/* --------------------------------------------------------------------
* Template Master Stylesheet
* 
* Template:		Alva - Responsive Portfolio HTML Website Template
* Author:		Themetorium
* URL:			https://themetorium.net/
*
* Template main color: #e05536 - hover: #cc3918
* Global font family (Google font): 'Poppins', sans-serif;
-------------------------------------------------------------------- */


/* Table of Content
====================
# General
# Page transitions / Page preloader
# Lazy-loading plugin
# Page boxed layout
# Cover
# Body inner
# Page wrap
# Page content
# Headings
# Figure
# tt-heading
# Header
# Logo
# Header attributes
# Main menu
# tt-section
# Page header
# Isotope
# Isotope filter
# Isotope items wrap
# Portfolio categories
# Portfolio grid
# Portfolio carousel
# Text portfolio (interactive links)
# Portfolio info list
# tt-Gallery
# Entry text nav
# Lightgallery
# Split box
# Content carousel
# Swiper
# Thumbnail list
# Team
# Pagination (tt-pagination)
# tt-list
# Clients list
# Clients carousel
# Forms
# Accordion
# Buttons
# Social buttons
# Modal
# Blockquotes
# Testimonial carousel
# Badges
# Info box
# Contact info
# Map (tt-map)
# Blog list section
# Blog list carousel
# Blog single section
# Sidebar
# Blog sidebar widgets
# Search results
# Scroll to top button
# Footer
*/ 


/* ------------------------------------------------------------- *
 *  General
/* ------------------------------------------------------------- */

html, body {
	padding: 0;
	margin: 0;
}
body {
	position: relative;
	background-color: #FFF;
	font-family: 'Poppins', sans-serif;
	line-height: 1.6;
	font-size: 19px;
	font-weight: 300;
	color: #333;
}

a {
	color: #e05536;
	text-decoration: none;
}
a:hover, a:focus {
	color: #cc3918;
	text-decoration: none;
}
a:focus {
	outline: none;
}

p {
	margin: 0 0 20px;
}

img {
	max-width: 100%;
	height: auto;
}

b, strong {
	font-weight: bold;
	font-weight: 600;
}

section {
	position: relative;
}

button {
}
button:focus {
	outline: none;
}

/* selection */
::selection {
	color: #fff;
	text-shadow: none;
	background: #e05536;
}
::-moz-selection {
	color: #fff;
	text-shadow: none;
	background: #e05536; /* Firefox */
}
::-webkit-selection {
	color: #fff;
	text-shadow: none;
	background: #e05536; /* Safari */
}

.small, small {
	font-size: 80%;
}

/* lead */
.lead {
	font-size: 21px;
	font-weight: normal;
}


/* --------------------------------------------------------------------------------------- *
 *  Page transitions / Page preloader (Animsition). Display loading animation while page loads
 *  More info: http://git.blivesta.com/animsition/
/* --------------------------------------------------------------------------------------- */

/* circle loader */
.animsition-loading {
	z-index: 99999;
	border-top: 3px solid rgba(130, 130, 130, 0.3);
	border-right: 3px solid rgba(130, 130, 130, 0.3);
	border-bottom: 3px solid rgba(130, 130, 130, 0.3);
	border-left: 3px solid #e05536;
}

/* slide overlay */
.overlay-slide-in-top,
.overlay-slide-out-top {
	background-color: #FFF;
	z-index: 99998;
}


/* --------------------------------------------------------------------------------------- *
 *	 Lazy-loading plugin - jQuery Lazy 
 *  More info: http://jquery.eisbehr.de/lazy/
/* --------------------------------------------------------------------------------------- */

.lazy {
	position: relative;
}
.lazy.lazy-loader::after {
	position: absolute;
	display: block;
	content: "";
	top: 50%;
	left: 50%;
	width: 54px;
	height: 54px;
	background-image: url(../img/loader.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.lazy.lazy-error::after {
	position: absolute;
	display: block;
	content: "";
	top: 50%;
	left: 50%;
	width: 54px;
	height: 54px;
	background-image: url(../img/no-image.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* if <img> tag */
img.lazy.lazy-loader,
img.lazy.lazy-error {
	width: 100%;
	height: 320px;
}
img.lazy.lazy-loader {
	background-image: url(../img/loader.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	-webkit-background-size: inherit !important;
	background-size: inherit !important;
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}
img.lazy.lazy-error {
	background-image: url(../img/no-image.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	-webkit-background-size: inherit !important;
	background-size: inherit !important;
}


/* ----------------------------------------------------------------------------------------- *
 *  Page boxed layout
 *  Note: Class "boxed" in <body> tag is connected with class "tt-wrap" in several places!
/* ----------------------------------------------------------------------------------------- */

body.tt-boxed .tt-wrap {
	max-width: 1282px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}
body.tt-boxed .tt-wrap .tt-wrap {
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
}


/* ------------------------------------------------------------- *
 *  Cover (used as image cover)
/* ------------------------------------------------------------- */

.cover {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
}

[class*="cover-opacity-"]::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #000;
	z-index: 1;
	opacity: 0;
}


/* Cover opasity
================= */
.cover-opacity-0::before { opacity: 0; }
.cover-opacity-0-5::before { opacity: .05; }
.cover-opacity-1::before { opacity: .1; }
.cover-opacity-1-5::before { opacity: .15; }
.cover-opacity-2::before { opacity: .2; }
.cover-opacity-2-5::before { opacity: .25; }
.cover-opacity-3::before { opacity: .3; }
.cover-opacity-3-5::before { opacity: .35; }
.cover-opacity-4::before { opacity: .4; }
.cover-opacity-4-5::before { opacity: .45; }
.cover-opacity-5::before { opacity: .5; }
.cover-opacity-5-5::before { opacity: .55; }
.cover-opacity-6::before { opacity: .6; }
.cover-opacity-6-5::before { opacity: .65; }
.cover-opacity-7::before { opacity: .7; }
.cover-opacity-7-5::before { opacity: .75; }
.cover-opacity-8::before { opacity: .8; }
.cover-opacity-8-5::before { opacity: .85; }
.cover-opacity-9::before { opacity: .9; }
.cover-opacity-9-5::before { opacity: .95; }


/* ------------------------------------------------------------- *
 *  Body inner
/* ------------------------------------------------------------- */

#body-inner {
	position: relative;
	overflow: hidden;
}


/* ------------------------------------------------------------- *
 *  Page wrap
/* ------------------------------------------------------------- */

#page-wrap {
	position: relative;
	background-color: #FFF;
	z-index: 5;
	overflow: hidden;
}


/* ------------------------------------------------------------- *
 *  Page content
/* ------------------------------------------------------------- */

#page-content {
	position: relative;
}


/* ------------------------------------------------------------- *
 * Headings
/* ------------------------------------------------------------- */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
	font-weight: 700;
	color: inherit;
}
.h1, .h2, .h3, h1, h2, h3 {
	line-height: 1.3;
	margin-top: 0px;
	margin-bottom: 20px;
}
.h4, .h5, .h6, h4, h5, h6 {
	line-height: 1.3;
	margin-top: 10px;
	margin-bottom: 20px;
}

.h1, h1 { font-size: 42px; }
.h2, h2 { font-size: 36px; }
.h3, h3 { font-size: 31px; }
.h4, h4 { font-size: 26px; }
.h5, h5 { font-size: 22px; }
.h6, h6 { font-size: 19px; }


/* ------------------------------------------------------------- *
 * Figure
/* ------------------------------------------------------------- */

figure {
	position: relative;
	margin-bottom: 0;
	overflow: hidden;
	background-color: rgba(130, 130, 130, 0.1);
}

@media (max-width: 768px) {
	figure[class*="margin-bottom-"] {
		margin-bottom: 5% !important;
	}
}

/* figure image */
figure img {
	width: 100%;
}

/* figure caption */
figcaption {
	background-color: #FFF;
	padding: 10px 5px 0 5px;
	font-size: 14px;
	color: #888;
	z-index: 1;
}
@media (max-width: 1280px) {
	figcaption {
		padding-right: 5px;
	}
}

figcaption a {
	border-bottom: 1px dotted;
}
figcaption a:hover {
	opacity: .8;
}


/* ------------------------------------------------------------- *
 * tt-heading
/* ------------------------------------------------------------- */

.tt-heading {
	position: relative;
	z-index: 3;
	margin-bottom: 30px;
}

/* tt-heading inner */
.tt-heading-inner {
	position: relative;
	max-width: 1282px; /* boxed layout width */
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	z-index: 2;
}
body.tt-boxed .tt-wrap .tt-heading-inner {
	padding-left: 0;
	padding-right: 0;
}

/* tt-heading in split-box */
.split-box .tt-heading-inner {
	padding: 0;
}

/* tt-heading title */
.tt-heading-title {
	max-width: 920px;
	margin: 0;
	font-size: 42px;
	font-weight: 800;
}
@media (max-width: 768px) {
	.tt-heading-title {
		font-size: 30px;
	}
}
.tt-heading-title a {
	color: inherit;
}
.tt-heading-title a:hover {
	color: #e05536;
}
.tt-heading.text-center .tt-heading-title {
	margin-left: auto;
	margin-right: auto;
}
.tt-heading.text-right .tt-heading-title {
	margin-left: auto;
}

/* tt-heading subtitle */
.tt-heading-subtitle {
	max-width: 800px;
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: 500;
	font-style: italic;
	letter-spacing: 1px;
}
.tt-heading-subtitle a {
	color: inherit;
}
.tt-heading-subtitle a:hover {
	color: #e05536;
}

.tt-heading.text-center .tt-heading-subtitle {
	margin-left: auto;
	margin-right: auto;
}
.tt-heading.text-right .tt-heading-subtitle {
	margin-left: auto;
}

/* tt-heading divider */
.tt-heading-divider {
	width: 115px;
	height: 25px;
	background-image: url(../img/heading-divider.png);
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: 25px;
}
.tt-heading.text-center .tt-heading-divider {
	margin-left: auto;
	margin-right: auto;
}
.tt-heading.text-right .tt-heading-divider {
	margin-left: auto;
}


/* tt-heading sizes 
==================== */
/* tt-heading xs */
.tt-heading.tt-heading-xs .tt-heading-title {
	font-size: 26px;
}
.tt-heading.tt-heading-xs .tt-heading-subtitle {
	margin-bottom: 15px;
	font-size: 16px;
}

/* tt-heading sm */
.tt-heading.tt-heading-sm .tt-heading-title {
	font-size: 34px;
}
.tt-heading.tt-heading-sm .tt-heading-subtitle {
	margin-bottom: 15px;
	font-size: 18px;
}
@media (max-width: 991px) {
	.tt-heading.tt-heading-sm .tt-heading-title {
		font-size: 30px;
	}
}
@media (max-width: 768px) {
	.tt-heading.tt-heading-sm .tt-heading-title {
		font-size: 26px;
	}
}

/* tt-heading lg */
.tt-heading.tt-heading-lg .tt-heading-title {
	font-size: 52px;
}
.tt-heading.tt-heading-lg .tt-heading-subtitle {
	margin-bottom: 15px;
	font-size: 20px;
}
@media (max-width: 991px) {
	.tt-heading.tt-heading-lg .tt-heading-title {
		font-size: 42px;
	}
}
@media (max-width: 768px) {
	.tt-heading.tt-heading-lg .tt-heading-title {
		font-size: 32px;
	}
}

/* tt-heading xlg */
.tt-heading.tt-heading-xlg .tt-heading-title {
	font-size: 76px;
}
.tt-heading.tt-heading-xlg .tt-heading-subtitle {
	margin-bottom: 10px;
	font-size: 26px;
}
@media (max-width: 1200px) {
	.tt-heading.tt-heading-xlg .tt-heading-title {
		font-size: 68px;
	}
}
@media (max-width: 991px) {
	.tt-heading.tt-heading-xlg .tt-heading-title {
		font-size: 52px;
	}
}
@media (max-width: 768px) {
	.tt-heading.tt-heading-xlg .tt-heading-title {
		font-size: 42px;
	}
	.tt-heading.tt-heading-xlg .tt-heading-subtitle {
		font-size: 22px;
	}
}

/* tt-heading xxlg */
.tt-heading.tt-heading-xxlg .tt-heading-title {
	font-size: 82px;
}
.tt-heading.tt-heading-xxlg .tt-heading-subtitle {
	margin-bottom: 10px;
	font-size: 30px;
}
@media (max-width: 1200px) {
	.tt-heading.tt-heading-xxlg .tt-heading-title {
		font-size: 68px;
	}
	.tt-heading.tt-heading-xxlg .tt-heading-subtitle {
		font-size: 24px;
	}
}
@media (max-width: 991px) {
	.tt-heading.tt-heading-xxlg .tt-heading-title {
		font-size: 68px;
	}
	.tt-heading.tt-heading-xxlg .tt-heading-subtitle {
		font-size: 24px;
	}
}
@media (max-width: 768px) {
	.tt-heading.tt-heading-xxlg .tt-heading-title {
		font-size: 48px;
	}
	.tt-heading.tt-heading-xxlg .tt-heading-subtitle {
		font-size: 22px;
	}
}


/* ------------------------------------------------------------- *
 * Header
/* ------------------------------------------------------------- */

#header {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	background-color: #FFF;
	z-index: 9998;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
	#header {
		z-index: 99999;
		/*-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);*/
	}
}

/* header inner */
.header-inner {
	position: relative;
	padding-left: 3%;
	padding-right: 3%;
}
.header-inner::after {
	content: "";
	display: block;
	clear: both;
}

/* header fixed top */
#header.header-fixed-top {
	position: fixed !important;
}

/* hide header on scroll down and show on scroll up */
#header.header-show-hide-on-scroll {
	position: fixed !important;
}
#header.header-show-hide-on-scroll.fly-up {
	top: -150px;
}
body.tt-m-menu-open #header.header-show-hide-on-scroll { /* disable hide/show header on scroll if mobile menu is open */
	top: 0;
}

/* header transparent */
@media (min-width: 992px) {
	#header.header-transparent {
		position: absolute;
		background-color: transparent;
		padding-top: 20px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
	#header.header-transparent .tt-menu-nav > li > a,
	#header.header-transparent .header-attributes > ul > li > a {
		color: #FFF;
	}
	#header.header-transparent .tt-menu-nav > li > a:hover {
	}

	#header.header-transparent #logo .logo-dark {
		display: none;
	}
	#header.header-transparent #logo .logo-light {
		display: block;
	}
}

/* header transparent dark */
@media (min-width: 992px) {
	#header.header-transparent-dark .tt-menu-nav > li > a,
	#header.header-transparent-dark .header-attributes > ul > li > a {
		color: #000;
	}
	#header.header-transparent-dark .tt-menu-nav > li > a:hover {
	}

	#header.header-transparent-dark #logo .logo-dark {
		display: block;
	}
	#header.header-transparent-dark #logo .logo-light {
		display: none;
	}
}

/* Header filled */
#header.header-filled {
	padding: 0;
	/* -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
}
#header.header-transparent.header-filled {
	background-color: #FFF;
	padding-top: 0;
}
#header.header-transparent.header-filled .tt-menu-nav > li > a,
#header.header-transparent.header-filled .header-attributes > ul > li > a {
	color: inherit;
}

@media (min-width: 992px) {
	#header.header-transparent.header-filled #logo .logo-dark {
		display: block;
	}
	#header.header-transparent.header-filled #logo .logo-light {
		display: none;
	}
}


/* ------------------------------------------------------------- *
 * Logo
/* ------------------------------------------------------------- */

#logo {
	position: relative;
	top: 18px;
	margin-bottom: 30px;
	z-index: 9;
	line-height: 1.5;
}
#header #logo img {
	max-height: 42px
}

#logo .logo-light {
	display: none;
}

#logo .logo-light-m,
#logo .logo-dark-m {
	display: none;
}

@media (max-width: 991px) {
	#header #logo {
		position: relative;
		float: left !important;
		top: 16px !important;
	}
	#header #logo img {
		max-height: 32px !important;
	}

	#logo .logo-dark {
		display: none;
	}
	#logo .logo-dark-m {
		display: block;
	}
}


/* ------------------------------------------------------------- *
 * Header attributes
/* ------------------------------------------------------------- */

.header-attributes {
	position: relative;
	float: right;
	margin-left: 20px;
}
@media (max-width: 1200px) {
	.header-attributes {
		margin-left: 10px;
	}
}
@media (max-width: 991px) {
	.header-attributes {
		margin-left: 0;
		margin-right: 5px;
	}
}

.header-attributes > ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.header-attributes > ul > li {
	float: left;
	margin-left: 8px;
}
.header-attributes > ul > li > a {
	position: relative;
	display: block;
	margin: 20px 0 18px 0;
	line-height: 1.5;
	font-size: 17px;
	font-weight: normal;
	color: #000;
	z-index: 9;
}
.header-attributes > ul > li > a:hover {
}
@media (max-width: 991px) {
	.header-attributes > ul > li > a {
		margin: 0;
		padding: 20px 8px;
	}
}


/* Header attributes search 
============================ */

/* header attributes search trigger */
.header-search-trigger {
	padding: 9px 5px;
}
.header-search-trigger:hover {
}

/* header attributes search */
.header-search {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 80px; /* same as header height */
	z-index: 99999;
	background-color: #FFF;
	opacity: 0;
	visibility: hidden;

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
}
@media (max-width: 991px) {
	.header-search {
		height: 64px; /* same as mobile header height */
	}
}

body.header-search-open .header-search {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
.header-search-cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	z-index: 999;
}

.header-search-inner {
	position: relative;
	top: 50%;
	width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding: 0 140px 0 30px;
	z-index: 2;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media (max-width: 767px) {
	.header-search-inner {
		padding: 0 40px 0 0;
	}
}
.header-inner.tt-wrap .header-search-inner {
	max-width: 1282px;
	padding: 0 80px 0 15px;
}

/* header attributes search form */
#header-search-form {
}
.header-search #header-search-input {
	position: relative;
	width: 100%;
	height: 60px;
	background-color: transparent;
	padding: 10px 0;
	font-size: 19px;
	color: #000;
	border: none;
}
.header-search #header-search-input:focus {
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
@media (max-width: 767px) {
	.header-search #header-search-input {
		height: 40px;
		padding: 10px;
		font-size: 16px;
	}
}
.header-search .form-btn-inside button {
	font-size: 24px;
	color: #000;
	padding: 0 10px;
}
@media (max-width: 767px) {
	.header-search .form-btn-inside button {
		font-size: 24px;
		padding: 0 10px;
	}
}
.header-search .form-btn-inside button:hover {
	opacity: 1;
	color: #e05536;
}

/* header attributes search close */
.header-search-close {
	position: absolute;
	top: 50%;
	right: 30px;
	margin-top: -2px;
	color: #222;
	cursor: pointer;
	z-index: 9;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media (max-width: 992px) {
	.header-search-close {
		right: 15px;
	}
	.header-search-close span {
		display: none;
	}
}

.header-search-close:hover .tt-close-btn {
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}


/* Header attributes call to action button 
=========================================== */
.h-attr-button {
	margin: 24px 0 0px 15px !important;
	padding: 6px 14px !important;
	font-size: 15px !important;
	color: #FFF !important;
}
@media (max-width: 1200px) {
	.h-attr-button {
		margin-left: 10px !important;
	}
}
@media (max-width: 991px) {
	.h-attr-button {
		display: none;
		margin: 13px 0 13px 8px !important;
		padding: 7px 10px !important;
	}
}


/* ------------------------------------------------------------- *
 * Main menu
/* ------------------------------------------------------------- */

.tt-main-menu {
	display: inline-block;
	min-height: 80px;
}

/* menu collapse */
.tt-menu-collapse {
	display: inline-block !important;
}

/* menu nav */
.tt-menu-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}
.tt-menu-nav > li {
	display: inline-block;
	margin: 0 10px;
}
.tt-menu-nav > li:first-child {
	margin-left: 0;
}
.tt-menu-nav > li:last-child {
	margin-right: 0;
}
@media (max-width: 1400px) {
	.tt-menu-nav > li {
		margin-left: 5px;
		margin-right: 5px;
	}
}
.tt-menu-nav > li > a {
	position: relative;
	display: block;
	margin: 0;
	padding: 28px 0;
	text-decoration: none;
	line-height: 1.5;
	font-size: 17px;
	font-weight: bold;
	font-weight: 500;
	color: #000;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	-ms-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}


/* Menu nav hover/active 
========================= */
.tt-menu-nav > li > a:hover,
.tt-menu-nav > li.active > a {
}
.tt-menu-nav > li > a::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 37%;
	width: 100%;
	z-index: -1;
	height: 0;
	background-color: #e05536;
	opacity: .2;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.tt-menu-nav > li > a:hover::after,
.tt-menu-nav > li.active > a::after {
	height: 10px;
}

/* menu nav hover/active opacity with different header styles. 
   Note: depends on the hover/active pseudo element background color you may not need this! */
.tt-menu-nav > li > a::after,
#header.header-transparent.header-filled .tt-menu-nav > li > a::after,
#header.header-transparent.header-transparent-dark .tt-menu-nav > li > a::after {
	opacity: .4;
}
#header.header-transparent .tt-menu-nav > li > a::after {
	opacity: 1;
}

/* menu nav hover/active alternative color (useful if the background color is the same as hover color) */
#header.header-transparent:not(.header-filled) .tt-menu-hover-alter .tt-menu-nav > li > a::after {
	background-color: #000;
}
#header.header-transparent.header-transparent-dark:not(.header-filled) .tt-menu-hover-alter .tt-menu-nav > li > a::after {
	background-color: #FFF !important;
}


/* Submenu 
============ */
.tt-submenu-wrap {
	position: relative;
}
.tt-submenu {
	position: relative;
	display: none;
	top: 100%;
	left: 0;
	min-width: 220px;
	background-color: #FFF;
	z-index: 99999;
	text-align: left;
}

@media (min-width: 992px) {
	.tt-submenu {
		position: absolute;
		display: block !important;
		top: 110%;
		visibility: hidden;
		opacity: 0;

		border-top: 1px solid rgba(158, 158, 158, 0.15);
		-webkit-box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .3);
		-moz-box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .3);
		box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .3);

		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.tt-submenu-wrap.tt-submenu-open > .tt-submenu {
		top: 100%;
		visibility: visible;
		opacity: 1;
		-webkit-transition-delay: 0.1s;
		-moz-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}
}

/* sub-sub menu */
.tt-submenu .tt-submenu-wrap > .tt-submenu { 
	left: 100%;
	right: auto;
	margin-top: -41px;
	margin-left: 0;
}

/* submenu links */
.tt-submenu-list {
	margin: 0;
	padding: 10px 0;
	list-style: none;
}
.tt-submenu .tt-submenu-list li {
	margin: 2px 0;
	/* border-bottom: 1px solid rgba(158, 158, 158, 0.13); */
}
.tt-submenu .tt-submenu-list li:first-child {
	margin-top: 0;
}
.tt-submenu .tt-submenu-list li:last-child {
	margin-bottom: 0;
	border-bottom: none;
}
.tt-submenu .tt-submenu-list li > a {
	position: relative;
	display: block;
	padding: 8px 25px 8px 20px;
	text-decoration: none;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.4;
	color: #333;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.tt-submenu .tt-submenu-list li:first-child > a {
}
.tt-submenu .tt-submenu-list li:last-child > a {
}
.tt-submenu .tt-submenu-list li > a:hover {
	background-color: rgba(158, 158, 158, 0.15);
	color: #000;
	-webkit-box-shadow: inset 4px 0 0 0 #e05536;
	-moz-box-shadow: inset 4px 0 0 0 #e05536;
	box-shadow: inset 4px 0 0 0 #e05536;
}
.tt-submenu .tt-submenu-list > li.active > a {
	background-color: rgba(158, 158, 158, 0.15);
	color: #000;
	-webkit-box-shadow: inset 4px 0 0 0 #e05536;
	-moz-box-shadow: inset 4px 0 0 0 #e05536;
	box-shadow: inset 4px 0 0 0 #e05536;
}

/* submenu right position */
.tt-menu-nav > li:last-child > .tt-submenu {
	left: auto;
	right: 0;
}
.tt-submenu-master.tt-submenu-right > .tt-submenu { /* you can add class "tt-submenu-right" manually if needed (works in dropdown master only!) */
	left: auto;
	right: 0;
}

/* caret (requires FontAwesome: https://fontawesome.com/) */
@media (min-width: 992px) {
	.tt-caret {
		position: relative;
		top: 1px;
		margin-left: 5px;
		font-size: 12px;
		line-height: 0;
	}
	.tt-caret::after {
		font-family: "Font Awesome 5 Free";
		content: "\f107";
		font-weight: 900;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}

	/* caret in dropdowns */
	.tt-submenu .tt-caret {
		position: absolute;
		right: 10px;
		top: 50%;
		-moz-transform: translateY(-50%) rotate(-90deg);
		-ms-transform: translateY(-50%) rotate(-90deg);
		-webkit-transform: translateY(-50%) rotate(-90deg);
		transform: translateY(-50%) rotate(-90deg);
	}
}

/* dropdown title */
.tt-dropdown-title {
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	font-weight: 600;
	border: none !important;
	-webkit-box-shadow: inset  !important;
	-moz-box-shadow: inset  !important;
	box-shadow: inset  !important;
}
.tt-dropdown-title + li {
	border: none !important;
	-webkit-box-shadow: inset  !important;
	-moz-box-shadow: inset  !important;
	box-shadow: inset  !important;
}

/* dropdown divider */
.tt-dropdown-divider {
	height: 1px;
	margin: 10px 0 !important;
	overflow: hidden;
	background-color: rgba(173, 173, 173, 0.21);
	border: none !important;
	-webkit-box-shadow: inset  !important;
	-moz-box-shadow: inset  !important;
	box-shadow: inset  !important;
}
.tt-dropdown-divider + li {
	border: none !important;
	-webkit-box-shadow: inset  !important;
	-moz-box-shadow: inset  !important;
	box-shadow: inset  !important;
}


/* Submenu style 
================= */
/* submenu dark style */
.tt-submenu-dark .tt-submenu {
	background-color: #111;
	color: #DDD;
	border: none;
}
.tt-submenu-dark .tt-submenu .tt-submenu-list li > a {
	color: #DDD;
}
.tt-submenu-dark .tt-submenu .tt-submenu-list li > a:hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: #FFF;
}
.tt-submenu-dark .tt-submenu .tt-submenu-list > li.active > a {
	background-color: rgba(255, 255, 255, 0.1);
	color: #FFF;
}


/* Menu aligns 
=============== */

/* menu align left */
#header.menu-align-left .tt-main-menu {
	display: block;
	text-align: left;
}
#header.menu-align-left #logo {
	top: 18px;
	float: left;
	margin: 0 4% 0 0;
}

/* menu align right */
#header.menu-align-right .tt-main-menu {
	float: right;
	text-align: left;
}
#header.menu-align-right #logo {
	top: 18px;
	float: left;
	margin: 0;
}

/* menu align center */
#header.menu-align-center {
}
@media (min-width: 992px) {
	#header.menu-align-center {
		text-align: center;
		padding-top: 40px;
	}
	#header.menu-align-center .header-inner {
		display: inline-block;
	}
	#header.menu-align-center #logo {
		padding: 0 0 30px 0;
	}
	#header.menu-align-center #logo img {
		max-height: 80px;
	}
}


/* Mobile menu toggle button 
============================= */
#tt-m-menu-toggle-btn {
	position: relative;
	display: none;
	float: right;
	top: 22px;
	cursor: pointer;
	padding: 8px 25px 11px 0;
	margin-left: 10px;
}

/* hamburger */
#tt-m-menu-toggle-btn span,
#tt-m-menu-toggle-btn span::before,
#tt-m-menu-toggle-btn span::after {
	position: absolute;
	display: block;
	content: '';
	height: 3px;
	width: 23px;
	background-color: #000;
	cursor: pointer;
	border-radius: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
#tt-m-menu-toggle-btn span::before {
	top: -7px;
}
#tt-m-menu-toggle-btn span::after {
	bottom: -7px;
}
body.tt-m-menu-open #tt-m-menu-toggle-btn span {
	background-color: transparent !important;
}
body.tt-m-menu-open #tt-m-menu-toggle-btn span::before {
	top: 0;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
body.tt-m-menu-open #tt-m-menu-toggle-btn span::after {
	top: 0;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}


/* Mobile menu 
================ */
@media (max-width: 991px) {
	#tt-m-menu-toggle-btn {
		display: block;
	}

	.tt-main-menu {
		display: block;
		float: none !important;
		min-height: 60px;
	}

	/* mobile menu collapse */
	.tt-menu-collapse {
		position: absolute;
		display: block;
		top: 100%;
		left: 0;
		width: 50%;
		min-height: calc(100vh - 62px);
		height: calc(100vh - 62px);
		overflow-y: auto;
		background-color: #000 !important;
		text-align: left;
		color: #DDD;
		-webkit-box-shadow: 15px 0 22px -14px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 15px 0 22px -14px rgba(0, 0, 0, 0.4);
		box-shadow: 15px 0 22px -14px rgba(0, 0, 0, 0.4);
		z-index: 99998;
		visibility: hidden;
		-webkit-transform: translateX(-100%);
		-moz-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		-o-transform: translateX(-100%);
		transform: translateX(-100%);
	}
	.tt-menu-collapse.tt-m-menu {
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	
	/* if mobile menu is open */
	body.tt-m-menu-open .tt-menu-collapse {
		visibility: visible;
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		transform: translateX(0);
	}
	html.tt-m-menu-noscroll { /* disable page scroll if mobile menu is open (class "tt-m-menu-noscroll" handled by "theme.js" file) */
		overflow-y: hidden !important;
	}

	.tt-m-menu-cover { /* page cover if mobile menu is open */
		position: absolute;
		content: "";
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: transparent;
		z-index: 99998;
		visibility: hidden;
		opacity: 0;
	}
	body.tt-m-menu-open .tt-m-menu-cover {
		visibility: visible;
		opacity: .6;
	}

	/* mobile menu nav */
	.tt-menu-nav {
	}
	.tt-menu-nav > li {
		display: block;
		float: none;
		margin: 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	}
	.tt-menu-nav > li > a {
		background-color: transparent;
		margin: 0;
		padding: 10px 20px;
		color: #CCC !important;
	}
	.tt-menu-nav > li > a:hover {
		background-color: transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		color: #FFF !important;
		border: none;
	}
	.tt-menu-nav > li.active > a {
		background-color: transparent;
		color: #FFF !important;
		-webkit-box-shadow: inset 4px 0 0 0 #e05536;
		-moz-box-shadow: inset 4px 0 0 0 #e05536;
		box-shadow: inset 4px 0 0 0 #e05536;
		border: none;
	}
	.tt-menu-nav > li > a::after {
		display: none;
	}
	
	/* mobile submenu */
	.tt-submenu {
		display: none;
		position: relative;
		top: auto;
		left: 0 !important;
		min-width: 100%;
		background-color: #1b1b1b !important;
		text-align: left;
		color: #DDD !important;
		overflow: hidden;
		border-top: 1px solid rgba(255, 255, 255, 0.02);
		-webkit-box-shadow: inset 0 22px 10px -20px rgba(0, 0, 0, 1), inset 0 -22px 10px -20px rgba(0, 0, 0, 1) !important;
		-moz-box-shadow: inset 0 22px 10px -20px rgba(0, 0, 0, 1), inset 0 -22px 10px -20px rgba(0, 0, 0, 1) !important;
		box-shadow: inset 0 22px 10px -20px rgba(0, 0, 0, 1), inset 0 -22px 10px -20px rgba(0, 0, 0, 1) !important;
	}
	.tt-submenu .tt-submenu-wrap > .tt-submenu {
		left: 0 !important;
		margin-top: 0;
		padding-left: 5%;
	}

	/* mobile submnu links */
	.tt-submenu .tt-submenu-list > li {
		margin-top: 0;
		margin-bottom: 0;
		border-bottom: 1px solid #000;
	}
	.tt-submenu .tt-submenu-list > li:first-child {
		border-top: none;
	}
	.tt-submenu .tt-submenu-list li > a {
		padding: 11px 55px 11px 30px;
		color: #CCC;
	}
	.tt-submenu .tt-submenu-list > li > a:hover {
		background-color: transparent;
		padding-left: 30px;
		color: #FFF;
		border: none;
	}
	.tt-submenu .tt-submenu-list > li.active > a {
		background-color: rgba(158, 158, 158, 0.06);
		color: #FFF;
		border: none;
	}

	/* mobile menu link caret */
	.tt-submenu-wrap .tt-m-submenu-toggle {
		width: 45px;
		height: 100%;
		line-height: 45px;
		background-color: transparent;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		text-align: center;
		font-size: 18px;
		color: #DDD;
		border-left: 1px solid rgba(255, 255, 255, 0.1);
	}
	.tt-submenu-wrap .tt-m-submenu-toggle::after {
		position: relative;
		top: -1px;
		margin-left: 2px;
		line-height: 0;

		font-family: "Font Awesome 5 Free";
		content: "\f107";
		font-weight: 900;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
	.tt-submenu-wrap .tt-m-submenu-toggle.tt-m-dropdown-open::after {
		font-size: 16px;

		font-family: "Font Awesome 5 Free";
		content: "\f00d";
		font-weight: 900;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

@media (max-width: 767px) {
	/* mobile menu collapse */
	.tt-menu-collapse {
		width: 85%;
	}
}

@media (max-width: 320px) {
	/* mobile menu collapse */
	.tt-menu-collapse {
		width: 100%;
	}
}


/* ------------------------------------------------------------- *
 * tt-section
/* ------------------------------------------------------------- */

.tt-section {
	position: relative;
	padding: 80px 0;
}
@media (max-width: 992px) {
	.tt-section {
		padding: 6% 0;
	}
}
@media (max-width: 768px) {
	.tt-section {
		padding: 6% 0;
	}
}


/* tt-section background image 
=============================== */
.tt-section-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	overflow: hidden;
}


/* tt-section inner 
==================== */
.tt-section-inner {
	position: relative;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	z-index: 2;
}


/* ------------------------------------------------------------- *
 * Page header
/* ------------------------------------------------------------- */

#page-header {
	position: relative;
	overflow: hidden;
}
.page-header-inner {
	position: relative;
	padding: 100px 8%;
	z-index: 1;
}
@media (max-width: 992px) {
	.page-header-inner {
		padding-top: 15%;
		padding-bottom: 12%;
	}
}
@media (max-width: 480px) {
	.page-header-inner {
		padding-top: 20%;
		padding-bottom: 12%;
	}
}

/* if isotope filter button enabled */
@media (max-width: 768px) {
	body.fi-btn-on .page-header-inner, 
	body.fi-btn-on.header-transparent-on .page-header-inner {
		padding-bottom: 120px !important;
	}
}


/* Page header caption 
======================= */
.page-header-caption {
	position: relative;
	margin: 0 auto;
	z-index: 4;
}
@media (max-width: 1282px) { /* same as boxed layout width */
	.page-header-caption {
		padding-left: 8%;
		padding-right: 8%;
	}
}
@media (max-width: 992px) {
	.page-header-caption {
		padding-left: 2%;
		padding-right: 2%;
	}
}

/* page header title */
.ph-title-wrap {
}
.page-header-title {
	margin: 0 0 15px 0;
	font-size: 52px;
	font-weight: 800;
	color: #000;
}
@media (max-width: 991px) {
	.page-header-title {
		font-size: 42px ;
	}
}
@media (max-width: 768px) {
	.page-header-title {
		font-size: 32px;
	}
}

/* page header subtitle */
.ph-subtitle-wrap {
}
.page-header-subtitle {
	margin: 20px 0 20px 0;
	font-size: 26px;
	font-weight: 700;
	color: #000;
}
@media (max-width: 992px) {
	.page-header-subtitle {
		margin-top: 8px;
	}
}
@media (max-width : 768px) {
	.page-header-subtitle {
		font-size: 21px;
	}
}

/* page header description */
.page-header-description {
	margin: 30px 0 0 0;
	line-height: inherit;
	font-size: 18px;
	color: #000;
}
.ph-desc-inner {
}
.page-header-description p {
}
@media (max-width: 767px) {
	.page-header-description {
		font-size: 16px;
	}
}

/* page header categories */
.ph-category-wrap {
}
.page-header-category {
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: 400;
}
.page-header-category a,
.page-header-category span {
	position: relative;
	display: inline-block;
	background-color: #e05536;
	margin-bottom: 3px;
	padding: 2px 6px;
	color: #fff;
}
.page-header-category a:hover {
	opacity: .9;
}


/* Page header caption sizes 
============================= */

/* caption sizes xs */
.page-header-caption.ph-cap-xs .page-header-title {
	font-size: 32px;
}
.page-header-caption.ph-cap-xs .page-header-subtitle {
	font-size: 21px;
}

/* caption sizes sm */
.page-header-caption.ph-cap-sm .page-header-title {
	font-size: 44px;
}
.page-header-caption.ph-cap-sm .page-header-subtitle {
	font-size: 24px;
}

/* caption sizes lg */
.page-header-caption.ph-cap-lg {
	padding-bottom: 10px;
}
.page-header-caption.ph-cap-lg .page-header-title {
	font-size: 68px;
}
.page-header-caption.ph-cap-lg .page-header-subtitle {
	font-size: 28px;
}
.page-header-caption.ph-cap-lg .page-header-description {
	font-size: 20px;
}

/* caption sizes xlg */
.page-header-caption.ph-cap-xlg {
	padding-bottom: 15px;
}
.page-header-caption.ph-cap-xlg .page-header-title {
	font-size: 86px;
}
.page-header-caption.ph-cap-xlg .page-header-subtitle {
	font-size: 34px;
}
.page-header-caption.ph-cap-xlg .page-header-description {
	font-size: 20px;
}

/* caption sizes xxlg */
.page-header-caption.ph-cap-xxlg {
	padding-bottom: 20px;
}
.page-header-caption.ph-cap-xxlg .page-header-title {
	max-width: 100%;
	font-size: 6vw;
}
.page-header-caption.ph-cap-xxlg .page-header-subtitle {
	margin-top: 20px;
	font-size: 28px;
}
.page-header-caption.ph-cap-xxlg .page-header-description {
	font-size: 22px;
	font-weight: 400;
}

/* caption sizes on smaller screens */
@media (max-width: 991px) {
	.page-header-caption.ph-cap-sm .page-header-title,
	.page-header-caption.ph-cap-lg .page-header-title,
	.page-header-caption.ph-cap-xlg .page-header-title {
		font-size: 62px;
	}
	.page-header-caption.ph-cap-xlg .page-header-subtitle,
	.page-header-caption.ph-cap-xxlg .page-header-subtitle {
		font-size: 28px;
	}
	.page-header-caption.ph-cap-lg .page-header-description,
	.page-header-caption.ph-cap-xlg .page-header-description,
	.page-header-caption.ph-cap-xxlg .page-header-description {
		font-size: 16px;
	}
}
@media (max-width: 767px) {
	.page-header-caption.ph-cap-sm .page-header-title,
	.page-header-caption.ph-cap-lg .page-header-title,
	.page-header-caption.ph-cap-xlg .page-header-title {
		font-size: 38px;
	}
	.page-header-caption.ph-cap-xxlg .page-header-title {
		font-size: 12vw;
	}
	.page-header-caption.ph-cap-sm .page-header-subtitle,
	.page-header-caption.ph-cap-lg .page-header-subtitle,
	.page-header-caption.ph-cap-xlg .page-header-subtitle,
	.page-header-caption.ph-cap-xxlg .page-header-subtitle {
		font-size: 21px;
	}
}


/* Page header aligns 
====================== */

/* page header align right */
.ph-right .page-header-caption {
	text-align: right;
}
.ph-right .page-header-caption .ph-title-wrap,
.ph-right .page-header-caption .ph-subtitle-wrap,
.ph-right .page-header-caption .page-header-description,
.ph-right .page-header-caption .page-header-title,
.ph-right .page-header-caption .page-header-subtitle {
	margin-left: auto;
	text-align: right;
}

/* page header align center */
.ph-center .page-header-caption {
	text-align: center;
}
.ph-center .page-header-caption .ph-title-wrap,
.ph-center .page-header-caption .ph-subtitle-wrap,
.ph-center .page-header-caption .page-header-description,
.ph-center .page-header-caption .page-header-title,
.ph-center .page-header-caption .page-header-subtitle {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}


/* Page header sizes 
===================== */
#page-header.ph-xs .page-header-inner {
	padding-top: 40px;
	padding-bottom: 40px;
}
#page-header.ph-sm .page-header-inner {
	padding-top: 80px;
	padding-bottom: 80px;
}
#page-header.ph-lg .page-header-inner {
	padding-top: 140px;
	padding-bottom: 140px;
}
#page-header.ph-xlg .page-header-inner {
	padding-top: 200px;
	padding-bottom: 200px;
}

@media (max-width: 991px) {
	#page-header.ph-xs .page-header-inner,
	#page-header.ph-sm .page-header-inner,
	#page-header.ph-lg .page-header-inner,
	#page-header.ph-xlg .page-header-inner {
		padding-top: 15%;
		padding-bottom: 15%;
	}
}
@media (max-width: 480px) {
	#page-header.ph-xs .page-header-inner,
	#page-header.ph-sm .page-header-inner,
	#page-header.ph-lg .page-header-inner,
	#page-header.ph-xlg .page-header-inner {
		padding-top: 20%;
		padding-bottom: 20%;
	}
}

/* page header full height (desktop only) */
@media (min-width: 992px) {
	#page-header.ph-full { 
		height: calc(100vh - 80px); /* minus same as header height */
		min-height: calc(100vh - 80px); /* minus same as header height */
	}
	body.header-transparent-on #page-header.ph-full { 
		height: 100vh;
		min-height: 100vh;
	}

	/* caption position */
	#page-header.ph-full .page-header-inner { 
		display: table;
		width: 100%;
		height: 85%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	#page-header.ph-full .page-header-caption {
		display: table-cell;
		vertical-align: bottom;
		padding-left: 0;
		padding-right: 0;
	}
	body.tt-boxed #page-header.ph-full .page-header-inner.tt-wrap .page-header-caption {
		padding-left: inherit;
		padding-right: inherit;
	}

	#page-header.ph-full.ph-center .page-header-inner { 
		height: 100%;
	}
	#page-header.ph-full.ph-center .page-header-caption {
		display: table-cell;
		vertical-align: middle;
	}
}

/* page header full height (small screens only) */
@media (max-width: 991px) {
	#page-header.ph-full-m { 
		height: calc(100vh - 64px); /* minus same as header height */
		min-height: calc(100vh - 64px); /* minus same as header height */
	}

	/* caption position */
	#page-header.ph-full-m .page-header-inner { 
		display: table;
		width: 100%;
		height: 85%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	#page-header.ph-full-m .page-header-caption {
		display: table-cell;
		vertical-align: bottom;
		padding-left: 0;
		padding-right: 0;
	}
	body.tt-boxed #page-header.ph-full-m .page-header-inner.tt-wrap .page-header-caption {
		padding-left: inherit;
		padding-right: inherit;
	}

	#page-header.ph-full-m.ph-center .page-header-inner { 
		height: 100%;
	}
	#page-header.ph-full-m.ph-center .page-header-caption {
		display: table-cell;
		vertical-align: middle;
	}
}


/* Page header styles 
====================== */

/* page header caption alternative style */
.page-header-caption.ph-cap-alter .page-header-title {
	display: inline;
	background-color: #000;
	padding: 0 10px;
	color: #FFF;
	line-height: 1.4;
}

/* page header caption light color */
.page-header-caption.ph-cap-light {
	color: #FFF;
}
.page-header-caption.ph-cap-light .page-header-title {
	color: #FFF;
}
.page-header-caption.ph-cap-light .page-header-subtitle {
	color: #FFF;
}
.page-header-caption.ph-cap-light .page-header-description {
	color: #FFF;
}

.page-header-caption.ph-cap-light.ph-cap-alter .page-header-title {
	background-color: #FFF;
	color: #222;
}

/* page header caption text shadow */
.page-header-caption.ph-cap-shadow .page-header-title {
	text-shadow: 11px 5px 8px rgba(0, 0, 0, 0.1);
}
.page-header-caption.ph-cap-shadow .page-header-subtitle {
	text-shadow: 4px 3px 5px rgba(0, 0, 0, 0.1);
}
.page-header-caption.ph-cap-shadow .page-header-description {
	text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
}

.page-header-caption.ph-cap-shadow.ph-cap-alter .page-header-title {
	text-shadow: none;
}


/* Page header image 
===================== */
.page-header-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}


/* Page header if "header-transparent" enabled.
================================================ */
body.header-transparent-on #page-header .page-header-inner {
	padding-top: 200px;
	padding-bottom: 100px;
}
body.header-transparent-on #page-header.ph-xs .page-header-inner {
	padding-top: 150px;
	padding-bottom: 50px;
}
body.header-transparent-on #page-header.ph-sm .page-header-inner {
	padding-top: 180px;
	padding-bottom: 80px;
}
body.header-transparent-on #page-header.ph-lg .page-header-inner {
	padding-top: 260px;
	padding-bottom: 100px;
}
body.header-transparent-on #page-header.ph-xlg .page-header-inner {
	padding-top: 280px;
	padding-bottom: 160px;
}

@media (max-width: 991px) {
	body.header-transparent-on #page-header .page-header-inner,
	body.header-transparent-on #page-header.ph-sm .page-header-inner,
	body.header-transparent-on #page-header.ph-lg .page-header-inner,
	body.header-transparent-on #page-header.ph-xlg .page-header-inner {
		padding-top: 15%;
		padding-bottom: 15%;
	}
}
@media (max-width: 480px) {
	body.header-transparent-on #page-header .page-header-inner,
	body.header-transparent-on #page-header.ph-sm .page-header-inner,
	body.header-transparent-on #page-header.ph-lg .page-header-inner,
	body.header-transparent-on #page-header.ph-xlg .page-header-inner {
		padding-top: 20%;
		padding-bottom: 20%;
	}
}


/* Page header scroll down button (displayed only if "#page-header" contains class "ph-full" or "ph-full-m")
================================== */
#page-header .ph-scroll-down {
	position: absolute;
	display: none;
	bottom: 50px;
	right: 60px;
	z-index: 9;
}

/* if "ph-full" or "ph-full-m" is enabled */
@media (min-width: 992px) {
	#page-header.ph-full .ph-scroll-down {
		display: block;
	}
}
@media (max-width: 991px) {
	#page-header.ph-full-m .ph-scroll-down {
		display: block;
		bottom: 20px;
		right: 30px;
	}
}

/* scroll down button */
#page-header .ph-scroll-down-btn {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 21px;
	color: #000;
	text-decoration: none;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
#page-header .ph-scroll-down-btn::before {
	line-height: 30px;

	font-family: "Font Awesome 5 Free";
	content: "\f078";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}
#page-header .ph-scroll-down-btn:focus {
	color: #000;
}

#page-header .ph-scroll-down-btn:hover {
	color: #FFF;
}

/* scroll down button hover background */
#page-header .ph-scroll-down-btn::after {
	position: absolute;
	display: block;
	content: "";
	height: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .3s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .3s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .3s cubic-bezier(.645,.045,.355,1);
	transition: all .3s cubic-bezier(.645,.045,.355,1);

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
#page-header .ph-scroll-down-btn:hover::after {
	height: 100%
}

/* if page header caption contains class "ph-cap-light" */
body.ph-cap-light-on #page-header .ph-scroll-down-btn {
	color: #FFF;
}


/* --------------------------------------------------- *
 * Isotope (more info: http://isotope.metafizzy.co/)
/* --------------------------------------------------- */

.isotope-wwrap {
	position: relative;
}
.isotope {
}


/* Isotope top content 
======================= */
.isotope-top-content {
	position: relative;
	padding: 40px 3%;
}

/* if gutter enabled */
.isotope.iso-gutter-1 .isotope-top-content {
	margin-left: 6px;
}
.isotope.iso-gutter-2 .isotope-top-content {
	margin-left: 1.5%;
}
.isotope.iso-gutter-3 .isotope-top-content {
	margin-left: 2.5%;
}
.isotope.iso-gutter-4 .isotope-top-content {
	margin-left: 4%;
	margin-right: 3.5%;
	padding-right: 0;
}
.isotope.iso-gutter-5 .isotope-top-content {
	margin-left: 5.5%;
	margin-right: 2%;
}
.isotope.iso-gutter-6 .isotope-top-content {
	margin-left: 9.5%;
	margin-right: 3.5%;
}

@media (max-width: 992px) {
	.isotope.iso-gutter-4 .isotope-top-content {
		margin-left: 2.5%;
		margin-right: 0;
		padding-right: 0;
	}
	.isotope.iso-gutter-5 .isotope-top-content {
		margin-left: 4.5%;
		margin-right: 0;
	}
	.isotope.iso-gutter-6 .isotope-top-content {
		margin-left: 2.5%;
		margin-right: 0;
	}
}

/* if boxed layout enabled */
body.tt-boxed .tt-wrap .isotope-top-content {
	padding-left: 0;
	padding-right: 0;
}
body.tt-boxed .tt-wrap .isotope.iso-gutter-4 .isotope-top-content {
	margin-left: 3.5%;
	margin-right: 0;
}
body.tt-boxed .tt-wrap .isotope.iso-gutter-5 .isotope-top-content {
	margin-left: 4.5%;
	margin-right: 0;
}
body.tt-boxed .tt-wrap .isotope.iso-gutter-6 .isotope-top-content {
	margin-left: 6.5%;
	margin-right: 0;
}


/* ================
	Isotope filter 
=================== */

.isotope-filter {
}

/* isotope filter links title */
.ifl-title {
}
.ifl-title span {
	font-size: 16px;
	font-weight: bold;
	font-weight: 700;
}
.ifl-title i {
	font-size: 16px;
}
.fi-btn .ifl-title {
	display: none !important;
}


/* Isotope filter links 
======================== */

ul.isotope-filter-links {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
}
ul.isotope-filter-links::before {
	display: inline-block;
	content: "";
	width: 50px;
	height: 3px;
	background-color: #000;
	margin: 0 14px 3px 0;
}

ul.isotope-filter-links > li {
	display: inline-block;
	margin: 0 6px;
}
@media (max-width: 768px) {
	ul.isotope-filter-links > li {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
ul.isotope-filter-links > li:first-child {
	margin-left: 0;
}
ul.isotope-filter-links > li:last-child {
	margin-right: 0;
}
ul.isotope-filter-links > li > button {
	position: relative;
	display: block;
	background: none;
	padding: 6px 0;
	font-size: 16px;
	font-weight: 500;
	color: #000;
	cursor: pointer;
	border: none;
}
ul.isotope-filter-links > li > button:focus {
	outline: none;
}
ul.isotope-filter-links > li > button::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 20%;
	width: 100%;
	z-index: -1;
	height: 0;
	background: #e05536;
	opacity: .3;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
ul.isotope-filter-links > li > button.active {
}
ul.isotope-filter-links > li > button:hover::after,
ul.isotope-filter-links > li > button.active::after {
	height: 10px;
}


/* Isotope filter aligns 
========================= */

/* filter center */
.isotope-filter.fi-center {
	text-align: center;
}
.isotope-filter.fi-center ul.isotope-filter-links::before {
	display: none;
}

/* filter right */
.isotope-filter.fi-right {
	text-align: right;
}
@media (max-width: 768px) {
	.isotope-filter.fi-right {
		text-align: left;
	}
}


/* Change isotope filter to button (use class "fi-btn") 
======================================================== */
.isotope-filter.fi-btn {
	position: absolute;
	top: 30px;
	left: 0;
	right: auto;
	z-index: 9997;
	padding: 0 0 8px 0 !important;
	margin: 0 !important;
}

@media (min-width: 992px) {
	.isotope-filter.fi-btn {
		top: 60px;
	}
	.isotope.iso-gutter-1 .isotope-filter.fi-btn {
		top: 70px;
	}
	.isotope.iso-gutter-2 .isotope-filter.fi-btn {
		top: 90px;
	}
	.isotope.iso-gutter-3 .isotope-filter.fi-btn {
		top: 110px;
	}
	.isotope.iso-gutter-4 .isotope-filter.fi-btn,
	.isotope.iso-gutter-5 .isotope-filter.fi-btn,
	.isotope.iso-gutter-6 .isotope-filter.fi-btn {
		top: 110px;
	}
}

/* isotope filter button */
.isotope-filter-button {
	position: relative;
	display: none;
	width: auto;
	height: 46px;
	line-height: 46px;
	background-color: #e05536;
	padding: 0 14px;
	text-align: center;
	font-size: 15px;
	color: #FFF;
	cursor: pointer;
	z-index: 2;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.isotope-filter-button > span {
	display: inline-block;
}
.isotope-filter.fi-btn .isotope-filter-button {
	display: block;
}
body.iso-filter-open .isotope-filter.fi-btn .isotope-filter-button {
	background-color: #e05536;
	color: #FFF;
}

.isotope-filter-button .ifb-title {
	margin-left: 2px;
	font-size: 14px;
	font-weight: 600;
}

.isotope-filter-button .ifb-text {
	margin-left: 2px;
	font-size: 16px;
	font-weight: 600;
}

/* isotope filter button icons */
.isotope-filter-button .ifb-icon {
	font-size: 16px;
	line-height: 43px;
}
.isotope-filter-button .ifb-icon-close {
	display: none;
}

/* isotope filter button on smaller screens */
@media (max-width: 992px) {
	.isotope-filter.fi-btn .isotope-filter-button {
		background-color: #e05536;
		color: #FFF;
	}

	.isotope-filter-button .ifb-icon-close,
	body.iso-filter-open .isotope-filter.fi-btn .isotope-filter-button .ifb-icon {
		display: none;
	}
	body.iso-filter-open .isotope-filter.fi-btn .isotope-filter-button .ifb-icon-close {
		display: inline-block;
	}
}

/* isotope filter button links (dropdown) */
.isotope-filter.fi-btn ul.isotope-filter-links {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 160px;
	list-style: none;
	background-color: #e05536;
	padding: 5px 0;
	text-align: left;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	-webkit-box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .4);
	-moz-box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .4);
	box-shadow: 0 10px 40px -5px rgba(0, 0, 0, .4);

	-moz-transform: translateY(5px);
	-ms-transform: translateY(5px);
	-webkit-transform: translateY(5px);
	transform: translateY(5px);

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.isotope-filter.fi-btn ul.isotope-filter-links::before {
	display: none;
}
body.iso-filter-open .isotope-filter.fi-btn ul.isotope-filter-links {
	visibility: visible;
	opacity: 1;
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.isotope-filter.fi-btn ul.isotope-filter-links > li {
	display: block;
	margin: 2px 0;
	line-height: 1.2;
}
.isotope-filter.fi-btn ul.isotope-filter-links > li > button {
	width: 100%;
	display: block;
	padding: 8px 20px;
	text-align: left;
	font-size: 15px;
	font-weight: 400;
	color: #FFF;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.isotope-filter.fi-btn ul.isotope-filter-links > li > button::after,
.isotope-filter.fi-btn ul.isotope-filter-links > li > button.active::after {
	content: none;
}
.isotope-filter.fi-btn ul.isotope-filter-links > li > button:hover,
.isotope-filter.fi-btn ul.isotope-filter-links > li > button.active {
	background-color: rgba(255, 255, 255, 0.1);
	color: #FFF;
}

/* isotope filter aligns */
.isotope-filter.fi-btn.fi-right {
	left: auto;
	right: 2%;
}
.isotope-filter.fi-btn.fi-right ul.isotope-filter-links {
	left: auto;
	right: 0;
}

/* if class "fi-btn-on" exist (in <body> tag)*/
body.fi-btn-on .isotope-top-content {
	margin-top: 0 !important;
	padding: 0;
}

body.fi-btn-on .isotope.iso-gutter-1 .isotope-top-content {
	padding-top: 6px;
	padding-bottom: 0;
}
body.fi-btn-on .isotope.iso-gutter-2 .isotope-top-content {
	padding-top: 1.5%;
	padding-bottom: 0;
}
body.fi-btn-on .isotope.iso-gutter-3 .isotope-top-content {
	padding-top: 2.5%;
	padding-bottom: 0;
}
body.fi-btn-on .isotope.iso-gutter-4 .isotope-top-content,
body.fi-btn-on .isotope.iso-gutter-5 .isotope-top-content,
body.fi-btn-on .isotope.iso-gutter-6 .isotope-top-content {
	padding-top: 3.5%;
	padding-bottom: 0;
}

/* if page header enabled */
body.page-header-on .isotope-filter.fi-btn {
	left: 3%;
	right: auto;
}
body.page-header-on .isotope-filter.fi-btn.fi-right {
	left: auto;
	right: 3%;
}

body.page-header-on .isotope-filter.fi-btn {
	top: -80px;
}
body.page-header-on .isotope.iso-gutter-1 .isotope-filter.fi-btn {
	top: -64px;
}
body.page-header-on .isotope.iso-gutter-2 .isotope-filter.fi-btn {
	top: -60px;
}
body.page-header-on .isotope.iso-gutter-3 .isotope-filter.fi-btn {
	top: -40px;
}
body.page-header-on .isotope.iso-gutter-4 .isotope-filter.fi-btn,
body.page-header-on .isotope.iso-gutter-5 .isotope-filter.fi-btn,
body.page-header-on .isotope.iso-gutter-6 .isotope-filter.fi-btn {
	top: -40px;
}
@media (max-width: 992px) {
	body.page-header-on .isotope-filter.fi-btn,
	body.page-header-on .isotope.iso-gutter-1 .isotope-filter.fi-btn,
	body.page-header-on .isotope.iso-gutter-2 .isotope-filter.fi-btn,
	body.page-header-on .isotope.iso-gutter-3 .isotope-filter.fi-btn,
	body.page-header-on .isotope.iso-gutter-4 .isotope-filter.fi-btn,
	body.page-header-on .isotope.iso-gutter-5 .isotope-filter.fi-btn,
	body.page-header-on .isotope.iso-gutter-6 .isotope-filter.fi-btn {
		top: -70px !important;
	}
}

/* if page header image enabled */
body.page-header-image-on .isotope-filter.fi-btn {
	top: -70px !important;
}

/* if page header background color enabled (class bg-* exist) */
body.page-header-on.page-header-bg-on .isotope.iso-gutter-1 .isotope-filter.fi-btn, 
body.page-header-on.page-header-bg-on .isotope.iso-gutter-2 .isotope-filter.fi-btn, 
body.page-header-on.page-header-bg-on .isotope.iso-gutter-3 .isotope-filter.fi-btn,
body.page-header-on.page-header-bg-on .isotope.iso-gutter-4 .isotope-filter.fi-btn, 
body.page-header-on.page-header-bg-on .isotope.iso-gutter-5 .isotope-filter.fi-btn, 
body.page-header-on.page-header-bg-on .isotope.iso-gutter-6 .isotope-filter.fi-btn {
	top: -70px;
}

/* if page boxed layout enabled */
body.tt-boxed .tt-wrap .isotope-filter.fi-btn {
	top: 60px;
	left: 0;
	margin: 0;
}
body.tt-boxed .tt-wrap .isotope-filter.fi-btn.fi-right {
	left: auto;
	right: 0;
}

@media (min-width: 992px) {
	body.tt-boxed .tt-wrap .isotope-filter.fi-btn {
		top: 70px;
	}
	body.tt-boxed.page-header-on .tt-wrap .isotope-filter.fi-btn {
		top: -40px;
	}
}
	
body.tt-boxed.fi-btn-on .tt-wrap .isotope-top-content,
body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-1 .isotope-top-content,
body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-2 .isotope-top-content,
body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-3 .isotope-top-content {
	padding-top: 40px;
}
body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-4 .isotope-top-content,
body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-5 .isotope-top-content,
body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-6 .isotope-top-content {
	padding-top: 60px;
}
@media (max-width: 991px) {
	body.tt-boxed.fi-btn-on .tt-wrap .isotope-top-content,
	body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-1 .isotope-top-content,
	body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-2 .isotope-top-content,
	body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-3 .isotope-top-content
	body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-4 .isotope-top-content,
	body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-5 .isotope-top-content,
	body.tt-boxed.fi-btn-on .tt-wrap .isotope.iso-gutter-6 .isotope-top-content {
		padding-top: 20px;
	}
}


/* Isotope filter button styles 
================================ */

/* filter button dark style */
.isotope-filter.fi-btn-dark .isotope-filter-button {
	background-color: #000;
	color: #FFF;
}
body.iso-filter-open .isotope-filter.fi-btn-dark .isotope-filter-button {
	background-color: #000;
	color: #FFF;
}

/* filter button light style */
.isotope-filter.fi-btn-light .isotope-filter-button {
	background-color: #FFF;
	color: #000;
}
body.iso-filter-open .isotope-filter.fi-btn-light .isotope-filter-button {
	background-color: #FFF;
	color: #000;
}

@media (min-width: 992px) { /* no effect on smaller screens */

	/* filter button transparent dark style */
	.isotope-filter.fi-btn-tansparent-dark .isotope-filter-button {
		background-color: transparent;
		padding: 0;
		color: #000;
	}
	body.iso-filter-open .isotope-filter.fi-btn-tansparent-dark .isotope-filter-button {
		background-color: transparent;
		color: #000;
	}
	.isotope-filter.fi-btn-tansparent-dark .isotope-filter-button .ifb-icon {
		color: #e05536;
	}

	/* filter button transparent light style */
	.isotope-filter.fi-btn-tansparent-light .isotope-filter-button {
		background-color: transparent;
		padding: 0;
		color: #FFF;
	}
	body.iso-filter-open .isotope-filter.fi-btn-tansparent-light .isotope-filter-button {
		background-color: transparent;
		color: #FFF;
	}

}


/* Isotope filter button dropdown styles 
========================================= */

/* filter button dropdown dark style */
.isotope-filter.fi-btn-dropdown-dark ul.isotope-filter-links {
	background-color: #000;
}
.isotope-filter.fi-btn-dropdown-dark ul.isotope-filter-links > li > button {
	color: #DDD;
}
.isotope-filter.fi-btn-dropdown-dark ul.isotope-filter-links > li > button:hover,
.isotope-filter.fi-btn-dropdown-dark ul.isotope-filter-links > li > button.active {
	background-color: rgba(255, 255, 255, 0.12);
	color: #FFF;
}

/* filter button dropdown light style */
.isotope-filter.fi-btn-dropdown-light ul.isotope-filter-links {
	background-color: #FFF;
}
.isotope-filter.fi-btn-dropdown-light ul.isotope-filter-links > li > button {
	color: #333;
}
.isotope-filter.fi-btn-dropdown-light ul.isotope-filter-links > li > button:hover,
.isotope-filter.fi-btn-dropdown-light ul.isotope-filter-links > li > button.active {
	background-color: rgba(158, 158, 158, 0.15);
	color: #000;
}


/* ====================
	Isotope items wrap  
======================= */
.isotope-items-wrap {
	margin-bottom: -1px;
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

/* isotope items wrap (if gutter enabled) */
.isotope.iso-gutter-1 .isotope-items-wrap {
	margin-top: 6px;
	margin-left: 6px;
	margin-right: 6px;
}
.isotope.iso-gutter-2 .isotope-items-wrap {
	margin-top: 1.5%;
	margin-left: 1.5%;
	margin-right: 1.5%;
}
.isotope.iso-gutter-3 .isotope-items-wrap {
	margin-top: 2.5%;
	margin-left: 2.5%;
	margin-right: 2.5%;
}
.isotope.iso-gutter-4 .isotope-items-wrap {
	margin-top: 2.5%;
	margin-left: 3.5%;
	margin-right: 3.5%;
}
.isotope.iso-gutter-5 .isotope-items-wrap {
	margin-top: 2.5%;
	margin-left: 4.5%;
	margin-right: 4.5%;
}
.isotope.iso-gutter-6 .isotope-items-wrap {
	margin-top: 2.5%;
	margin-left: 6.5%;
	margin-right: 6.5%;
}

/* if boxed layout enabled */
body.tt-boxed .tt-wrap .isotope-items-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-3 .isotope-items-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-4 .isotope-items-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-5 .isotope-items-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-6 .isotope-items-wrap {
	margin-left: 0;
	margin-right: 0;
}
@media (max-width: 991px) {
	body.tt-boxed .tt-wrap .isotope-items-wrap,
	body.tt-boxed .tt-wrap .isotope.iso-gutter-3 .isotope-items-wrap,
	body.tt-boxed .tt-wrap .isotope.iso-gutter-4 .isotope-items-wrap,
	body.tt-boxed .tt-wrap .isotope.iso-gutter-5 .isotope-items-wrap,
	body.tt-boxed .tt-wrap .isotope.iso-gutter-6 .isotope-items-wrap {
		margin-top: 20px;
	}
}

/* if "isotope-top-content" exist */
body.iso-top-content-on .isotope .isotope-items-wrap {
	margin-top: 0 !important;
}

/* if "page-header" exist */
body.page-header-on.tt-boxed .tt-wrap .isotope-items-wrap,
body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-3 .isotope-items-wrap,
body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-4 .isotope-items-wrap,
body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-5 .isotope-items-wrap,
body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-6 .isotope-items-wrap {
	margin-top: 80px;
}
@media (max-width: 991px) {
	body.page-header-on.tt-boxed .tt-wrap .isotope-items-wrap,
	body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-3 .isotope-items-wrap,
	body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-4 .isotope-items-wrap,
	body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-5 .isotope-items-wrap,
	body.page-header-on.tt-boxed .tt-wrap .isotope.iso-gutter-6 .isotope-items-wrap {
		margin-top: 20px;
	}
}


/* isotope item 
================ */
.isotope-item {
	float: left;
	width: 33.33333333%;
}

/* isotope last item (it makes last item to 100% wide). Class: "iso-last-item" */
@media (max-width : 991px) {
	.isotope-item.iso-last-item {
		width: 100% !important;
	}
}


/* isotope item heights 
======================== */
/* isotope item height-1 (use class "iso-height-1") */
.isotope-item.iso-height-1 > div,
.isotope-item.iso-height-1 > a,
.isotope-item.iso-height-1 > article,
.isotope-item.iso-height-1 > figure {
	display: block;
	padding-bottom: 70%;
}

/* isotope item height-2 (use class "iso-height-2") */
.isotope-item.iso-height-2 > div,
.isotope-item.iso-height-2 > a,
.isotope-item.iso-height-2 > article,
.isotope-item.iso-height-2 > figure {
	display: block;
	padding-bottom: 140%;
}

/* isotope item height-2 if "iso-gutter-1" enabled */
.isotope.iso-col-2.iso-gutter-1 .isotope-item.iso-height-2 > div,
.isotope.iso-col-2.iso-gutter-1 .isotope-item.iso-height-2 > a,
.isotope.iso-col-2.iso-gutter-1 .isotope-item.iso-height-2 > article,
.isotope.iso-col-2.iso-gutter-1 .isotope-item.iso-height-2 > figure {
	padding-bottom: 140.6%;
}
.isotope.iso-col-3.iso-gutter-1 .isotope-item.iso-height-2 > div,
.isotope.iso-col-3.iso-gutter-1 .isotope-item.iso-height-2 > a,
.isotope.iso-col-3.iso-gutter-1 .isotope-item.iso-height-2 > article,
.isotope.iso-col-3.iso-gutter-1 .isotope-item.iso-height-2 > figure {
	padding-bottom: 140.9%;
}
.isotope.iso-col-4.iso-gutter-1 .isotope-item.iso-height-2 > div,
.isotope.iso-col-4.iso-gutter-1 .isotope-item.iso-height-2 > a,
.isotope.iso-col-4.iso-gutter-1 .isotope-item.iso-height-2 > article,
.isotope.iso-col-4.iso-gutter-1 .isotope-item.iso-height-2 > figure {
	padding-bottom: 141.4%;
}

/* isotope item height-2 if "iso-gutter-2" enabled */
.isotope.iso-col-2.iso-gutter-2 .isotope-item.iso-height-2 > div,
.isotope.iso-col-2.iso-gutter-2 .isotope-item.iso-height-2 > a,
.isotope.iso-col-2.iso-gutter-2 .isotope-item.iso-height-2 > article,
.isotope.iso-col-2.iso-gutter-2 .isotope-item.iso-height-2 > figure {
	padding-bottom: 143.1%;
}
.isotope.iso-col-3.iso-gutter-2 .isotope-item.iso-height-2 > div,
.isotope.iso-col-3.iso-gutter-2 .isotope-item.iso-height-2 > a,
.isotope.iso-col-3.iso-gutter-2 .isotope-item.iso-height-2 > article,
.isotope.iso-col-3.iso-gutter-2 .isotope-item.iso-height-2 > figure {
	padding-bottom: 144.7%;
}
.isotope.iso-col-4.iso-gutter-2 .isotope-item.iso-height-2 > div,
.isotope.iso-col-4.iso-gutter-2 .isotope-item.iso-height-2 > a,
.isotope.iso-col-4.iso-gutter-2 .isotope-item.iso-height-2 > article,
.isotope.iso-col-4.iso-gutter-2 .isotope-item.iso-height-2 > figure {
	padding-bottom: 146.2%;
}

/* isotope item height-2 if "iso-gutter-3" enabled */
.isotope.iso-col-2.iso-gutter-3 .isotope-item.iso-height-2 > div,
.isotope.iso-col-2.iso-gutter-3 .isotope-item.iso-height-2 > a,
.isotope.iso-col-2.iso-gutter-3 .isotope-item.iso-height-2 > article,
.isotope.iso-col-2.iso-gutter-3 .isotope-item.iso-height-2 > figure {
	padding-bottom: 145.2%;
}
.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > div,
.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > a,
.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > article,
.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > figure {
	padding-bottom: 148%;
}
.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > div,
.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > a,
.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > article,
.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > figure {
	padding-bottom: 150.9%;
}

/* isotope item height-2 if "iso-gutter-4" enabled */
.isotope.iso-col-2.iso-gutter-4 .isotope-item.iso-height-2 > div,
.isotope.iso-col-2.iso-gutter-4 .isotope-item.iso-height-2 > a,
.isotope.iso-col-2.iso-gutter-4 .isotope-item.iso-height-2 > article,
.isotope.iso-col-2.iso-gutter-4 .isotope-item.iso-height-2 > figure {
	padding-bottom: 147.4%;
}
.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > div,
.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > a,
.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > article,
.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > figure {
	padding-bottom: 151.4%;
}
.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > div,
.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > a,
.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > article,
.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > figure {
	padding-bottom: 156%;
}

/* isotope item height-2 if "iso-gutter-5" enabled */
.isotope.iso-col-2.iso-gutter-5 .isotope-item.iso-height-2 > div,
.isotope.iso-col-2.iso-gutter-5 .isotope-item.iso-height-2 > a,
.isotope.iso-col-2.iso-gutter-5 .isotope-item.iso-height-2 > article,
.isotope.iso-col-2.iso-gutter-5 .isotope-item.iso-height-2 > figure {
	padding-bottom: 149.6%;
}
.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > div,
.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > a,
.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > article,
.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > figure {
	padding-bottom: 155%;
}
.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > div,
.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > a,
.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > article,
.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > figure {
	padding-bottom: 161.6%;
}

/* isotope item height-2 if "iso-gutter-6" enabled */
.isotope.iso-col-2.iso-gutter-6 .isotope-item.iso-height-2 > div,
.isotope.iso-col-2.iso-gutter-6 .isotope-item.iso-height-2 > a,
.isotope.iso-col-2.iso-gutter-6 .isotope-item.iso-height-2 > article,
.isotope.iso-col-2.iso-gutter-6 .isotope-item.iso-height-2 > figure {
	padding-bottom: 154.5%;
}
.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > div,
.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > a,
.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > article,
.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > figure {
	padding-bottom: 164%;
}
.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > div,
.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > a,
.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > article,
.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > figure {
	padding-bottom: 174.6%;
}

@media (max-width: 1200px) {
	/* isotope item height-2 if "iso-gutter-4" enabled */
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > figure,

	/* isotope item height-2 if "iso-gutter-5" enabled */
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > figure,

	/* isotope item height-2 if "iso-gutter-6" enabled */
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > figure,
}

@media (max-width: 991px) {
	/* isotope item height-2 if "iso-gutter-3" enabled */
	.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-3.iso-gutter-3 .isotope-item.iso-height-2 > figure {
		padding-bottom: 145.1%;
	}
	.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-3 .isotope-item.iso-height-2 > figure {
		padding-bottom: 145.1%;
	}

	/* isotope item height-2 if "iso-gutter-4" enabled */
	.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-3.iso-gutter-4 .isotope-item.iso-height-2 > figure,

	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-4 .isotope-item.iso-height-2 > figure,

	/* isotope item height-2 if "iso-gutter-5" enabled */
	.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-3.iso-gutter-5 .isotope-item.iso-height-2 > figure,

	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-5 .isotope-item.iso-height-2 > figure,

	/* isotope item height-2 if "iso-gutter-6" enabled */
	.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-3.iso-gutter-6 .isotope-item.iso-height-2 > figure,

	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-4.iso-gutter-6 .isotope-item.iso-height-2 > figure,
}

/* isotope iso-col-1 if "iso-height-1" enabled */
.isotope.iso-col-1 .isotope-item.iso-height-1 > div,
.isotope.iso-col-1 .isotope-item.iso-height-1 > a,
.isotope.iso-col-1 .isotope-item.iso-height-1 > article,
.isotope.iso-col-1 .isotope-item.iso-height-1 > figure {
	display: block;
	padding-bottom: 30%;
}
@media (max-width: 768px) {
	.isotope.iso-col-1 .isotope-item.iso-height-1 > div,
	.isotope.iso-col-1 .isotope-item.iso-height-1 > a,
	.isotope.iso-col-1 .isotope-item.iso-height-1 > article,
	.isotope.iso-col-1 .isotope-item.iso-height-1 > figure {
		padding-bottom: 60%;
	}
}
/* isotope iso-col-1 if "iso-height-2" enabled */
.isotope.iso-col-1 .isotope-item.iso-height-2 > div,
.isotope.iso-col-1 .isotope-item.iso-height-2 > a,
.isotope.iso-col-1 .isotope-item.iso-height-2 > article,
.isotope.iso-col-1 .isotope-item.iso-height-2 > figure {
	display: block;
	padding-bottom: 50%;
}
@media (max-width: 768px) {
	.isotope.iso-col-1 .isotope-item.iso-height-2 > div,
	.isotope.iso-col-1 .isotope-item.iso-height-2 > a,
	.isotope.iso-col-1 .isotope-item.iso-height-2 > article,
	.isotope.iso-col-1 .isotope-item.iso-height-2 > figure {
		padding-bottom: 60%;
	}
}


/* Isotope gutter (add more space between isotope items) 
=========================================================*/
/* iso-gutter-1 */
.isotope.iso-gutter-1 .isotope-item { padding: 0 0 6px 6px; }
.isotope.iso-gutter-1 { margin-left: -6px; }

/* iso-gutter-2 */
.isotope.iso-gutter-2 .isotope-item { padding: 0 0 1.5% 1.5%; }
.isotope.iso-gutter-2 { margin-left: -1.5%; }

/* iso-gutter-3 */
.isotope.iso-gutter-3 .isotope-item { padding: 0 0 2.5% 2.5%; }
.isotope.iso-gutter-3 { margin-left: -2.5%; }

/* iso-gutter-4 */
.isotope.iso-gutter-4 .isotope-item { padding: 0 0 3.5% 3.5%; }
.isotope.iso-gutter-4 { margin-left: -3.6%; }

/* iso-gutter-5 */
.isotope.iso-gutter-5 .isotope-item { padding: 0 0 4.5% 4.5%; }
.isotope.iso-gutter-5 { margin-left: -4.7%; }

/* iso-gutter-6 */
.isotope.iso-gutter-6 .isotope-item { padding: 0 0 6.5% 6.5%; }
.isotope.iso-gutter-6 { margin-left: -6.8%; }


/* Isotope columns 
=================== */
.isotope.iso-col-1 .isotope-item {
	width: 100%;
}
.isotope.iso-col-2 .isotope-item {
	width: 50%;
}
.isotope.iso-col-3 .isotope-item {
	width: 33.33333333%;
}
.isotope.iso-col-4 .isotope-item {
	width: 25%;
}

/* isotope grid sizer */
.grid-sizer {
	width: 33.33333333%;
}
.isotope.iso-col-1 .grid-sizer {
	width: 100%;
}
.isotope.iso-col-2 .grid-sizer {
	width: 50%;
}
.isotope.iso-col-3 .grid-sizer {
	width: 33.33333333%;
}
.isotope.iso-col-4 .grid-sizer {
	width: 25%;
}

/* isotope item width (alternative width) */
.isotope.iso-col-2 .width-2 { width: 100%; }
.isotope.iso-col-3 .width-2 { width: 66.66666666%; }
.isotope.iso-col-4 .width-2 { width: 50%; }


/* Isotope columns on smaller screens 
====================================== */
@media (max-width: 1200px) {
	.isotope.iso-col-4 .isotope-item,
	.isotope.iso-col-4 .grid-sizer {
		width: 33.33333333%;
	}
	.isotope.iso-col-4 .width-2 {
		width: 66.66666666%;
	}
}

@media (max-width: 991px) {
	.isotope-item,
	.isotope.iso-col-3 .isotope-item,
	.isotope.iso-col-4 .isotope-item,
	
	.grid-sizer,
	.isotope.iso-col-3 .grid-sizer,
	.isotope.iso-col-4 .grid-sizer,

	.isotope.iso-col-3 .width-2,
	.isotope.iso-col-4 .width-2 {
		width: 50%;
	}
}

@media (max-width: 768px) {
	.isotope-item,
	.isotope.iso-col-2 .isotope-item,
	.isotope.iso-col-3 .isotope-item,
	.isotope.iso-col-4 .isotope-item,

	.grid-sizer,
	.isotope.iso-col-2 .grid-sizer,
	.isotope.iso-col-3 .grid-sizer,
	.isotope.iso-col-4 .grid-sizer,
	
	.isotope.iso-col-2 .width-2,
	.isotope.iso-col-3 .width-2,
	.isotope.iso-col-4 .width-2 {
		width: 100%;
	}
}


/* Isotope pagination (tt-pagination)
====================================== */
.isotope .tt-pagination-wrap {
	margin-top: 40px;
	padding-top: 40px;
	padding-bottom: 40px;
}
.isotope .tt-pagination {
}

.isotope.iso-col-1:not([class*="iso-gutter-"]) .tt-pagination-wrap {
	margin-bottom: 40px;
}

.isotope.iso-gutter-1 .tt-pagination-wrap {
	margin-top: 40px;
}
.isotope.iso-gutter-2 .tt-pagination-wrap {
	margin-top: 30px;
}
.isotope.iso-gutter-3 .tt-pagination-wrap {
	margin-top: 10px;
}
.isotope.iso-gutter-4 .tt-pagination-wrap,
.isotope.iso-gutter-5 .tt-pagination-wrap,
.isotope.iso-gutter-6 .tt-pagination-wrap {
	margin-top: 0;
}

.isotope.iso-gutter-1 .tt-pagination-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-1 .tt-pagination-wrap {
	margin-left: 6px;
}
.isotope.iso-gutter-2 .tt-pagination-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-2 .tt-pagination-wrap {
	margin-left: 1.5%;
}
.isotope.iso-gutter-3 .tt-pagination-wrap,
body.tt-boxed .tt-wrap .isotope.iso-gutter-3 .tt-pagination-wrap {
	margin-left: 2.5%;
}
.isotope.iso-gutter-4 .tt-pagination-wrap {
	margin-left: 4.5%;
	margin-right: 1%;
	padding-top: 20px;
}
.isotope.iso-gutter-5 .tt-pagination-wrap {
	margin-left: 6.5%;
	margin-right: 2%;
	padding-top: 15px;
}
.isotope.iso-gutter-6 .tt-pagination-wrap {
	margin-left: 9.5%;
	margin-right: 4%;
	padding-top: 0;
}

@media (max-width: 768px) {
	.isotope.iso-gutter-1 .tt-pagination-wrap,
	.isotope.iso-gutter-2 .tt-pagination-wrap,
	.isotope.iso-gutter-3 .tt-pagination-wrap,
	.isotope.iso-gutter-4 .tt-pagination-wrap,
	.isotope.iso-gutter-5 .tt-pagination-wrap,
	.isotope.iso-gutter-6 .tt-pagination-wrap {
		padding-top: 30px;
		padding-bottom: 10px;
	}
}

/* if boxed layout enabled */
body.tt-boxed .tt-wrap .isotope.iso-gutter-4 .tt-pagination-wrap {
	margin-left: 3.5%;
	margin-right: 0;
}
body.tt-boxed .tt-wrap .isotope.iso-gutter-5 .tt-pagination-wrap {
	margin-left: 4.5%;
	margin-right: 0;
}
body.tt-boxed .tt-wrap .isotope.iso-gutter-6 .tt-pagination-wrap {
	margin-left: 6.5%;
	margin-right: 0;
}


/* Isotope load more button (Note: "Load More" button is for design purposes only!) 
============================ */
.iso-load-more-wrap {
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;
}

.isotope.iso-gutter-1 .iso-load-more-wrap {
	margin-left: 6px;
}
.isotope.iso-gutter-2 .iso-load-more-wrap {
	padding-top: 30px;
	margin-left: 1.5%;
}
.isotope.iso-gutter-3 .iso-load-more-wrap {
	padding-top: 25px;
	margin-left: 2.5%;
}
.isotope.iso-gutter-4 .iso-load-more-wrap {
	padding-top: 15px;
	margin-left: 3.5%;
}
.isotope.iso-gutter-5 .iso-load-more-wrap {
	padding-top: 10px;
	margin-left: 4.5%;
}
.isotope.iso-gutter-6 .iso-load-more-wrap {
	padding-top: 0;
	margin-left: 6.5%;
}

@media (max-width: 768px) {
	.iso-load-more-wrap,
	.isotope.iso-gutter-1 .iso-load-more-wrap,
	.isotope.iso-gutter-2 .iso-load-more-wrap,
	.isotope.iso-gutter-3 .iso-load-more-wrap,
	.isotope.iso-gutter-4 .iso-load-more-wrap,
	.isotope.iso-gutter-5 .iso-load-more-wrap,
	.isotope.iso-gutter-6 .iso-load-more-wrap {
		padding-top: 30px;
	}
}

.iso-load-more-button {
	position: relative;
	display: inline-block;
	padding: 12px 26px;
	font-size: 16px;
	font-weight: normal;
	color: #333;
	border: none;
	outline: none;
	cursor: pointer;
	z-index: 1;
	overflow: hidden;
	-webkit-box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.18);
	-moz-box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.18);
	box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.18);

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;

	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.iso-load-more-button:hover {
	color: #FFF;
}

.iso-load-more-button::before {
	position: absolute;
	display: block;
	content: "";
	height: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.iso-load-more-button:hover::before {
	height: 100%
}


/* ------------------------------------------------------------- *
 * Portfolio categories
/* ------------------------------------------------------------- */

.portfolio-categories {
}

/* portfolio categories links title */
.portfolio-cat-title {
}
.portfolio-cat-title span {
	font-size: 16px;
	font-weight: bold;
	font-weight: 700;
}
.portfolio-cat-title i {
	font-size: 16px;
}


/* Portfolio categories links 
============================== */

ul.portfolio-cat-links {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
}
ul.portfolio-cat-links::before {
	display: inline-block;
	content: "";
	width: 50px;
	height: 3px;
	background-color: #000;
	margin: 0 14px 3px 0;
}

ul.portfolio-cat-links > li {
	display: inline-block;
	margin: 0 6px;
}
@media (max-width: 768px) {
	ul.portfolio-cat-links > li {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
ul.portfolio-cat-links > li:first-child {
	margin-left: 0;
}
ul.portfolio-cat-links > li:last-child {
	margin-right: 0;
}
ul.portfolio-cat-links > li > a {
	position: relative;
	display: block;
	background: none;
	padding: 6px 0;
	font-size: 16px;
	font-weight: 500;
	color: #333;
	border: none;
}
ul.portfolio-cat-links > li > a:focus {
	outline: none;
}
ul.portfolio-cat-links > li > a::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 20%;
	width: 100%;
	z-index: -1;
	height: 0;
	background: #e05536;
	opacity: .4;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
ul.portfolio-cat-links > li.active > a {
}
ul.portfolio-cat-links > li > a:hover::after,
ul.portfolio-cat-links > li.active > a::after {
	height: 10px;
}


/* Portfolio categories aligns 
=============================== */

/* cat center */
.portfolio-categories.cat-center {
	text-align: center;
}
.portfolio-categories.cat-center ul.portfolio-cat-links::before {
	display: none;
}

/* cat right */
.portfolio-categories.cat-right {
	text-align: right;
}
@media (max-width: 768px) {
	.portfolio-categories.cat-right {
		text-align: left;
	}
}


/* ------------------------------------------------------------- *
 * Portfolio grid
/* ------------------------------------------------------------- */

.portfolio-grid {
}

/* Portfolio grid item 
======================= */
.portfolio-grid-item {
	position: relative;
	display: block;
	overflow: hidden;
}

/* portfolio grid item cover */
.portfolio-grid-item::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #FFF;
	opacity: 0;
	z-index: 2;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.portfolio-grid-item:hover::before {
	opacity: .93;
}

/* portfolio grid item image */
.pgi-image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: rgba(130, 130, 130, 0.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);

	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
img.pgi-image {
	position: relative;
}

.portfolio-grid-item:hover .pgi-image {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}


/* Portfolio grid item caption 
=============================== */
.pgi-caption-wrap {
	position: absolute;
	top: 50%;
	width: 100%;
	z-index: 2;
	text-align: center;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.pgi-caption {
	padding: 0 12%;
	visibility: hidden;
	opacity: 0;
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	-webkit-transform: scale(0.9);
	transform: scale(0.9);

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media (max-width: 992px) {
	.pgi-caption {
		padding: 0 10%;
	}
}
.portfolio-grid-item:hover .pgi-caption {
	visibility: visible;
	opacity: 1;
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

/* portfolio grid item title */
.pgi-caption-title {
	margin: 0;
	padding: 0;
	font-size: 32px; /* default size */
	font-weight: 700;
	color: #111;
	line-height: 1.2;
}

/* portfolio grid item title sizes (depending on columns and screen sizes). */
.isotope.iso-col-1 .pgi-caption-title {
	font-size: 34px;
}
.isotope.iso-col-2 .pgi-caption-title {
	font-size: 32px;
}
.isotope.iso-col-3 .pgi-caption-title {
	font-size: 24px;
}
.isotope.iso-col-4 .pgi-caption-title {
	font-size: 22px;
}

body.tt-boxed .tt-wrap .isotope.iso-col-1 .pgi-caption-title {
	font-size: 28px;
}
body.tt-boxed .tt-wrap .isotope.iso-col-2 .pgi-caption-title {
	font-size: 26px;
}
body.tt-boxed .tt-wrap .isotope.iso-col-3 .pgi-caption-title {
	font-size: 24px;
}
body.tt-boxed .tt-wrap .isotope.iso-col-4 .pgi-caption-title {
	font-size: 18px;
}

@media (max-width: 1600px) {
	.pgi-caption-title,
	.isotope.iso-col-3 .pgi-caption-title {
		font-size: 24px;
	}
	.isotope.iso-col-4 .pgi-caption-title {
		font-size: 20px;
	}
}
@media (max-width: 1400px) {
	.isotope.iso-col-2 .pgi-caption-title {
		font-size: 32px;
	}
	.pgi-caption-title,
	.isotope.iso-col-3 .pgi-caption-title {
		font-size: 24px;
	}
	.isotope.iso-col-4 .pgi-caption-title {
		font-size: 20px;
	}
}
@media (max-width: 1282px) {
	.isotope.iso-col-2 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-2 .pgi-caption-title {
		font-size: 26px;
	}
	.pgi-caption-title,
	.isotope.iso-col-3 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-3 .pgi-caption-title {
		font-size: 20px;
	}
	.isotope.iso-col-4 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-4 .pgi-caption-title {
		font-size: 20px;
	}
}
@media (max-width: 992px) {
	.pgi-caption-title,
	.isotope.iso-col-2 .pgi-caption-title,
	.isotope.iso-col-3 .pgi-caption-title,
	.isotope.iso-col-4 .pgi-caption-title,

	body.tt-boxed .tt-wrap .isotope.iso-col-2 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-3 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-4 .pgi-caption-title {
		font-size: 24px;
	}
	.isotope.iso-col-1 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-1 .pgi-caption-title {
		font-size: 30px;
	}
}
@media (max-width: 768px) {
	.pgi-caption-title,
	.isotope.iso-col-2 .pgi-caption-title,
	.isotope.iso-col-3 .pgi-caption-title,
	.isotope.iso-col-4 .pgi-caption-title,

	body.tt-boxed .tt-wrap .isotope.iso-col-2 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-3 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-4 .pgi-caption-title {
		font-size: 5vw;
	}
	.isotope.iso-col-1 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-1 .pgi-caption-title {
		font-size: 5vw;
	}

}
@media (max-width: 320px) {
	.pgi-caption-title,
	.isotope.iso-col-2 .pgi-caption-title,
	.isotope.iso-col-3 .pgi-caption-title,
	.isotope.iso-col-4 .pgi-caption-title,

	body.tt-boxed .tt-wrap .isotope.iso-col-2 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-3 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-4 .pgi-caption-title {
		font-size: 7vw;
	}
	.isotope.iso-col-1 .pgi-caption-title,
	body.tt-boxed .tt-wrap .isotope.iso-col-1 .pgi-caption-title {
		font-size: 6vw;
	}
}

/* portfolio grid item category */
.pgi-caption-category {
	margin-top: 3px;
	font-size: 15px;
	font-weight: normal;
	color: #e05536;
}
@media (max-width: 1200px) {
	.pgi-caption-category {
		font-size: 13px;
	}
}
.pgi-caption-category span {
}

.pgi-caption-category span ~ span:not(:empty)::before { /* separate categories with comma */
	content: ", ";
}


/* Portfolio grid item styles 
============================== */
/* alter style 1 */
@media (min-width: 768px) {
	body.tt-boxed .tt-wrap .pgi-alter-1 .isotope-top-content {
		padding-left: 15px;
		padding-right: 15px;
	}
	body.tt-boxed .tt-wrap .pgi-alter-1 .isotope-filter.fi-btn {
		left: 15px;
	}
	body.tt-boxed .tt-wrap .pgi-alter-1 .isotope-filter.fi-btn.fi-right {
		left: auto;
		right: 15px;
	}
}

.pgi-alter-1 .portfolio-grid-item::before {
	opacity: 0;
}

body.iso-gutter-1-on .pgi-alter-1 .portfolio-grid-item::after,
body.iso-gutter-2-on .pgi-alter-1 .portfolio-grid-item::after,
body.iso-gutter-3-on .pgi-alter-1 .portfolio-grid-item::after,
body.iso-gutter-4-on .pgi-alter-1 .portfolio-grid-item::after,
body.iso-gutter-5-on .pgi-alter-1 .portfolio-grid-item::after,
body.iso-gutter-6-on .pgi-alter-1 .portfolio-grid-item::after {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	-webkit-box-shadow: inset 0 0 0 15px #FFF;
	-moz-box-shadow: inset 0 0 0 15px #FFF;
	box-shadow: inset 0 0 0 15px #FFF;

	-webkit-transition: all 0.3s cubic-bezier(0, 0.35, 0.71, 0.96);
	-moz-transition: all 0.3s cubic-bezier(0, 0.35, 0.71, 0.96);
	-o-transition: all 0.3s cubic-bezier(0, 0.35, 0.71, 0.96);
	-ms-transition: all 0.3s cubic-bezier(0, 0.35, 0.71, 0.96);
	transition: all 0.3s cubic-bezier(0, 0.35, 0.71, 0.96);
}
body.iso-gutter-1-on .pgi-alter-1 .portfolio-grid-item:hover::after,
body.iso-gutter-2-on .pgi-alter-1 .portfolio-grid-item:hover::after,
body.iso-gutter-3-on .pgi-alter-1 .portfolio-grid-item:hover::after,
body.iso-gutter-4-on .pgi-alter-1 .portfolio-grid-item:hover::after,
body.iso-gutter-5-on .pgi-alter-1 .portfolio-grid-item:hover::after,
body.iso-gutter-6-on .pgi-alter-1 .portfolio-grid-item:hover::after {
	-webkit-box-shadow: inset 0 0 0 0 #FFF;
	-moz-box-shadow: inset 0 0 0 0 #FFF;
	box-shadow: inset 0 0 0 0 #FFF;
}

body.iso-gutter-1-on .pgi-alter-1 .portfolio-grid-item .pgi-image,
body.iso-gutter-2-on .pgi-alter-1 .portfolio-grid-item .pgi-image,
body.iso-gutter-3-on .pgi-alter-1 .portfolio-grid-item .pgi-image,
body.iso-gutter-4-on .pgi-alter-1 .portfolio-grid-item .pgi-image,
body.iso-gutter-5-on .pgi-alter-1 .portfolio-grid-item .pgi-image,
body.iso-gutter-6-on .pgi-alter-1 .portfolio-grid-item .pgi-image {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	-webkit-transform: scale(1.04);
	-moz-transform: scale(1.04);
	-o-transform: scale(1.04);
	-ms-transform: scale(1.04);
	transform: scale(1.04);
}
.pgi-alter-1 .iso-col-1 .portfolio-grid-item .pgi-image {
	-webkit-transition: all 6s ease-in-out;
	-moz-transition: all 6s ease-in-out;
	-o-transition: all 6s ease-in-out;
	-ms-transition: all 6s ease-in-out;
	transition: all 6s ease-in-out;
}
body.iso-gutter-1-on .pgi-alter-1 .portfolio-grid-item:hover .pgi-image,
body.iso-gutter-2-on .pgi-alter-1 .portfolio-grid-item:hover .pgi-image,
body.iso-gutter-3-on .pgi-alter-1 .portfolio-grid-item:hover .pgi-image,
body.iso-gutter-4-on .pgi-alter-1 .portfolio-grid-item:hover .pgi-image,
body.iso-gutter-5-on .pgi-alter-1 .portfolio-grid-item:hover .pgi-image,
body.iso-gutter-6-on .pgi-alter-1 .portfolio-grid-item:hover .pgi-image {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.pgi-alter-1 .portfolio-grid-item .pgi-caption {
	visibility: visible;
	opacity: 1;
	visibility: visible;
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);
}

.pgi-alter-1 .pgi-caption-title {
	display: inline;
	background-color: #000;
	padding: 0 5px;
	color: #FFF;
}

.pgi-alter-1 .pgi-caption-category {
	margin-top: 10px;
	font-size: 13px;
	color: #FFF;
}
.pgi-alter-1 .pgi-caption-category span {
	display: inline-block;
	margin: 2px;
	padding: 0 4px;
	background-color: #e05536;
}
.pgi-alter-1 .pgi-caption-category span ~ span:not(:empty)::before { /* disable categories comma */
	content: none;
}

/* alter style 2 */
.pgi-alter-2 .portfolio-grid-item::before {
	display: none;
}
.pgi-alter-2 .portfolio-grid-item .pgi-image {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);

	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
body.tt-boxed .tt-wrap .pgi-alter-2 .iso-col-1 .portfolio-grid-item .pgi-image {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.pgi-alter-2 .iso-col-1 .portfolio-grid-item .pgi-image {
	-webkit-transition: all 6s ease-in-out;
	-moz-transition: all 6s ease-in-out;
	-o-transition: all 6s ease-in-out;
	-ms-transition: all 6s ease-in-out;
	transition: all 6s ease-in-out;
}
.pgi-alter-2 .portfolio-grid-item:hover .pgi-image {
	-webkit-transform: scale(1.07);
	-moz-transform: scale(1.07);
	-o-transform: scale(1.07);
	-ms-transform: scale(1.07);
	transform: scale(1.07);
}

.pgi-alter-2 .pgi-caption-wrap {
	left: 50%;
	padding: 15px 10%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.pgi-alter-2 .portfolio-grid-item .pgi-caption {
	display: inline-block;
	background-color: rgba(255, 255, 255, 0.92);
	padding: 15px 20px;
	visibility: visible;
	opacity: 1;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}

.pgi-alter-2 .pgi-caption-title {
	color: #000;
}

.pgi-alter-2 .pgi-caption-category {
	margin-top: 5px;
	font-size: 15px;
	font-weight: 500;
	color: #e05536;
}


/* Portfolio grid item caption positions 
========================================= */
/* bottom */
.pgi-caption-bottom .pgi-caption-wrap {
	top: auto;
	left: 7%;
	bottom: 10%;
	max-width: 70%;
	text-align: left;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.pgi-caption-bottom .pgi-caption {
	padding: 0;
}
.pgi-caption-bottom.pgi-alter-2 .pgi-caption-wrap {
	padding: 0;
}


/* ------------------------------------------------------------- *
 * Portfolio carousel
/* ------------------------------------------------------------- */

.portfolio-carousel {
}


/* Portfolio carousel caption
============================== */
.portfolio-carousel .portfolio-carousel-caption {
	position: absolute;
	left: 7%;
	bottom: 10%;
	z-index: 1;

	-webkit-transform: translateY(10px);
	-moz-transform: translateY(10px);
	-ms-transform: translateY(10px);
	-o-transform: translateY(10px);
	transform: translateY(10px);

	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

/* carousel caption max width */
body.tt-boxed .tt-wrap .portfolio-carousel .portfolio-carousel-caption {
	max-width: 70%;
}
.portfolio-carousel .owl-carousel[data-items="1"] .owl-item .portfolio-carousel-caption {
	max-width: 40%;
}
@media (max-width: 992px) {
	.portfolio-carousel .owl-carousel[data-items="1"] .owl-item .portfolio-carousel-caption {
		max-width: 70%;
	}
}

/* carousel caption title */
.portfolio-carousel .pc-caption-title {
	display: inline;
	background-color: #000;
	margin: 0;
	padding: 0 5px;
	font-size: 24px;
	color: #FFF;
}
@media (max-width: 992px) {
	.portfolio-carousel .pc-caption-title {
		font-size: 18px;
	}
}

/* carousel caption category */
.portfolio-carousel .pc-caption-category {
	margin-top: 8px;
}
@media (max-width: 992px) {
	.portfolio-carousel .pc-caption-category {
		margin-top: 0;
	}
}

.portfolio-carousel .pc-caption-category span {
	display: inline-block;
	margin: 2px 2px 2px 0;
	padding: 0 4px;
	background-color: #e05536;
	font-size: 13px;
	color: #FFF;
}

/* carousel caption alternative style */
.portfolio-carousel.pc-caption-alter .portfolio-carousel-caption {
	display: inline-block;
	background-color: rgba(255, 255, 255, 0.92);
	padding: 2% 3%;
}
@media (max-width: 768px) {
	.portfolio-carousel.pc-caption-alter .portfolio-carousel-caption {
		padding: 5% 6%;
	}
}

.portfolio-carousel.pc-caption-alter .pc-caption-title {
	display: block;
	background-color: transparent;
	margin: 0;
	padding: 0;
	font-size: 24px;
	color: #222;
}
@media (max-width: 992px) {
	.portfolio-carousel.pc-caption-alter .pc-caption-title {
		font-size: 20px;
	}
}

.portfolio-carousel.pc-caption-alter .pc-caption-category {
	margin-top: 4px;
}

.portfolio-carousel.pc-caption-alter .pc-caption-category span {
	background-color: transparent;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	color: #e05536;
}

/* caption if only one carousel item enabled */
.portfolio-carousel .owl-carousel[data-items="1"] .owl-item .portfolio-carousel-caption {
	left: 10%;
	bottom: 15%;
	opacity: 0;
	visibility: hidden;
}
.portfolio-carousel .owl-carousel[data-items="1"] .owl-item.active .portfolio-carousel-caption {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);

	-webkit-transition-delay: 0.7s;
	-moz-transition-delay: 0.7s;
	transition-delay: 0.7s;
}

@media (min-width: 992px) {
	.portfolio-carousel .owl-carousel[data-items="1"] .owl-item .pc-caption-title {
		font-size: 38px;
	}
}
@media (max-width: 991px) {
	.portfolio-carousel .owl-carousel[data-items="1"] .owl-item .pc-caption-title {
		font-size: 24px;
	}
}


/* Portfolio carousel alternative style
======================================== */
.portfolio-carousel.pc-alter-style {
	background-color: rgba(138, 138, 138, 0.1);
}

.portfolio-carousel.pc-alter-style .owl-carousel {
	padding: 60px 60px 30px 60px;
}
@media (max-width: 991px) {
	.portfolio-carousel.pc-alter-style .owl-carousel {
		padding: 0;
	}
}

@media (min-width: 992px) {
	.portfolio-carousel.pc-alter-style .owl-carousel .owl-stage-outer {
		padding-bottom: 40px;
	}
}

@media (min-width: 991px) {
	.portfolio-carousel.pc-alter-style .owl-carousel .owl-item {
		-moz-box-shadow: 0 30px 20px -20px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 30px 20px -20px rgba(0, 0, 0, 0.3);
		box-shadow: 0 30px 20px -20px rgba(0, 0, 0, 0.3);
	}
}

/* portfolio carousel dots */
.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside {
	margin-bottom: 0;
}
.portfolio-carousel.pc-alter-style .owl-carousel .owl-dots, 
.portfolio-carousel.pc-alter-style .owl-carousel .owl-nav.disabled + .owl-dots {
	bottom: 90px;
}
@media (max-width: 992px) {
	.portfolio-carousel.pc-alter-style .owl-carousel .owl-dots, 
	.portfolio-carousel.pc-alter-style .owl-carousel .owl-nav.disabled + .owl-dots {
		bottom: 10px;
	}
}
.portfolio-carousel.pc-alter-style .owl-carousel.dots-left .owl-dots, 
.portfolio-carousel.pc-alter-style .owl-carousel.dots-left .owl-nav.disabled + .owl-dots,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-right .owl-dots, 
.portfolio-carousel.pc-alter-style .owl-carousel.dots-right .owl-nav.disabled + .owl-dots,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside .owl-dots, 
.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside .owl-nav.disabled + .owl-dots {
	bottom: 25px;
}
@media (max-width: 992px) {
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-left .owl-dots, 
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-left .owl-nav.disabled + .owl-dots,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-right .owl-dots, 
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-right .owl-nav.disabled + .owl-dots,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside .owl-dots, 
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside .owl-nav.disabled + .owl-dots {
		bottom: 15px;
	}
}

/* portfolio carousel nav */
.portfolio-carousel.pc-alter-style .owl-carousel.nav-bottom-right .owl-nav,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-right.nav-bottom-right .owl-nav,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-right.dots-outside .owl-nav,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside.nav-bottom-right .owl-nav,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside.dots-right.nav-bottom-right .owl-nav,
.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside.dots-right.dots-outside .owl-nav {
	bottom: 100px;
}
@media (max-width: 992px) {
	.portfolio-carousel.pc-alter-style .owl-carousel.nav-bottom-right .owl-nav,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-right.nav-bottom-right .owl-nav,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-right.dots-outside .owl-nav,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside.nav-bottom-right .owl-nav,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside.dots-right.nav-bottom-right .owl-nav,
	.portfolio-carousel.pc-alter-style .owl-carousel.dots-outside.dots-right.dots-outside .owl-nav {
		bottom: 90px;
	}
}


/* Portfolio carousel full height sizes 
======================================== */
@media (min-width: 992px) {
	/* if dots-outside enabled */
	.portfolio-carousel .owl-carousel.owl-size-full.dots-outside .cc-item .cc-image.bg-image {
		min-height: 400px;
		height: calc(100vh - 135px);
	}

	/* if pc-alter-style enabled */
	.portfolio-carousel.pc-alter-style .owl-carousel.owl-size-full .cc-item .cc-image.bg-image {
		min-height: 400px;
		height: calc(100vh - 210px);
	}
	
	/* if footer minimal enabled */
	body.footer-minimal-on:not(.footer-fixed-on) .portfolio-carousel .owl-carousel.owl-size-full .cc-item .cc-image.bg-image {
		min-height: 400px;
		height: calc(100vh - 163px);
	}
	body.footer-minimal-on:not(.footer-fixed-on) .portfolio-carousel.pc-alter-style .owl-carousel.owl-size-full .cc-item .cc-image.bg-image {
		min-height: 400px;
		height: calc(100vh - 293px);
	}
	body.footer-minimal-on.header-transparent-on:not(.footer-fixed-on) .portfolio-carousel .owl-carousel.owl-size-full .cc-item .cc-image.bg-image {
		min-height: 400px;
		height: calc(100vh - 83px);
	}
}


/* ------------------------------------------------------------- *
 * Text portfolio (interactive links)
/* ------------------------------------------------------------- */

.text-portfolio {
	position: relative;
	min-height: 560px;
	background-color: #000;
	overflow: hidden;
}

/* Text portfolio background images 
==================================== */
.text-portfolio-bg {
}

/* text portfolio background item */
.tp-bg-item {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	background-color: #000;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-transform: scale(1.04);
	-moz-transform: scale(1.04);
	-o-transform: scale(1.04);
	-ms-transform: scale(1.04);
	transform: scale(1.04);
}
.tp-bg-item.active {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}


/* Text portfolio list 
======================= */
.text-portfolio-list-wrap {
	position: absolute;
	top: 50%;
	left: 10%;
	max-width: 800px;
	padding: 70px 15px 70px 0;
	z-index: 2;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	overflow: hidden;
}
@media (max-width: 360px) {
	.text-portfolio-list-wrap {
		top: 40%;
	}
}

.text-portfolio-list {
	height: 338px;
	overflow: hidden;
}
@media (max-width: 991px) {
	.text-portfolio-list {
		height: 245px;
	}
}
@media (max-width: 360px) {
	.text-portfolio-list {
		height: 215px;
	}
}

/* text portfolio list item */
.tpl-item {
	display: block;
	height: auto !important;
}
.tpl-item-title {
	display: inline;
	background-color: #000;
	margin: 0;
	padding: 0 10px;
	font-size: 42px;
	font-weight: bold;
	font-weight: 800;
	color: #FFF;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
@media (max-width: 991px) {
	.tpl-item-title {
		font-size: 28px;
	}
}
@media (max-width: 360px) {
	.tpl-item-title {
		font-size: 20px;
	}
}
.tpl-item:hover .tpl-item-title,
.tpl-item:focus .tpl-item-title,
.tpl-item.active .tpl-item-title {
	background-color: #e05536;
}


/* Text portfolio carousel nav buttons
======================================= */
.tpl-nav-btn {
	position: absolute;
	left: 7%;
	width: 40px;
	height: 40px;
	line-height: 42px;
	text-align: center;
	font-size: 28px;
	color: #FFF;
	cursor: pointer;
	text-shadow: 0px 4px #000;
	opacity: .8;
	outline: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.tpl-nav-btn.swiper-tpl-nav-up:hover,
.tpl-nav-btn.swiper-tpl-nav-down:hover {
	opacity: 1;
}

.tpl-nav-btn.swiper-tpl-nav-up {
	top: 0;
}
.tpl-nav-btn.swiper-tpl-nav-down {
	bottom: 0;
}

/* nav button disabled */
.tpl-nav-btn.swiper-button-disabled {
	opacity: 0;
	visibility: hidden;
}


/* ------------------------------------------------------------- *
 * Portfolio info list
/* ------------------------------------------------------------- */

.portfolio-info-list {
	margin-bottom: 30px;
}
.portfolio-info-list > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.portfolio-info-list > ul > li {
	position: relative;
	max-width: 100%;
	margin-bottom: 12px;
}

.portfolio-info-list .pi-list-heading {
	font-size: 18px;
	font-weight: 600;
}

.portfolio-info-list .pi-list-cont {
	font-size: 17px;
}
.portfolio-info-list .pi-list-cont a {
	word-wrap: break-word;
}
.portfolio-info-list .pi-list-cont a:hover {
	text-decoration: underline;
}


/* Portfolio info list if class "pi-list-inline" enabled
========================================================= */
.portfolio-info-list.pi-list-inline > ul > li {
	display: inline-block;
	max-width: 100%;
	vertical-align: top;
	padding: 0 25px 0 0;
	margin: 12px 20px 12px 0;
}
.portfolio-info-list.pi-list-inline > ul > li:last-child {
	padding-right: 0;
	margin-right: 0;
}
.portfolio-info-list.pi-list-inline > ul > li::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	right: 0;
	width: 1px;
	height: 58px;
	background-color: #DDD;
}
.portfolio-info-list.pi-list-inline > ul > li:last-child::before {
	display: none;
}

@media (max-width: 768px) {
	.portfolio-info-list.pi-list-inline > ul > li {
		display: block;
		margin-right: 0;
		padding: 0;
		text-align: left;
	}
	.portfolio-info-list.pi-list-inline > ul > li::before {
		display: none;
	}
}

.portfolio-info-list.pi-list-inline .pi-list-heading {
}


/* ------------------------------------------------------------- *
 * tt-Gallery
/* ------------------------------------------------------------- */

.tt-gallery {
}


/* tt-Gallery item 
=================== */
.tt-gallery-item {
	position: relative;
	margin-bottom: 30px;
	overflow: hidden;
}
@media (max-width: 768px) {
	.tt-gallery-item {
		margin-bottom: 15px;
	}
}
.isotope .tt-gallery-item {
	margin-bottom: 0;
}

/* tt-gallery image link */
.tt-gallery-image-wrap {
	position: relative;
	display: block;
	overflow: hidden;
}

/* tt-gallery image */
.tt-gallery-image {
	position: relative;
	z-index: 1;
}
.tt-gallery-image img {
}

/* if tt-gallery image as a background image */
.tt-gallery-image.bg-image {
	background-color: rgba(130, 130, 130, 0.1);
}

.isotope-item.iso-height-1 .tt-gallery-item .tt-gallery-image-wrap, 
.isotope-item.iso-height-2 .tt-gallery-item .tt-gallery-image-wrap,
.isotope-item.iso-height-1 .tt-gallery-item .tt-gallery-image, 
.isotope-item.iso-height-2 .tt-gallery-item .tt-gallery-image {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0;
}


/* tt-Gallery image hover 
========================== */

/* tt-gallery image hover icon */
.tt-gallery-image-icon {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 34px;
	height: 34px;
	line-height: 34px;
	background-color: #FFF;
	opacity: 0;
	visibility: hidden;
	text-align: center;
	font-size: 15px;
	color: #000;
	z-index: 3;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
a.tt-gallery-image-wrap:hover .tt-gallery-image-icon {
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

/* show icon only if "tt-gallery-image-wrap" is a link!  */
.tt-gallery-image-wrap .tt-gallery-image-icon {
	display: none;
}
a.tt-gallery-image-wrap .tt-gallery-image-icon {
	display: block;
}

/* tt-gallery image hover zoom */
.ttg-hover-zoom .tt-gallery-image {
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.ttg-hover-zoom a.tt-gallery-image-wrap:hover .tt-gallery-image {
	-webkit-transform: scale(1.06);
	-moz-transform: scale(1.06);
	-o-transform: scale(1.06);
	-ms-transform: scale(1.06);
	transform: scale(1.06);
}

/* tt-gallery image hover cover */
a.tt-gallery-image-wrap::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

/* tt-gallery image hover cover dark */
.ttg-hover-dark a.tt-gallery-image-wrap:hover::before {
	opacity: .6;
	visibility: visible;
	background-color: #000;
}
.ttg-hover-dark .tt-gallery-image-icon {
	background-color: rgba(255, 255, 255, 0.04);
	color: #FFF;
}

/* tt-gallery image hover cover light */
.ttg-hover-light a.tt-gallery-image-wrap:hover::before {
	opacity: .9;
	visibility: visible;
	background-color: #FFF;
}
.ttg-hover-light .tt-gallery-image-icon {
	background-color: rgba(0, 0, 0, 0.04);
	color: #222;
}

/* tt-gallery image hover icon position (center) */
.ttg-hover-dark .tt-gallery-image-icon,
.ttg-hover-light .tt-gallery-image-icon  {
	top: 50%;
	right: 50%;
	width: 58px;
	height: 58px;
	line-height: 58px;
	font-size: 21px;
	-moz-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}


/* tt-Gallery isotope items
============================ */
@media (max-width: 768px) {
	.tt-gallery .isotope.iso-col-2 .grid-sizer, 
	.tt-gallery .isotope.iso-col-3 .grid-sizer, 
	.tt-gallery .isotope.iso-col-4 .grid-sizer,

	.tt-gallery .isotope.iso-col-2 .isotope-item, 
	.tt-gallery .isotope.iso-col-3 .isotope-item, 
	.tt-gallery .isotope.iso-col-4 .isotope-item {
		width: 50%;
	}
}


/* ------------------------------------------------------------- *
 * Entry text nav
/* ------------------------------------------------------------- */

.entry-text-nav {
	position: relative;
}

/* entry text nav item */
.et-nav-item {
	position: relative;
	display: inline-block;
	color: #333;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.et-nav-item:hover,
.et-nav-item:focus {
	color: inherit;
}

/* entry text nav title */
.et-nav-title {
	position: relative;
	display: inline-block;
	margin: 0;
	font-size: 54px;
	font-weight: 800;
}
@media (max-width: 991px) {
	.et-nav-title {
		font-size: 38px;
	}
}
@media (max-width: 768px) {
	.et-nav-title {
		font-size: 28px;
	}
}

.et-nav-title::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 10%;
	width: 100%;
	z-index: -1;
	height: 0;
	background: #e05536;
	opacity: .4;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.et-nav-info:hover .et-nav-title::after {
	height: 40%;
}

/* entry text nav entry title */
.et-nav-entry-title {
	margin: 0;
	font-size: 24px;
}
@media (max-width: 768px) {
	.et-nav-entry-title {
		display: none;
	}
}

/* entry text nav left */
.et-nav-left {
}

/* entry text nav right */
.et-nav-right {
}

/* entry text nav center */
.et-nav-center {
	text-align: center;
	color: #333;
}
.et-nav-center:hover,
.et-nav-center:focus {
	color: #e05536;
}
.et-nav-icon {
	display: inline-block;
	margin-top: 16px;
	font-size: 32px;
}
@media (max-width: 991px) {
	.et-nav-icon {
		margin-top: 3px;
		font-size: 32px;
	}
}
@media (max-width: 768px) {
	.et-nav-icon {
		margin-top: 0;
		font-size: 23px;
	}
}

/* entry text nav info */
.et-nav-info {
}
.et-nav-left .et-nav-info {
}
.et-nav-right .et-nav-info {
}


/* Portfolio single nav hover effect 
===================================== */
@media (min-width: 992px) {
	.etn-hover .et-nav-info-wrap {
		position: relative;
		height: 70px;
		overflow: hidden;
	}

	.etn-hover .et-nav-info {
		position: relative;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	.etn-hover .et-nav-info-wrap:hover .et-nav-info {
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);

		-webkit-transition-delay: 0.1s;
		-moz-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}

	.etn-hover .et-nav-title,
	.etn-hover .et-nav-entry-title {
		line-height: 70px;
	}
	.etn-hover .et-nav-entry-title {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.etn-hover .et-nav-title::after {
		display: none;
	}
}


/* ----------------------------------------------------------------------- *
 * Lightgallery (lightbox plugin)
 * More info: http://sachinchoolur.github.io/lightGallery)
/* ----------------------------------------------------------------------- */

body.lg-on {
   overflow: hidden !important;
}
.lg-backdrop {
	z-index: 99999;
}
.lg-outer {
	z-index: 99999;
}

/* Lightgallery sub html (image caption) */
.lg-sub-html {
	font-size: 16px;
}
@media (max-width : 992px) {
	.lg-sub-html {
		padding: 10px 10px 40px 10px;
		font-size: 14px;
	}
}

.lg-sub-html p {
	display: inline-block;
	max-width: 1000px;
	font-size: 16px;
}

/* Lightgallery thumbnails improvements */
.lg-outer .lg-thumb {
	margin-left: auto;
	margin-right: auto;
}
.lg-outer .lg-thumb-item {
	position: relative;
	border: 2px solid #FFF;
	border-radius: 0;
}
.lg-outer .lg-thumb-item img {
	position: absolute;
	left: 50%;
	top: 50%;
	max-width: inherit;
	width: 150%;
	height: auto;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* Lightgallery autoplay progress bar improvements */
.lg-progress-bar {
	background-color: #333;
	height: 3px;
}
.lg-progress-bar .lg-progress {
	background-color: #a90707;
	height: 3px;
}

/* hide next/prev buttons on small screens */
@media (max-width: 768px) {
	.lg-actions .lg-next, .lg-actions .lg-prev {
		display: none;
	}
}


/* ------------------------------------------------------------- *
 * Split box
/* ------------------------------------------------------------- */

.split-box {
}

/* Split box image 
=================== */
.split-box-image {
	position: relative;
	background-color: rgba(130, 130, 130, 0.1);
}
img.split-box-image {
	background-color: transparent;
}

/* split box image height */
.split-box-image-height {
}
@media (max-width: 991px) {
	.split-box-image-height {
		padding-bottom: 70% !important;
	}
}

/* split box image shadow (for boxed layout only!) */
body.tt-boxed .tt-wrap .split-box-image.sbi-shadow {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: 0px 30px 40px -24px rgba(0, 0, 0, 0.45);
	-moz-box-shadow: 0px 30px 40px -24px rgba(0, 0, 0, 0.45);
	box-shadow: 0px 30px 40px -24px rgba(0, 0, 0, 0.45);
}
body.tt-boxed .tt-wrap .split-box-image.sbi-shadow:hover {
	-webkit-box-shadow: 0px 25px 40px -15px rgba(0, 0, 0, 0.55);
	-moz-box-shadow: 0px 25px 40px -15px rgba(0, 0, 0, 0.55);
	box-shadow: 0px 25px 40px -15px rgba(0, 0, 0, 0.55);
}

/* Split box content 
===================== */
.split-box-content {
	position: relative;
}
.split-box-content-inner {
	max-height: 100%; 
	padding: 10%;
}

/* If boxed layout enabled */
body.tt-boxed .tt-wrap .split-box-content-inner {
	padding-left: 0;
}
body.tt-boxed .tt-wrap .split-box-image + .split-box-content .split-box-content-inner {
	padding-left: 10%;
	padding-right: 0;
}

body.tt-boxed .tt-wrap .split-box-content + .split-box-content .split-box-content-inner {
	padding-left: 10%;
	padding-right: 0;
}

/* If boxed layout enabled + "order-first" classes enabled */
body.tt-boxed .tt-wrap .split-box-content.order-first .split-box-content-inner,
body.tt-boxed .tt-wrap .split-box-content.order-sm-first .split-box-content-inner,
body.tt-boxed .tt-wrap .split-box-content.order-md-first .split-box-content-inner,
body.tt-boxed .tt-wrap .split-box-content.order-lg-first .split-box-content-inner {
	padding-left: 0;
	padding-right: 10%;
}

/* If boxed layout enabled + "order-last" classes enabled */
body.tt-boxed .tt-wrap .split-box-content.order-last .split-box-content-inner,
body.tt-boxed .tt-wrap .split-box-content.order-sm-last .split-box-content-inner,
body.tt-boxed .tt-wrap .split-box-content.order-md-last .split-box-content-inner,
body.tt-boxed .tt-wrap .split-box-content.order-lg-last .split-box-content-inner {
	padding-left: 10%;
	padding-right: 0;
}

/* On small screens */
@media (max-width: 991px) {
	.split-box-content .split-box-content-inner {
		padding: 40px 15px 40px 15px;
	}
	body.tt-boxed .tt-wrap .split-box-content .split-box-content-inner {
		padding: 20px 0 0 0 !important;
	}
}


/* ---------------------------------------------------------------------- *
 *  Content carousel - CC
 *  Based on OWL Carousel: http://owlcarousel2.github.io/OwlCarousel2/
/* ---------------------------------------------------------------------- */

.owl-carousel {
}

.owl-carousel .owl-item {
	z-index: 9;
	overflow: hidden;
	/* image flickering fix */
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Page swiping fix on mobile devices */
.owl-carousel.owl-drag .owl-item {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}


/* Prev/Next nav buttons 
========================= */
.owl-nav {
	margin: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/* owl-nav buttons */
.owl-carousel .owl-nav button.owl-prev, 
.owl-carousel .owl-nav button.owl-next {
	position: absolute;
	top: 50%;
	width: 35px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	font-size: 20px;
	color: #FFF;
	overflow: hidden;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.owl-carousel .owl-nav button.owl-prev::before, 
.owl-carousel .owl-nav button.owl-next::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	opacity: .6;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-nav button.owl-prev:hover, 
.owl-carousel .owl-nav button.owl-next:hover {
	width: 45px;
}
.owl-carousel .owl-nav button.owl-prev:focus, 
.owl-carousel .owl-nav button.owl-next:focus {
	outline: none;
}
.owl-carousel .owl-nav button.owl-prev {
	left: 0;
	padding-right: 3px;
}
.owl-carousel .owl-nav button.owl-next {
	right: 0;
	padding-left: 3px;
}

.owl-carousel .owl-nav button.owl-prev::after,
.owl-carousel .owl-nav button.owl-next::after {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}
.owl-carousel .owl-nav button.owl-prev::after {
	content: "\f053";
}
.owl-carousel .owl-nav button.owl-next::after {
	content: "\f054";
}

.owl-carousel:hover .owl-nav button.owl-prev {
	left: 0;
}
.owl-carousel:hover .owl-nav button.owl-next {
	right: 0;
}

@media (max-width: 992px) {
	.owl-carousel .owl-nav button.owl-prev, 
	.owl-carousel .owl-nav button.owl-next {
		top: 50%;
		width: 30px;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
	}
	.owl-carousel .owl-nav button.owl-prev:hover,
	.owl-carousel .owl-nav button.owl-next:hover {
		width: 35px;
	}
}

/* Prev/Next buttons disabled (no loop) */
.owl-carousel .owl-nav button.owl-prev.disabled,
.owl-carousel .owl-nav button.owl-next.disabled {
	opacity: .3;
	cursor: default;
}
.owl-carousel .owl-nav button.owl-prev.disabled::before,
.owl-carousel .owl-nav button.owl-next.disabled::before {
	opacity: .6 !important;
}


/* Prev/Next nav buttons styles 
================================ */
/* Prev/Next buttons - show on hover */
@media (min-width: 991px) {
	.owl-carousel.nav-hover .owl-nav {
		opacity: 0;
		visibility: hidden;
	}
	.owl-carousel.nav-hover:hover .owl-nav {
		opacity: 1;
		visibility: visible;
	}
}

/* Prev/Next buttons light */
.owl-carousel.nav-light .owl-nav button.owl-prev, 
.owl-carousel.nav-light .owl-nav button.owl-next {
	color: #222;
}
.owl-carousel.nav-light .owl-nav button.owl-prev::before, 
.owl-carousel.nav-light .owl-nav button.owl-next::before {
	background-color: #FFF;
	opacity: 1;
}
.owl-carousel.nav-light .owl-nav button.owl-prev:hover, 
.owl-carousel.nav-light .owl-nav button.owl-next:hover {
	color: #e05536;
}

/* Prev/Next buttons dark */
.owl-carousel.nav-dark .owl-nav button.owl-prev, 
.owl-carousel.nav-dark .owl-nav button.owl-next {
	color: #FFF;
}
.owl-carousel.nav-dark .owl-nav button.owl-prev::before, 
.owl-carousel.nav-dark .owl-nav button.owl-next::before {
	background-color: #111;
	opacity: .4;
}

/* Prev/Next buttons rounded */
.owl-carousel.nav-rounded .owl-nav button.owl-prev, 
.owl-carousel.nav-rounded .owl-nav button.owl-next {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 100px;
}
.owl-carousel.nav-rounded .owl-nav button.owl-prev {
	left: 20px;
}
.owl-carousel.nav-rounded .owl-nav button.owl-next {
	right: 20px;
}
@media (max-width: 992px) {
	.owl-carousel.nav-rounded .owl-nav button.owl-prev, 
	.owl-carousel.nav-rounded .owl-nav button.owl-next {
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 16px;
	}
}

/* Prev/Next buttons minimal */
.owl-carousel.nav-minimal .owl-nav button.owl-prev, 
.owl-carousel.nav-minimal .owl-nav button.owl-next {
	width: 70px;
	height: 70px;
	min-height: 60px;
	font-size: 26px;
	color: #222;
}
.owl-carousel.nav-minimal.nav-light .owl-nav button.owl-prev, 
.owl-carousel.nav-minimal.nav-light .owl-nav button.owl-next {
	color: #FFF;
}

.owl-carousel.nav-minimal .owl-nav button.owl-prev:hover, 
.owl-carousel.nav-minimal .owl-nav button.owl-next:hover {
	color: #e05536;
}

.owl-carousel.nav-minimal .owl-nav button.owl-prev::before, 
.owl-carousel.nav-minimal .owl-nav button.owl-next::before {
	background-color: transparent !important;
}


/* Prev/Next nav buttons positions 
=================================== */
/* Prev/Next buttons position outside (left/right) */
.owl-carousel.nav-outside .owl-nav button.owl-prev, 
.owl-carousel.nav-outside .owl-nav button.owl-next {
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.owl-carousel.nav-outside .owl-nav button.owl-prev,
.owl-carousel.nav-outside:hover .owl-nav button.owl-prev {
	left: -70px;
}
.owl-carousel.nav-outside .owl-nav button.owl-next,
.owl-carousel.nav-outside:hover .owl-nav button.owl-next {
	right: -70px;
}

@media (max-width: 1540px) {
	.owl-carousel.nav-outside .owl-nav button.owl-prev, 
	.owl-carousel.nav-outside .owl-nav button.owl-next {
		width: 32px;
		height: 32px;
		line-height: 32px;
		font-size: 16px;
	}
	.owl-carousel.nav-outside .owl-nav button.owl-prev,
	.owl-carousel.nav-outside:hover .owl-nav button.owl-prev {
		left: 15px;
	}
	.owl-carousel.nav-outside .owl-nav button.owl-next,
	.owl-carousel.nav-outside:hover .owl-nav button.owl-next {
		right: 15px;
	}
}

/* Prev/Next nav buttons position outside top */
.owl-carousel.nav-outside-top .owl-nav {
	position: absolute;
	top: -50px;
	right: 30px;
	width: 60px;
}
@media (max-width: 992px) {
	.owl-carousel.nav-outside-top .owl-nav {
		right: 15px;
	}
}
.tt-wrap .owl-carousel.nav-outside-top .owl-nav {
	right: 0;
}

.owl-carousel.nav-outside-top .owl-nav button.owl-prev, 
.owl-carousel.nav-outside-top .owl-nav button.owl-next {
	width: 28px;
	height: 28px;
	line-height: 28px;
	font-size: 17px;
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.owl-carousel.nav-outside-top .owl-nav button.owl-prev:hover::before, 
.owl-carousel.nav-outside-top .owl-nav button.owl-next:hover::before {
	opacity: 1;
}

.owl-carousel.nav-outside-top:hover .owl-nav button.owl-prev {
	left: 0;
}
.owl-carousel.nav-outside-top:hover .owl-nav button.owl-next {
	right: 0;
}

/* Prev/Next nav buttons position bottom right */
.owl-carousel.nav-bottom-right .owl-nav {
	position: absolute;
	top: auto;
	bottom: 85px;
	right: 3%;
	width: 92px;
}

.owl-carousel.nav-bottom-right .owl-nav button.owl-prev, 
.owl-carousel.nav-bottom-right .owl-nav button.owl-next {
	width: 44px;
	height: 44px;
	line-height: 44px;
	font-size: 21px;
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.owl-carousel.nav-bottom-right .owl-nav button.owl-prev::before, 
.owl-carousel.nav-bottom-right .owl-nav button.owl-next::before {
	opacity: .6;
}
.owl-carousel.nav-bottom-right .owl-nav button.owl-prev:hover::before, 
.owl-carousel.nav-bottom-right .owl-nav button.owl-next:hover::before {
	opacity: 1;
}

.owl-carousel.nav-bottom-right:hover .owl-nav button.owl-prev,
.owl-carousel.nav-bottom-right:hover .owl-nav button.owl-prev {
	left: 0;
}
.owl-carousel.nav-bottom-right:hover .owl-nav button.owl-next,
.owl-carousel.nav-bottom-right:hover .owl-nav button.owl-next {
	right: 0;
}

.owl-carousel.nav-bottom-right.nav-light .owl-nav button.owl-prev::before, 
.owl-carousel.nav-bottom-right.nav-light .owl-nav button.owl-next::before {
	opacity: .85;
}
.owl-carousel.nav-bottom-right.nav-light .owl-nav button.owl-prev:hover::before, 
.owl-carousel.nav-bottom-right.nav-light .owl-nav button.owl-next:hover::before {
	opacity: 1;
}

.owl-carousel.nav-bottom-right.nav-light .owl-nav button.owl-prev.disabled::before,
.owl-carousel.nav-bottom-right.nav-light .owl-nav button.owl-next.disabled::before {
	opacity: 1 !important;
}

@media (min-width: 768px) {
	.owl-carousel.dots-right.nav-bottom-right .owl-nav {
		bottom: 110px;
	}
	.owl-carousel.dots-outside.nav-bottom-right .owl-nav {
		bottom: 85px;
	}
}
@media (max-width: 992px) {
	.owl-carousel.nav-bottom-right .owl-nav {
		bottom: 60px;
	}
}
@media (max-width: 768px) {
	.owl-carousel.nav-bottom-right .owl-nav {
		right: 5%;
		width: 84px;
	}
	.owl-carousel.nav-bottom-right .owl-nav button.owl-prev, 
	.owl-carousel.nav-bottom-right .owl-nav button.owl-next {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
	}
	.owl-carousel.dots-outside.nav-bottom-right .owl-nav {
		bottom: 55px;
	}
}


/* Dots 
======== */
.owl-dots, .owl-nav.disabled + .owl-dots {
	position: absolute;
	bottom: 20px;
	margin-top: 0px;
	left: 50%;
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.owl-dots button.owl-dot {
	display: inline-block;
}
.owl-dots button.owl-dot:focus {
	outline: none;
}

.owl-dots button.owl-dot span {
	display: inline-block;
	width: 20px;
	height: 4px;
	margin: 0px 3px;
	background-color: #a9a9a9;
	border-radius: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.owl-dots button.owl-dot.active span, 
.owl-dots button.owl-dot:hover span {
	background-color: #e05536;
}
.owl-dots button.owl-dot.active span {
	width: 28px !important;
}

@media (max-width: 768px) {
	.owl-dots, .owl-nav.disabled + .owl-dots {
		bottom: 10px;
	}
}

/* dots rounded */
.dots-rounded .owl-dots button.owl-dot span {
	border-radius: 10px;
}

/* disable dots on small screens */
@media (max-width: 768px) {
	.owl-dots, .owl-nav.disabled + .owl-dots {
		display: none !important;
	}
	.dots-outside {
		margin-bottom: 0 !important;
	}
}


/* Dots positions
=================== */
/* Dots position outside */
.dots-outside {
	margin-bottom: 55px;
}
.dots-outside .owl-dots, .dots-outside .owl-nav.disabled + .owl-dots {
	bottom: -40px;
}

/* Dots position left */
.dots-left .owl-dots, .dots-left .owl-nav.disabled + .owl-dots {
	left: 3%;
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

/* Dots position right */
.dots-right .owl-dots, .dots-right .owl-nav.disabled + .owl-dots {
	left: inherit;
	right: 3%;
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

/* Dots position center right */
.dots-center-right .owl-dots {
	position: absolute;
	top: 50%;
	bottom: auto !important;
	left: auto !important;
	right: 15px;
	text-align: right;
	-moz-transform: translateY(-50%) !important;
	-ms-transform: translateY(-50%) !important;
	-webkit-transform: translateY(-50%) !important;
	transform: translateY(-50%) !important;
}
.dots-center-right .owl-dots button.owl-dot {
	display: block;
	line-height: 0;
}
.dots-center-right .owl-dots button.owl-dot span {
	margin: 5px 0;
}


/* CC item 
=========== */
.owl-carousel .cc-item {
	position: relative;
	display: block;
	overflow: hidden;
}


/* CC item image
================= */
.owl-carousel .owl-item .cc-image {
	position: relative;
	display: block;
	min-height: 300px;
	z-index: 1;
}
.owl-carousel .owl-item .cc-image.bg-image {
}

.owl-carousel .owl-item img {
	display: block;
	width: auto;
	max-width: 100%;
	margin: 0 auto;
}


/* CC link 
=========== */
.cc-link {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
}
.cc-link-icon {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 34px;
	height: 34px;
	line-height: 34px;
	background-color: #FFF;
	opacity: 0;
	visibility: hidden;
	text-align: center;
	font-size: 15px;
	color: #000;
	z-index: 3;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.cc-link:hover .cc-link-icon {
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}


/* CC item hover 
================= */
/* CC item hover zoom */
.owl-carousel.cc-hover-zoom .cc-item {
	overflow: hidden;
}
.owl-carousel.cc-hover-zoom .cc-item .cc-image,
.owl-carousel.cc-hover-zoom .cc-item img {
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-ms-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}
.owl-carousel.cc-hover-zoom .cc-item:hover .cc-image,
.owl-carousel.cc-hover-zoom .cc-item:hover img {
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}


/* CC item size (for background images only!)
============================================== */
.owl-carousel.owl-size-1 .cc-item .cc-image.bg-image { padding-bottom: 10% }
.owl-carousel.owl-size-2 .cc-item .cc-image.bg-image { padding-bottom: 20% }
.owl-carousel.owl-size-3 .cc-item .cc-image.bg-image { padding-bottom: 30% }
.owl-carousel.owl-size-4 .cc-item .cc-image.bg-image { padding-bottom: 40% }
.owl-carousel.owl-size-5 .cc-item .cc-image.bg-image { padding-bottom: 50% }
.owl-carousel.owl-size-6 .cc-item .cc-image.bg-image { padding-bottom: 60% }
.owl-carousel.owl-size-7 .cc-item .cc-image.bg-image { padding-bottom: 70% }
.owl-carousel.owl-size-8 .cc-item .cc-image.bg-image { padding-bottom: 80% }
.owl-carousel.owl-size-9 .cc-item .cc-image.bg-image { padding-bottom: 90% }
.owl-carousel.owl-size-10 .cc-item .cc-image.bg-image { padding-bottom: 100% }
.owl-carousel.owl-size-11 .cc-item .cc-image.bg-image { padding-bottom: 110% }
.owl-carousel.owl-size-12 .cc-item .cc-image.bg-image { padding-bottom: 120% }
.owl-carousel.owl-size-13 .cc-item .cc-image.bg-image { padding-bottom: 130% }
.owl-carousel.owl-size-14 .cc-item .cc-image.bg-image { padding-bottom: 140% }
.owl-carousel.owl-size-15 .cc-item .cc-image.bg-image { padding-bottom: 150% }
.owl-carousel.owl-size-16 .cc-item .cc-image.bg-image { padding-bottom: 160% }
.owl-carousel.owl-size-17 .cc-item .cc-image.bg-image { padding-bottom: 170% }
.owl-carousel.owl-size-18 .cc-item .cc-image.bg-image { padding-bottom: 180% }
.owl-carousel.owl-size-19 .cc-item .cc-image.bg-image { padding-bottom: 190% }
.owl-carousel.owl-size-20 .cc-item .cc-image.bg-image { padding-bottom: 200% }

@media (max-width: 1024px) {
	.owl-carousel.owl-size-8 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-9 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-10 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-11 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-12 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-13 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-14 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-15 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-16 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-17 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-18 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-19 .cc-item .cc-image.bg-image,
	.owl-carousel.owl-size-20 .cc-item .cc-image.bg-image { 
		padding-bottom: 70% 
	}
}

/* full height (for desktop only!) */
@media (min-width: 992px) {
	.owl-carousel.owl-size-full .cc-item .cc-image.bg-image { 
		height: calc(100vh - 80px); /* minus same as header height */
		min-height: calc(100vh - 80px); /* minus same as header height */
	}
	body.header-transparent-on .owl-carousel.owl-size-full .cc-item .cc-image.bg-image { 
		height: 100vh;
		min-height: 100vh;
	}
}

/* full height (for small screen only!) */
@media (max-width: 991px) {
	.owl-carousel.owl-size-full-m .cc-item .cc-image.bg-image { 
		height: calc(100vh - 64px); /* minus same as mobile header height */
		min-height: calc(100vh - 64px); /* minus same as mobile header height */
	}
}


/* owl elements 
================ */
/* owl video */
.owl-carousel .owl-video-wrapper {
	z-index: 2;
}

/* owl video play icon */
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background-color: rgba(0, 0, 0, 0.7);
	background-position: center;
	cursor: pointer;
	z-index: 1;
	border-radius: 80px;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/* cursor grab icon */
.owl-carousel.cursor-grab .owl-stage {
	cursor: -webkit-grab;
	cursor: grab;
}
.owl-carousel.cursor-grab .owl-stage:active {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}


/* if owl lazy loading enabled 
=============================== */
.owl-lazy-wrap {
	position: relative;
}
.owl-lazy {
}
.owl-lazy.bg-image {
}
.owl-lazy-loader {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}
.owl-lazy-loader.owl-lazy-loading {
	background-image: url(../img/loader.gif);
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.owl-carousel .owl-item .owl-lazy:not([src]), 
.owl-carousel .owl-item .owl-lazy[src^=""] {
	max-height: inherit;
}


/* ------------------------------------------------------------- *
 * Swiper - http://idangero.us/swiper/
 * Note: class "swiper-default" is required!
/* ------------------------------------------------------------- */

.swiper-default.swiper-container {
	width: 100%;
	min-height: 1px;
}


/* Swiper slide
================ */
.swiper-default .swiper-slide {
	text-align: center;
}

/* If Swiper slide = background image */
@media (max-width: 991px) {
	.swiper-default .swiper-slide.bg-image {
		padding-bottom: 50%;
	}
}
@media (max-width: 768px) {
	.swiper-default .swiper-slide.bg-image {
		padding-bottom: 70%;
	}
}


/* Swiper preloader 
===================== */
.swiper-default .swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	-webkit-animation: none;
	animation: none;
}
.swiper-default .swiper-lazy-preloader:after {
	display: block;
	content: '';
	width: 100%;
	height: 100%;
	background-image: url(../img/loader.gif);
	background-position: 50%;
	background-size: auto;
	background-repeat: no-repeat;
}


/* Swiper navigation 
===================== */
.swiper-default .swiper-button-next, 
.swiper-default .swiper-button-prev {
	top: 50%;
	width: 35px;
	height: 54px;
	line-height: 54px;
	margin-top: -27px; /* half a button height */
	background-image: none;
	background-color: transparent;
	text-align: center;
	font-size: 18px;
	color: #FFF;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.swiper-default .swiper-button-next::before, 
.swiper-default .swiper-button-prev::before { /* navigation button background */
	position: absolute;
	display: block;
	content: "";
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	opacity: .6;
}
.swiper-default .swiper-button-next:hover,
.swiper-default .swiper-button-prev:hover {
	width: 45px; 
}
.swiper-default .swiper-button-next:focus, 
.swiper-default .swiper-button-prev:focus {
	outline: none;
}

.swiper-default .swiper-button-next.swiper-button-disabled,
.swiper-default .swiper-button-prev.swiper-button-disabled {
	opacity: .2 !important;
}

/* nav next button */
.swiper-default .swiper-button-next, 
.swiper-default .swiper-container-rtl .swiper-button-prev {
	right: 0;
}
.swiper-default .swiper-button-next::after, 
.swiper-default .swiper-container-rtl .swiper-button-prev::after {
	font-family: "Font Awesome 5 Free";
	content: "\f054";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

/* nav prev button */
.swiper-default .swiper-button-prev, 
.swiper-default .swiper-container-rtl .swiper-button-next {
	left: 0;
}
.swiper-default .swiper-button-prev::after, 
.swiper-default .swiper-container-rtl .swiper-button-next::after {
	font-family: "Font Awesome 5 Free";
	content: "\f053";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}


/* Swiper navigation on swiper hover 
===================================== */
.swiper-default.swiper-nav-hover .swiper-button-next, 
.swiper-default.swiper-nav-hover .swiper-container-rtl .swiper-button-prev {
	opacity: 0;
	visibility: hidden;
	right: -60px;
}
.swiper-default.swiper-nav-hover.swiper-container:hover .swiper-button-next, 
.swiper-default.swiper-nav-hover.swiper-container:hover .swiper-container-rtl .swiper-button-prev {
	opacity: 1;
	visibility: visible;
	right: 0;
}

.swiper-default.swiper-nav-hover .swiper-button-prev, 
.swiper-default.swiper-nav-hover .swiper-container-rtl .swiper-button-next {
	opacity: 0;
	visibility: hidden;
	left: -60px;
}
.swiper-default.swiper-nav-hover.swiper-container:hover .swiper-button-prev, 
.swiper-default.swiper-nav-hover.swiper-container:hover .swiper-container-rtl .swiper-button-next {
	opacity: 1;
	visibility: visible;
	left: 0;
}


/* Swiper pagination bullets 
============================= */
.swiper-default .swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 100%;
	background: #8e8e8e;
	opacity: .4;
}
.swiper-default .swiper-pagination-bullet-active {
	background: #e05536;
	opacity: 1;
}

.swiper-default.swiper-container-horizontal > .swiper-pagination-bullets {
	left: 50%;
	bottom: 15px;
	width: auto;
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

/* pagination bullets dynamic */
.swiper-default.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
	bottom: 20px;
}

/* pagination bullets if vertical container is enabled */
.swiper-default.swiper-container-vertical > .swiper-pagination-bullets,
.swiper-default.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
	right: 20px;
}


/* Swiper pagination fraction 
============================== */
.swiper-default .swiper-pagination-fraction {
	left: 50%;
	bottom: 15px;
	width: auto;
	background-color: rgba(12, 12, 12, 0.3);
	padding: 6px 8px;
	color: #FFF;
	font-size: 14px;
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}


/* Swiper pagination progressbar 
================================= */
.swiper-default .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
	background: #e05536;
}


/* Swiper pagination positions 
=============================== */
/* position left */
.swiper-default.swiper-container-horizontal > .swiper-pagination-bullets.sw-pag-left {
	left: 17px;
	bottom: 10px;
	text-align: left;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.swiper-default.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic.sw-pag-left {
	left: 15px;
	bottom: 20px;
	text-align: left;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.swiper-default .swiper-pagination-fraction.sw-pag-left {
	left: 17px;
	bottom: 15px;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.swiper-default.swiper-container-vertical > .swiper-pagination-bullets.sw-pag-left,
.swiper-default.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic.sw-pag-left {
	left: 20px;
	right: auto;
}

/* position right */
.swiper-default.swiper-container-horizontal > .swiper-pagination-bullets.sw-pag-right {
	left: auto;
	right: 17px;
	bottom: 10px;
	text-align: right;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.swiper-default.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic.sw-pag-right {
	left: auto;
	right: 15px;
	bottom: 20px;
	text-align: right;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.swiper-default .swiper-pagination-fraction.sw-pag-right {
	left: auto;
	right: 17px;
	bottom: 15px;
	-moz-transform: none;
	-ms-transform: none;
	-webkit-transform: none;
	transform: none;
}
.swiper-default.swiper-container-vertical > .swiper-pagination-progressbar.sw-pag-right {
	left: auto;
	right: 0;
}


/* Swiper styles 
================= */

/* swiper-minimal */
.swiper-default.swiper-minimal .swiper-button-next, 
.swiper-default.swiper-minimal .swiper-button-prev {
	width: 60px;
	font-size: 20px;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.7);
}
.swiper-default.swiper-minimal .swiper-button-next:hover, 
.swiper-default.swiper-minimal .swiper-button-prev:hover {
	width: 67px;
}
.swiper-default.swiper-minimal .swiper-button-next::before, 
.swiper-default.swiper-minimal .swiper-button-prev::before {
	background-color: transparent;
}

.swiper-default.swiper-minimal .swiper-pagination-fraction {
	background-color: transparent;
	font-size: 15px;
	font-weight: 500;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.7);
}

/* swiper-minimal-dark */
.swiper-default.swiper-minimal.swiper-minimal-dark .swiper-button-next, 
.swiper-default.swiper-minimal.swiper-minimal-dark .swiper-button-prev {
	color: #222;
	text-shadow: none;
}
.swiper-default.swiper-minimal.swiper-minimal-dark .swiper-pagination-fraction {
	color: #222;
	text-shadow: none;
}


/* ------------------------------------------------------------- *
 * Thumbnail list
/* ------------------------------------------------------------- */

.tt-thumb-list {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
}
.tt-thumb-list > li {
	float: left;
}

/* thumbnail list item */
.tt-thumb-list .tt-thumb-list-item {
	display: block;
	background-color: rgba(130, 130, 130, 0.1);
	padding-bottom: 100%;
	border: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.tt-thumb-list .tt-thumb-list-item:hover { 
	opacity: .8;
}

/* thumbnail list columns */
.tt-thumb-list.tl-col-2 > li { width: 50%; }
.tt-thumb-list.tl-col-3 > li { width: 33.33333333%; }
.tt-thumb-list.tl-col-4 > li { width: 25%; }
.tt-thumb-list.tl-col-5 > li { width: 20%; }
.tt-thumb-list.tl-col-6 > li { width: 16.66666666666667%; }

@media (max-width: 992px) {
	.tt-thumb-list.tl-col-5 > li,
	.tt-thumb-list.tl-col-6 > li { 
		width: 25%; 
	}
}
@media (max-width: 768px) {
	.tt-thumb-list.tl-col-3 > li,
	.tt-thumb-list.tl-col-4 > li,
	.tt-thumb-list.tl-col-5 > li,
	.tt-thumb-list.tl-col-6 > li { 
		width: 33.33333333%; 
	}
}
@media (max-width: 480px) {
	.tt-thumb-list.tl-col-4 > li,
	.tt-thumb-list.tl-col-5 > li,
	.tt-thumb-list.tl-col-6 > li { 
		width: 50%; 
	}
}

/* thumbnail list gutter (more space between items) */
.tt-thumb-list.tl-gutter-1 > li { padding: 1px; }
.tt-thumb-list.tl-gutter-2 > li { padding: 2px; }
.tt-thumb-list.tl-gutter-3 > li { padding: 3px; }
.tt-thumb-list.tl-gutter-4 > li {padding: 4px;}
.tt-thumb-list.tl-gutter-5 > li { padding: 5px; }


/* ------------------------------------------------------------- *
 * Team
/* ------------------------------------------------------------- */

.tt-team {
}


/* Team item 
============= */
.team-item {
	position: relative;
	margin: 0 5% 40px 5%;
	overflow: hidden;
}

/* team item image */
.team-item-image {
	position: relative;
	background-color: rgba(130, 130, 130, 0.1);
	padding-bottom: 110%;
	z-index: 1;
}
img.team-item-image {
	padding-bottom: 0 !important;
}


/* Team item caption 
===================== */
.team-item-caption {
	position: relative;
	padding: 15px 0 0 0;
}

/* team item title */
.team-item-title {
	margin: 0;
	font-size: 19px;
	font-weight: 600;
}
.team-item-title span {
	display: inline-block;
	color: #222;
}

/* team item subtitle */
.team-item-subtitle {
	margin: 3px 0 0 0;
	font-size: 15px;
	font-weight: 400;
}
.team-item-subtitle span {
	display: inline-block;
	color: #888;
}

/* team item social */
.team-item-social {
	margin-top: 15px;
}

.team-item-social .team-item-social-inner > ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.team-item-social .team-item-social-inner > ul > li {
	float: left;
}
.team-item-social .team-item-social-button {
	position: relative;
	display: block;
	width: 35px;
	height: 35px;
	line-height: 35px;
	background-color: #FFF;
	text-align: center;
	font-size: 14px;
	color: #222;
	border-radius: 100%;
	overflow: hidden;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.team-item-social .team-item-social-button::before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.team-item-social .team-item-social-button:hover::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.team-item-social .team-item-social-button:hover {
	color: #FFF;
	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

@media (min-width: 992px) {
	.team-item-social {
		position: absolute;
		left: 0;
		top: 0;
		margin-top: 0;
		text-align: center;
		z-index: 9;
		overflow: hidden;
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	.team-item-social .team-item-social-inner {
		margin: 0;
		padding: 15px;
		opacity: 0;
		visibility: hidden;
		-moz-transform: translateX(-10px);
		-ms-transform: translateX(-10px);
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);

		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.team-item:hover .team-item-social .team-item-social-inner {
		opacity: 1;
		visibility: visible;
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	.team-item-social .team-item-social-inner > ul > li {
		float: none;
		margin: 5px;
	}
}


/* Team item styles 
==================== */
/* team item alternative style */
@media (min-width: 992px) {
	.tt-team.team-item-alter .team-item-image::before {
		position: absolute;
		display: block;
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 2;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.tt-team.team-item-alter .team-item:hover .team-item-image::before {
		opacity: 1;
		visibility: visible;
	}

	.tt-team.team-item-alter .team-item-caption {
		position: absolute;
		top: 50%;
		width: 100%;
		padding: 0 5%;
		text-align: center;
		z-index: 2;
		opacity: 0;
		visibility: hidden;
		-moz-transform: translateY(-45%);
		-ms-transform: translateY(-45%);
		-webkit-transform: translateY(-45%);
		transform: translateY(-45%);

		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.tt-team.team-item-alter .team-item:hover .team-item-caption {
		opacity: 1;
		visibility: visible;
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);

		-webkit-transition-delay: 0.2s;
		-moz-transition-delay: 0.2s;
		transition-delay: 0.2s;
	}

	.tt-team.team-item-alter .team-item-title span {
		color: #FFF;
	}
	.tt-team.team-item-alter .team-item-subtitle span {
		color: #C1C1C1;
	}

	.tt-team.team-item-alter .team-item-social {
		position: relative;
		margin-top: 15px;
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	.tt-team.team-item-alter .team-item-social .team-item-social-inner {
		background-color: transparent;
		margin: 0;
		padding: 0;
		opacity: 1;
		visibility: visible;

		-moz-transform: none;
		-ms-transform: none;
		-webkit-transform: none;
		transform: none;

		-webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		-ms-transition: none;
		transition: none;
	}
	.tt-team.team-item-alter .team-item-social-inner > ul > li {
		display: inline-block;
		margin: 0;
	}
	.tt-team.team-item-alter .team-item-social-button {
		width: 30px;
		background-color: transparent;
		font-size: 16px;
		color: #FFF;
	}
	.tt-team.team-item-alter .team-item-social-button::before {
		display: none;
	}
	.tt-team.team-item-alter .team-item-social-button:hover {
		color: #e05536;
	}
}

/* team item caption boxed style */
.tt-team.team-caption-boxed .team-item-title {
	font-size: 18px;
}
.tt-team.team-caption-boxed .team-item-title span {
	display: inline-block;
	color: #fff;
	background-color: #111;
	padding: 0 3px;
}

.tt-team.team-caption-boxed .team-item-subtitle {
	margin: 10px 0 0 0;
	font-size: 13px;
	font-weight: 400;
}
.tt-team.team-caption-boxed .team-item-subtitle span {
	background-color: #e05536;
	padding: 0 3px;
	margin: 0 2px 2px 0;
	color: #FFF;
}


/* Team carousel 
================= */
.tt-team.team-carousel {
	margin-left: -1px;
}

.tt-team.team-carousel .team-item,
.owl-carousel .team-item {
	margin: 0;
}

@media (max-width: 1200px) {
	.tt-team.team-carousel .owl-carousel[class*="owl-size-"] .cc-item .cc-image.bg-image { 
		padding-bottom: 150% 
	}
}
@media (max-width: 992px) {
	.tt-team.team-carousel .owl-carousel[class*="owl-size-"] .cc-item .cc-image.bg-image { 
		padding-bottom: 120% 
	}
}
@media (max-width: 768px) {
	.tt-team.team-carousel .owl-carousel[class*="owl-size-"] .cc-item .cc-image.bg-image { 
		padding-bottom: 100% 
	}
}


/* ------------------------------------------------------------- *
 *  Pagination (tt-pagination)
/* ------------------------------------------------------------- */

.tt-pagination-wrap {
	padding: 30px 2.5%;
}

.tt-pagination {
	margin: 0;
	padding: 0;
	list-style: none;
}

.tt-pagination > li {
	display: inline-block;
}
.tt-pagination > li > a, 
.tt-pagination > li > span {
	position: relative;
	display: block;
	float: none;
	padding: 5px 13px;
	margin: 5px 0;
	font-size: 16px;
	font-weight: 400;
	color: #000;
	text-decoration: none;
	/* background-color: rgba(156, 156, 156, 0.15); */
	/* border: 1px solid rgba(232, 232, 232, 0.15); */
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
@media (max-width: 992px) {
	.tt-pagination > li > a, 
	.tt-pagination > li > span {
		padding: 0px 8px;
	}
}

.tt-pagination > li > a::before {
	position: absolute;
	display: block;
	content: "";
	height: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(156, 156, 156, 0.2);
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);
}
.tt-pagination > li > a:hover::before {
	height: 100%
}

.tt-pagination > li > span {
	background-color: transparent;
	border: none;
}
.tt-pagination > li:first-child > a {
	margin-left: 0;
}
.tt-pagination > li:last-child > a {
}

.tt-pagination > li.active > a, 
.tt-pagination > li.active > a:focus, 
.tt-pagination > li.active > a:hover {
	color: #FFF;
	cursor: default;
	background-color: #e05536;
	border-color: #e05536;
}

/* if boxed layout enabled */
body.tt-boxed .tt-wrap .tt-pagination-wrap {
	padding-left: 0;
	padding-right: 0;
}

/* tt-pagination info */
.tt-pagination-info {
	margin-top: 15px;
	font-size: 14px;
	color: #999;
}
@media (max-width: 992px) {
	.tt-pagination-info {
		margin-top: 20px;
		text-align: center;
	}
}

.tt-pagination-info span {
	display: block;
}


/* tt-pagination aligns 
======================== */
/* align center */
.tt-pagination-wrap.tt-pagin-center {
	text-align: center;
}

/* align right */
.tt-pagination-wrap.tt-pagin-right {
	text-align: right;
}


/* tt-pagination styles 
======================== */
/* rounded */
.tt-pagination-wrap.tt-pagin-rounded .tt-pagination > li > a, 
.tt-pagination-wrap.tt-pagin-rounded .tt-pagination > li > span {
	border-radius: 50px;
}


/* ------------------------------------------------------------- *
 * tt-list
/* ------------------------------------------------------------- */

.tt-list {
}

.tt-list > ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.tt-list > ul > li {
}

/* tt-list item */
.tt-list-item {
}
.tt-list-item-title {
	display: inline-block;
	font-weight: bold;
	font-weight: 700;
	color: #222;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.tt-list-item-title:hover {
	color: #e05536;
}


/* tt-list styles
================== */
/* tt-list boxed style */
.tt-list.tt-list-boxed > ul > li {
	margin-bottom: 10px;
}
.tt-list.tt-list-boxed .tt-list-item-title {
	display: inline;
	background-color: #222;
	padding: 0 10px;
	color: #FFF;
	line-height: 1.4;
}
.tt-list.tt-list-boxed .tt-list-item-title:hover {
	background-color: #e05536;
	color: #FFF;
}

/* tt-list hover move (no effect on small devisec!) */
@media (min-width: 992px) {
	.tt-list.tt-list-hover-move .tt-list-item-title:hover {
		margin-left: 5px;
	}
}


/* tt-list sizes 
================= */
/* tt-list lg */
.tt-list.tt-list-lg .tt-list-item-title {
	font-size: 24px;
	font-weight: 700;
}

/* tt-list xlg */
.tt-list.tt-list-xlg .tt-list-item-title {
	font-size: 30px;
	font-weight: 700;
}
@media (max-width: 768px) {
	.tt-list.tt-list-xlg .tt-list-item-title {
		font-size: 24px;
	}
}

@media (min-width: 992px) {
	.tt-list.tt-list-xlg.tt-list-hover-move .tt-list-item-title:hover {
		margin-left: 8px;
	}
}

/* tt-list xxlg */
.tt-list.tt-list-xxlg .tt-list-item-title {
	font-size: 38px;
	font-weight: 700;
}
@media (max-width: 768px) {
	.tt-list.tt-list-xxlg .tt-list-item-title {
		font-size: 24px;
	}
}

@media (min-width: 992px) {
	.tt-list.tt-list-xxlg.tt-list-hover-move .tt-list-item-title:hover {
		margin-left: 10px;
	}
}

/* tt-list xxxlg */
.tt-list.tt-list-xxxlg .tt-list-item-title {
	font-size: 46px;
	font-weight: 700;
}
@media (max-width: 1200px) {
	.tt-list.tt-list-xxxlg .tt-list-item-title {
		font-size: 36px;
	}
}
@media (max-width: 768px) {
	.tt-list.tt-list-xxxlg .tt-list-item-title {
		font-size: 24px;
	}
}

@media (min-width: 992px) {
	.tt-list.tt-list-xxxlg.tt-list-hover-move .tt-list-item-title:hover {
		margin-left: 12px;
	}
}


/* ------------------------------------------------------------- *
 * Clients list
/* ------------------------------------------------------------- */

.clients-list {
	position: relative;
}

.clients-list > ul {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 2;
}
.clients-list > ul::after {
	display: block;
	content: "";
	clear: both;
}
.clients-list > ul > li {
	width: 25%;
	float: left;
}
@media (max-width: 992px) {
	.clients-list > ul > li {
		width: 33.33333333333333%;
	}
}
@media (max-width: 768px) {
	.clients-list > ul > li {
		width: 50%;
	}
}

/* clients list item */
.clients-list .clients-list-item {
	display: block;
	padding: 15% 5%;
	text-align: center;
}


/* clients list styles
======================= */

/* clients list bordered style */
.clients-list.clients-list-bordered {
	overflow: hidden;
}
.clients-list.clients-list-bordered > ul {
	margin: 0 0 -2px -2px;
}
.clients-list.clients-list-bordered .clients-list-item {
	border-left: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-color: rgba(158, 158, 158, 0.23);
}

/* clients list boxed style */
.clients-list.clients-list-boxed > ul > li {
	padding: 1.2%;
}
.clients-list.clients-list-boxed .clients-list-item {
	background-color: #FFF;
	padding: 10%;
	border: 1px solid rgba(158, 158, 158, 0.23);
}

/* clients list hover */
@media (min-width: 992px) {
	.clients-list.clients-list-hover:not(.clients-list-boxed) .clients-list-item img {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	.clients-list.clients-list-hover:not(.clients-list-boxed) .clients-list-item img:hover {
		-webkit-transform: scale(1.07);
		-moz-transform: scale(1.07);
		-o-transform: scale(1.07);
		-ms-transform: scale(1.07);
		transform: scale(1.07);
	}

	.clients-list.clients-list-boxed .clients-list-item {
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	.clients-list.clients-list-boxed .clients-list-item:hover {
		-moz-transform: translate3d(0, -7px, 0);
		-ms-transform: translate3d(0, -7px, 0);
		-webkit-transform: translate3d(0, -7px, 0);
		transform: translate3d(0, -7px, 0);
		border-color: rgba(158, 158, 158, 0.3);
		-webkit-box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.21);
		-moz-box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.21);
		box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.21);
	}
}


/* ------------------------------------------------------------- *
 * Clients carousel
/* ------------------------------------------------------------- */

.clients-carousel {
}

.clients-carousel .owl-carousel.cc-hover-zoom .cc-item {
	padding: 15px 0;
}


/* ------------------------------------------------------------- *
 * Forms
/* ------------------------------------------------------------- */

.form-control {
	display: block;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.09);
	padding: 5px 15px;
	font-size: 18px;
	color: #666;
	border: 2px solid rgba(148, 148, 148, 0.2);
	border-radius: 0 !important;
}
.form-control:focus {
	color: #333;
	background-color: #FFF;
	border-color: #e05536;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.form-control + .form-control {
	margin-top: 8px;
}

.form-control:disabled, .form-control[readonly] {
	background-color: rgba(177, 177, 177, 0.26);
	opacity: 1;
}

.form-text {
	margin-top: 5px;
}

label {
	display: inline-block;
	margin-bottom: 8px;
}

.form-group {
	margin-bottom: 18px;
}
.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 5px 12px;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: #666;
	text-align: center;
	white-space: nowrap;
	background-color: rgba(181, 181, 181, 0.25);
	border: 1px solid rgba(148, 148, 148, 0.4);
	border-radius: 0;
}


/* Custom forms 
================= */

/* custom select */
.custom-select {
	display: inline-block;
	width: 100%;
	height: 46px;
	background-color: rgba(132, 132, 132, 0.12);
	padding: 5px 20px 5px 15px;
	font-size: 18px;
	color: #666;
	border: 1px solid transparent;
	border-radius: 0;
}
.custom-select:focus {
	color: #333;
	background-color: rgba(132, 132, 132, 0.1);
	border-color: rgba(148, 148, 148, 0.7);
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* custom file browser */
.custom-file,
.custom-file-input {
	height: 46px;
	cursor: pointer;
}
.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 46px;
	padding: 4px 20px;
	line-height: 2;
	color: #666;
	background-color: rgba(132, 132, 132, 0.12);
	border: 1px solid transparent;
	border-radius: inherit;
}
.custom-file-input:focus ~ .custom-file-label {
	border-color: rgba(132, 132, 132, 0.1);
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: 44px;
	line-height: 1.8;
	padding: 5px 15px;
	color: #FFF;
	content: "Browse";
	background-color: #e05536;
	border-left: 1px solid transparent;
	border-radius: 0;
}
.custom-file-input:focus~.custom-file-label::after {
	border-color: transparent;
}

/* custom checkbox/radio */
.custom-control-label {
	cursor: pointer;
}
.custom-control-label::before {
	background-color: #e1e1e1;
	border-color: #d2d2d2;
}

.custom-control-input:active ~ .custom-control-label::before {
	color: #333;
	background-color: #d6d6d6;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #e05536;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #e05536;
	border-color: #e05536;
}

.custom-control-input:focus ~ .custom-control-label::before {
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-label {
	cursor: not-allowed;
}
.custom-control-input:disabled ~ .custom-control-label::before {
	background-color: #e1e1e1;
}

/* range slider */
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  color: transparent;
  cursor: pointer;
  background-color: rgba(181, 181, 181, 0.35);
  border-color: transparent;
  border-radius: 20px;
}

.custom-range::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  background-color: #e05536;
  border: none;
  border-radius: 20px;
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #e05536;
  opacity: .3;
}
.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* form with button inside */
.form-btn-inside {
	position: relative;
}
.form-btn-inside .form-control {
	padding-right: 45px;
}
.form-btn-inside button {
	position: absolute;
	top: 50%;
	right: 0;
	width: auto;
	height: 100%;
	line-height: 40px;
	background-color: transparent;
	padding: 0 15px 0 15px;
	font-size: 18px;
	color: #333;
	border: none;
	cursor: pointer;
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.form-btn-inside button:hover {
	opacity: .8;
}
.form-btn-inside button:focus {
	outline: none;
}


/* Forms validation 
==================== */

/* valid */
.custom-select.is-valid, 
.form-control.is-valid, 
.was-validated .custom-select:valid, 
.was-validated .form-control:valid {
	border-color: #28a745;
}
.valid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #28a745;
}
.form-check-input.is-valid ~ .form-check-label, 
.was-validated .form-check-input:valid ~ .form-check-label {
	color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label, 
.was-validated .custom-control-input:valid ~ .custom-control-label {
	color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before, 
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
	background-color: #28a745;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before, 
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
	background-color: #28a745;
}

.custom-select.is-valid:focus, 
.form-control.is-valid:focus, 
.was-validated .custom-select:valid:focus, 
.was-validated .form-control:valid:focus {
	border-color: #28a745;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* valid tooltip */
.valid-tooltip {
	color: #FFF;
	background-color: rgba(40,167,69,.8);
	border-radius: 0;
}

/* invalid */
.custom-select.is-invalid, 
.form-control.is-invalid, 
.was-validated .custom-select:invalid, 
.was-validated .form-control:invalid {
	border-color: #dc3545;
}
.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: #dc3545;
}
.form-check-input.is-invalid ~ .form-check-label, 
.was-validated .form-check-input:invalid ~ .form-check-label {
	color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label, 
.was-validated .custom-control-input:invalid ~ .custom-control-label {
	color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label, 
.was-validated .custom-file-input:invalid ~ .custom-file-label {
	border-color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before, 
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
	background-color: #efa2a9;
}

.custom-select.is-invalid:focus, 
.form-control.is-invalid:focus, 
.was-validated .custom-select:invalid:focus, 
.was-validated .form-control:invalid:focus {
	border-color: #dc3545;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.custom-file-input.is-invalid:focus~.custom-file-label, 
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
   -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* invalid tooltip */
.invalid-tooltip {
	color: #FFF;
	background-color: rgba(220,53,69,.8);
	border-radius: 0;
}


/* Form sizes 
============== */

/* default height */
.form-control,
select.form-control:not([size]):not([multiple]) {
	height: 46px;
}
select.form-control,
textarea.form-control {
	height: auto;
}

/* form control lg */
.form-control-lg, 
.input-group-lg > .form-control, 
.input-group-lg > .input-group-append > .btn, 
.input-group-lg > .input-group-append > .input-group-text, 
.input-group-lg > .input-group-prepend > .btn, 
.input-group-lg > .input-group-prepend > .input-group-text,
.custom-select-lg {
	height: 56px;
	padding: 5px 18px;
	font-size: 18px;
}
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), 
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), 
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), 
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), 
.input-group-lg > select.form-control:not([size]):not([multiple]), 
select.form-control-lg:not([size]):not([multiple]) {
	height: 56px;
}

/* form control sm */
.form-control-sm, 
.input-group-sm > .form-control, 
.input-group-sm > .input-group-append > .btn, 
.input-group-sm > .input-group-append > .input-group-text, 
.input-group-sm > .input-group-prepend > .btn, 
.input-group-sm > .input-group-prepend > .input-group-text,
.custom-select-sm {
	height: 34px;
	padding: 3px 10px;
	font-size: 16px;
}
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), 
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), 
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), 
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), 
.input-group-sm > select.form-control:not([size]):not([multiple]), 
select.form-control-sm:not([size]):not([multiple]) {
	height: 34px;
}


/* ------------------------------------------------------------- *
 *  Accordion
/* ------------------------------------------------------------- */

.tt-accordion {
	position: relative;
}


/* tt-accordion item 
================== */
.tt-accordion-item {
	margin-bottom: 10px;
}

/* tt-accordion item header */
.tt-accordion-item-header {
	background-color: #EEE;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.tt-accordion-item-header:hover {
	background-color: #EAEAEA;
}

.tt-accordion-item-title {
	margin: 0;
	font-size: 19px;
	font-weight: 500;
}

.tt-accordion-item-title button {
	position: relative;
	width: 100%;
	padding: 18px 60px 18px 18px;
	background: transparent;
	cursor: pointer;
	text-align: left;
	font-weight: inherit;
	outline: none;
	border: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.tt-accordion-item-arrow {
	position: absolute;
	content: "";
	right: 20px;
	top: 50%;
	margin-top: -10px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	font-size: 16px;
	color: inherit;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.tt-accordion-item-title button.collapsed > .tt-accordion-item-arrow {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}

/* tt-accordion item body */
.tt-accordion-item-body {
	padding: 15px;
}


/* tt-accordion styles 
==================== */
/* tt-accordion boxed style */
.tt-accordion.accordion-boxed .tt-accordion-item-header {
	background-color: transparent;
	-webkit-box-shadow: 0 0 0 2px #DDD;
	-moz-box-shadow: 0 0 0 2px #DDD;
	box-shadow: 0 0 0 2px #DDD;
}

/* tt-accordion minimal style */
.tt-accordion.accordion-minimal .tt-accordion-item-header {
	background-color: transparent;
	border-bottom: 2px solid #222;
}
.tt-accordion.accordion-minimal .tt-accordion-item-header .tt-accordion-item-title button {
	padding-left: 0;
}
.tt-accordion.accordion-minimal .tt-accordion-item-arrow {
	right: 0;
}

/* tt-accordion dark style */
.tt-accordion.accordion-dark .tt-accordion-item-header {
	background-color: #222;
	color: #FFF;
}
.tt-accordion.accordion-dark .tt-accordion-item-header .tt-accordion-item-title button {
	color: #FFF;
}

/* tt-accordion colored style */
.tt-accordion.accordion-colored .tt-accordion-item-header {
	background-color: #e05536;
	color: #FFF;
}
.tt-accordion.accordion-colored .tt-accordion-item-header .tt-accordion-item-title button {
	color: #FFF;
}


/* ------------------------------------------------------------- *
 * Buttons
/* ------------------------------------------------------------- */

.btn {
	position: relative;
	display: inline-block;
	padding: 15px 25px;
	margin: 0 5px 5px 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	z-index: 1;
	border: 0 solid transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	overflow: hidden;

	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.btn.focus, 
.btn:focus {
	outline: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* button border radius */
.btn,
.btn-group-lg > .btn,
.btn-group-sm > .btn {
	border-radius: 0;
}

/* button margin bottom */
.btn,
.btn-group-toggle > .btn, 
.btn-group-toggle > .btn-group > .btn {
	margin-bottom: 6px;
}


/* Button global hover pseudo element 
====================================== */
.btn::before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.15);
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);

	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.btn:hover::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

/* button active */
.btn:not(:disabled):not(.disabled).active::before,
.btn:not(:disabled):not(.disabled):active::before,
.show > .btn.dropdown-toggle::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

/* button disabled */
.btn.disabled::before, 
.btn:disabled::before {
	display: none;
}

/* button toggle */
.btn[data-toggle="button"]::after {
	position: absolute;
	display: inline-block;
	content: "";
	top: 3px;
	right: 6px;
	width: 5px;
	height: 8px;
	border: solid #FFF;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .3s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .3s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .3s cubic-bezier(.645,.045,.355,1);
	transition: all .3s cubic-bezier(.645,.045,.355,1);
}
.btn[data-toggle="button"].active::after {
	opacity: 1;
	visibility: visible;
}
.btn.btn-light[data-toggle="button"]::after, 
.btn.btn-outline-light[data-toggle="button"]::after {
	border-color: #333;
}
.btn[class*="btn-outline-"][data-toggle="button"]::after {
	top: 0;
	right: 3px;
}

/* button checkbox and radio */
.btn-group-toggle[data-toggle="buttons"] .btn::before {
	background-color: rgba(0, 0, 0, 0.3);
}



/* Standard buttons 
==================== */
/* Button primary */
.btn-primary,

.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show > .btn-primary.dropdown-toggle,

.btn-primary:hover,
.btn-primary.focus, 
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show > .btn-primary.dropdown-toggle:focus {
	color: #fff;
	background-color: #e05536;
	border-color: #e05536;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show > .btn-primary.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-primary::before {
}
.btn-primary.disabled, 
.btn-primary:disabled {
	color: #fff;
	background-color: #e05536;
	border-color: #e05536;
}

/* Button secondary */
.btn-secondary,

.btn-secondary:not(:disabled):not(.disabled).active, 
.btn-secondary:not(:disabled):not(.disabled):active, 
.show > .btn-secondary.dropdown-toggle,

.btn-secondary:hover,
.btn-secondary.focus, 
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.show > .btn-secondary.dropdown-toggle:focus {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.show > .btn-secondary.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-secondary::before {
}

/* Button success */
.btn-success,

.btn-success:not(:disabled):not(.disabled).active, 
.btn-success:not(:disabled):not(.disabled):active, 
.show > .btn-success.dropdown-toggle,

.btn-success:hover,
.btn-success.focus, 
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled).active:focus, 
.btn-success:not(:disabled):not(.disabled):active:focus, 
.show > .btn-success.dropdown-toggle:focus {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active:focus, 
.btn-success:not(:disabled):not(.disabled):active:focus, 
.show > .btn-success.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-success::before {
}

/* Button danger */
.btn-danger,

.btn-danger:not(:disabled):not(.disabled).active, 
.btn-danger:not(:disabled):not(.disabled):active, 
.show > .btn-danger.dropdown-toggle,

.btn-danger:hover,
.btn-danger.focus, 
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus, 
.btn-danger:not(:disabled):not(.disabled):active:focus, 
.show > .btn-danger.dropdown-toggle:focus {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active:focus, 
.btn-danger:not(:disabled):not(.disabled):active:focus, 
.show > .btn-danger.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-danger::before {
}

/* Button warning */
.btn-warning,

.btn-warning:not(:disabled):not(.disabled).active, 
.btn-warning:not(:disabled):not(.disabled):active, 
.show > .btn-warning.dropdown-toggle,

.btn-warning:hover,
.btn-warning.focus, 
.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus, 
.btn-warning:not(:disabled):not(.disabled):active:focus, 
.show > .btn-warning.dropdown-toggle:focus {
	color: #333;
	background-color: #ffc107;
	border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active:focus, 
.btn-warning:not(:disabled):not(.disabled):active:focus, 
.show > .btn-warning.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-warning::before {
}

/* Button info */
.btn-info,

.btn-info:not(:disabled):not(.disabled).active, 
.btn-info:not(:disabled):not(.disabled):active, 
.show > .btn-info.dropdown-toggle,

.btn-info:hover,
.btn-info.focus, 
.btn-info:focus,
.btn-info:not(:disabled):not(.disabled).active:focus, 
.btn-info:not(:disabled):not(.disabled):active:focus, 
.show > .btn-info.dropdown-toggle:focus {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active:focus, 
.btn-info:not(:disabled):not(.disabled):active:focus, 
.show > .btn-info.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-info::before {
}

/* Button light */
.btn-light,

.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show > .btn-light.dropdown-toggle,

.btn-light:hover,
.btn-light.focus, 
.btn-light:focus,
.btn-light:not(:disabled):not(.disabled).active:focus, 
.btn-light:not(:disabled):not(.disabled):active:focus, 
.show > .btn-light.dropdown-toggle:focus {
	color: #333;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active:focus, 
.btn-light:not(:disabled):not(.disabled):active:focus, 
.show > .btn-light.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-light::before {
	background-color: rgba(0, 0, 0, 0.1);
}

/* Button gray */
.btn-gray,

.btn-gray:not(:disabled):not(.disabled).active, 
.btn-gray:not(:disabled):not(.disabled):active, 
.show > .btn-gray.dropdown-toggle,

.btn-gray:hover,
.btn-gray.focus, 
.btn-gray:focus,
.btn-gray:not(:disabled):not(.disabled).active:focus, 
.btn-gray:not(:disabled):not(.disabled):active:focus, 
.show > .btn-gray.dropdown-toggle:focus {
	color: #333;
	background-color: rgba(160, 160, 160, 0.2);
	border-color: rgba(160, 160, 160, 0.2);
}
.btn-gray:not(:disabled):not(.disabled).active:focus, 
.btn-gray:not(:disabled):not(.disabled):active:focus, 
.show > .btn-gray.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-gray::before {
	background-color: rgba(0, 0, 0, 0.07);
}

/* Button dark */
.btn-dark,

.btn-dark:not(:disabled):not(.disabled).active, 
.btn-dark:not(:disabled):not(.disabled):active, 
.show > .btn-dark.dropdown-toggle,

.btn-dark:hover,
.btn-dark.focus, 
.btn-dark:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus, 
.btn-dark:not(:disabled):not(.disabled):active:focus, 
.show > .btn-dark.dropdown-toggle:focus {
	color: #fff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active:focus, 
.btn-dark:not(:disabled):not(.disabled):active:focus, 
.show > .btn-dark.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-dark::before {
	background-color: #000;
}

/* Button link */
.btn-link,

.btn-link:not(:disabled):not(.disabled).active, 
.btn-link:not(:disabled):not(.disabled):active, 
.show > .btn-link.dropdown-toggle,

.btn-link:hover,
.btn-link.focus, 
.btn-link:focus,
.btn-link:not(:disabled):not(.disabled).active:focus, 
.btn-link:not(:disabled):not(.disabled):active:focus, 
.show > .btn-link.dropdown-toggle:focus {
	color: #e05536;
	background-color: transparent;
	border-color: transparent;
}
.btn-link:not(:disabled):not(.disabled).active:focus, 
.btn-link:not(:disabled):not(.disabled):active:focus, 
.show > .btn-link.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-link:before {
	display: none;
}

.text-light .btn-link,
.text-white .btn-link {
	color: #FFF !important;
}
.text-light .btn-link:hover,
.text-light .btn-link.focus, 
.text-light .btn-link:focus,
.text-light .btn-link:not(:disabled):not(.disabled).active:focus, 
.text-light .btn-link:not(:disabled):not(.disabled):active:focus, 
.text-light .show > .btn-link.dropdown-toggle:focus,
.text-white .btn-link:hover,
.text-white .btn-link.focus, 
.text-white .btn-link:focus,
.text-white .btn-link:not(:disabled):not(.disabled).active:focus, 
.text-white .btn-link:not(:disabled):not(.disabled):active:focus, 
.text-white .show > .btn-link.dropdown-toggle:focus {
	color: #e05536 !important;
	background-color: transparent;
	border-color: transparent;
}


/* Button outline 
================== */
.btn[class*="btn-outline-"] {
	background-color: transparent;
	background-image: none;
	padding: 12px 22px;
	border-width: 3px;

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.btn[class*="btn-outline-"]:not(:disabled):not(.disabled).active:focus, 
.btn[class*="btn-outline-"]:not(:disabled):not(.disabled):active:focus, 
.show > .btn[class*="btn-outline-"].dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

/* Button outline primary */
.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:disabled, 
.btn-outline-primary.disabled,
.btn-outline-primary:disabled:hover, 
.btn-outline-primary.disabled:hover {
	color: #e05536;
	border-color: #e05536;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active, 
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	border-color: #e05536;
}
.btn-outline-primary::before {
	background-color: #e05536;
}

/* Button outline secondary */
.btn-outline-secondary,
.btn-outline-secondary:focus,
.btn-outline-secondary:disabled, 
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled:hover, 
.btn-outline-secondary.disabled:hover {
	color: #6c757d;
	border-color: #6c757d;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled).active, 
.btn-outline-secondary:not(:disabled):not(.disabled):active, 
.show > .btn-outline-secondary.dropdown-toggle {
	color: #fff;
	border-color: #6c757d;
}
.btn-outline-secondary::before {
	background-color: #6c757d;
}

/* Button outline success */
.btn-outline-success,
.btn-outline-success:focus,
.btn-outline-success:disabled, 
.btn-outline-success.disabled,
.btn-outline-success:disabled:hover, 
.btn-outline-success.disabled:hover {
	color: #28a745;
	border-color: #28a745;
}
.btn-outline-success:hover,
.btn-outline-success:not(:disabled):not(.disabled).active, 
.btn-outline-success:not(:disabled):not(.disabled):active, 
.show > .btn-outline-success.dropdown-toggle {
	color: #fff;
	border-color: #28a745;
}
.btn-outline-success::before {
	background-color: #28a745;
}

/* Button outline danger */
.btn-outline-danger,
.btn-outline-danger:focus,
.btn-outline-danger:disabled, 
.btn-outline-danger.disabled,
.btn-outline-danger:disabled:hover, 
.btn-outline-danger.disabled:hover {
	color: #dc3545;
	border-color: #dc3545;
}
.btn-outline-danger:hover,
.btn-outline-danger:not(:disabled):not(.disabled).active, 
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.show > .btn-outline-danger.dropdown-toggle {
	color: #fff;
	border-color: #dc3545;
}
.btn-outline-danger::before {
	background-color: #dc3545;
}

/* Button outline warning */
.btn-outline-warning,
.btn-outline-warning:focus,
.btn-outline-warning:disabled, 
.btn-outline-warning.disabled,
.btn-outline-warning:disabled:hover, 
.btn-outline-warning.disabled:hover {
	color: #ffc107;
	border-color: #ffc107;
}
.btn-outline-warning:hover,
.btn-outline-warning:not(:disabled):not(.disabled).active, 
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.show > .btn-outline-warning.dropdown-toggle {
	color: #333;
	border-color: #ffc107;
}
.btn-outline-warning::before {
	background-color: #ffc107;
}

/* Button outline info */
.btn-outline-info,
.btn-outline-info:focus,
.btn-outline-info:disabled, 
.btn-outline-info.disabled,
.btn-outline-info:disabled:hover, 
.btn-outline-info.disabled:hover {
	color: #17a2b8;
	border-color: #17a2b8;
}
.btn-outline-info:hover,
.btn-outline-info:not(:disabled):not(.disabled).active, 
.btn-outline-info:not(:disabled):not(.disabled):active, 
.show > .btn-outline-info.dropdown-toggle {
	color: #fff;
	border-color: #17a2b8;
}
.btn-outline-info::before {
	background-color: #17a2b8;
}

/* Button outline light */
.btn-outline-light,
.btn-outline-light:focus,
.btn-outline-light:disabled, 
.btn-outline-light.disabled,
.btn-outline-light:disabled:hover, 
.btn-outline-light.disabled:hover {
	color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled).active, 
.btn-outline-light:not(:disabled):not(.disabled):active, 
.show > .btn-outline-light.dropdown-toggle {
	color: #333;
	border-color: #f8f9fa;
}
.btn-outline-light::before {
	background-color: #f8f9fa;
}

/* Button outline gray */
.btn-outline-gray,
.btn-outline-gray:focus,
.btn-outline-gray:disabled, 
.btn-outline-gray.disabled,
.btn-outline-gray:disabled:hover, 
.btn-outline-gray.disabled:hover {
	color: rgba(160, 160, 160, 0.8);
	border-color: rgba(160, 160, 160, 0.5);
}
.btn-outline-gray:hover,
.btn-outline-gray:not(:disabled):not(.disabled).active, 
.btn-outline-gray:not(:disabled):not(.disabled):active, 
.show > .btn-outline-gray.dropdown-toggle {
	color: #333;
}
.btn-outline-gray::before {
	background-color: rgba(160, 160, 160, 0.5);
}

/* Button outline dark */
.btn-outline-dark,
.btn-outline-dark:focus,
.btn-outline-dark:disabled, 
.btn-outline-dark.disabled,
.btn-outline-dark:disabled:hover, 
.btn-outline-dark.disabled:hover {
	color: #111;
	border-color: #111;
}
.btn-outline-dark:hover,
.btn-outline-dark:not(:disabled):not(.disabled).active, 
.btn-outline-dark:not(:disabled):not(.disabled):active, 
.show > .btn-outline-dark.dropdown-toggle {
	color: #fff;
	border-color: #111;
}
.btn-outline-dark::before {
	background-color: #111;
}

/* Button outline link */
.btn-outline-link,

.btn-outline-link:not(:disabled):not(.disabled).active, 
.btn-outline-link:not(:disabled):not(.disabled):active, 
.show > .btn-outline-link.dropdown-toggle,

.btn-outline-link:hover,
.btn-outline-link.focus, 
.btn-outline-link:focus,
.btn-outline-link:not(:disabled):not(.disabled).active:focus, 
.btn-outline-link:not(:disabled):not(.disabled):active:focus, 
.show > .btn-outline-link.dropdown-toggle:focus {
	color: #e05536;
	background-color: transparent;
	border-color: transparent;
}
.btn-outline-link:not(:disabled):not(.disabled).active:focus, 
.btn-outline-link:not(:disabled):not(.disabled):active:focus, 
.show > .btn-outline-link.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.btn-outline-link:before {
	display: none;
}


/* Button block 
================ */
.btn-block + .btn-block {
    margin-top: 0;
}


/* Extra shadow 
================ */
.btn-shadow:not(.btn-outline-link):not(.btn-link) {
	-webkit-box-shadow: 0px 6px 25px -5px rgba(0, 0, 0, 0.45);
	-moz-box-shadow: 0px 6px 25px -5px rgba(0, 0, 0, 0.45);
	box-shadow: 0px 6px 25px -5px rgba(0, 0, 0, 0.45);
}


/* Button group 
================ */
.btn-group .btn {
}


/* Button dropdown 
=================== */
.dropdown-toggle::after {
	vertical-align: 0.155em;
}

/* button dropdown toggle split */
.btn[class*="btn-outline-"].dropdown-toggle-split {
	margin-left: -3px;
}

/* button dropdown menu */
.dropdown-menu {
	border-radius: 0;
}
.dropdown-item.active, 
.dropdown-item:active {
	color: #FFF;
	text-decoration: none;
	background-color: #e05536;
}
.dropdown-item:focus, 
.dropdown-item:hover {
	color: #000;
	text-decoration: none;
	background-color: #F1F1F1;
}


/* Button social 
================= */
.btn-social {
	position: relative;
	padding-left: 55px;
	text-align: left;
	color: #FFF;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn-social:hover, 
.btn-social:focus, 
.btn-social:active,
.btn-social.active {
	color: #FFF;
}

/* Button social icon */
.btn-social > .btn-social-icon {
	position: absolute;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	left: 0;
	top: 0;
	bottom: 0;
	width: 42px;
	font-size: 20px;
	border-right: 1px solid rgba(0,0,0,0.12);
}
.btn-social > .btn-social-icon > i {
}

/* Button social sizes */
.btn-social.btn-xs { padding-left: 55px; }
.btn-social.btn-sm { padding-left: 55px; }
.btn-social.btn-lg { padding-left: 60px; }
.btn-social.btn-xlg { padding-left: 65px; }


/* Button social min 
===================== */
.btn-social-min {
	position: relative;
	width: 46px;
	height: 46px;
	padding: 0 !important;
	display: inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 16px;
}

.btn-social-min.btn-link {
	width: 30px;
	height: 30px;
	color: #333;
}
.btn-social-min.btn-link:hover,
.btn-social-min.btn-link:focus {
	color: #e05536;
}

/* Button social min sizes */
.btn-social-min.btn-xs { 
	width: 28px;
	height: 28px;
	line-height: 22px;
	font-size: 12px;
}
.btn-social-min.btn-link.btn-xs {
	width: 20px;
	height: 20px;
}


.btn-social-min.btn-sm { 
	width: 34px;
	height: 34px;
	line-height: 28px;
	font-size: 13px;
}
.btn-social-min.btn-link.btn-sm {
	width: 24px;
	height: 24px;
}

@media (min-width: 991px) {
	.btn-social-min.btn-lg { 
		width: 54px;
		height: 54px;
		line-height: 48px;
		font-size: 18px;
	}
	.btn-social-min.btn-link.btn-lg {
		width: 34px;
		height: 34px;
	}

	.btn-social-min.btn-xlg { 
		width: 68px;
		height: 68px;
		line-height: 62px;
		font-size: 22px;
	}
	.btn-social-min.btn-link.btn-xlg {
		width: 34px;
		height: 34px;
	}
}


/* Button social types 
======================= */

/* Button facebook */
.btn-facebook { 
	background-color: #3b5998;
	color: #FFF;
}
.btn-facebook:hover,
.btn-facebook:focus{
	color: #FFF;
}

/* Button twitter */
.btn-twitter { 
	background-color: #55acee;
	color: #FFF;
}
.btn-twitter:hover,
.btn-twitter:focus{
	color: #FFF;
}

/* Button dropbox */
.btn-dropbox { 
	background-color: #1087dd;
	color: #FFF;
}
.btn-dropbox:hover,
.btn-dropbox:focus{
	color: #FFF;
}

/* Button linkedin */
.btn-linkedin { 
	background-color: #007bb6;
	color: #FFF;
}
.btn-linkedin:hover,
.btn-linkedin:focus{
	color: #FFF;
}

/* Button pinterest */
.btn-pinterest { 
	background-color: #cb2027;
	color: #FFF;
}
.btn-pinterest:hover,
.btn-pinterest:focus{
	color: #FFF;
}

/* Button google */
.btn-google { 
	background-color: #dd4b39;
	color: #FFF;
}
.btn-google:hover,
.btn-google:focus{
	color: #FFF;
}

/* Button instagram */
.btn-instagram {
	background-color: #3f729b;
	color: #FFF;
}
.btn-instagram:hover,
.btn-instagram:focus{
	color: #FFF;
}

/* Button tumblr */
.btn-tumblr { 
	background-color: #2c4762;
	color: #FFF;
}
.btn-tumblr:hover,
.btn-tumblr:focus{
	color: #FFF;
}

/* Button vk */
.btn-vk { 
	background-color: #587ea3;
	color: #FFF;
}
.btn-vk:hover,
.btn-vk:focus{
	color: #FFF;
}

/* Button flickr */
.btn-flickr { 
	background-color: #ff0084;
	color: #FFF;
}
.btn-flickr:hover,
.btn-flickr:focus{
	color: #FFF;
}

/* Button soundcloud */
.btn-soundcloud { 
	background-color: #f50;
	color: #FFF;
}
.btn-soundcloud:hover,
.btn-soundcloud:focus{
	color: #FFF;
}

/* Button yahoo */
.btn-yahoo { 
	background-color: #720e9e;
	color: #FFF;
}
.btn-yahoo:hover,
.btn-yahoo:focus{
	color: #FFF;
}

/* Button dribbble */
.btn-dribbble { 
	background-color: #222;
	color: #FFF;
}
.btn-dribbble:hover,
.btn-dribbble:focus{
	color: #FFF;
}

/* Button youtube */
.btn-youtube { 
	background-color: #cc181e;
	color: #FFF;
}
.btn-youtube:hover,
.btn-youtube:focus{
	color: #FFF;
}

/* Button behance */
.btn-behance { 
	background-color: #0057ff;
	color: #FFF;
}
.btn-behance:hover,
.btn-behance:focus{
	color: #FFF;
}


/* Button close 
================= */
.tt-close-btn {
	position: relative;
	display: inline-block;
	top: 6px;
	width: 21px;
	height: 21px;
	margin: 0;
	padding: 0;
	cursor: pointer;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);

	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.tt-close-btn:hover {
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}
.tt-close-btn::before, 
.tt-close-btn::after {
	content: '';
	position: absolute;
	background-color: #000;
}
.tt-close-btn::before {
	left: 0;
	top: 9px;
	width: 100%;
	height: 3px;
}
.tt-close-btn::after {
	left: 9px;
	top: 0;
	width: 3px;
	height: 100%;
}

/* Button close light color*/
.tt-close-btn.tt-close-light::before, 
.tt-close-btn.tt-close-light::after {
	background-color: #FFF;
}


/* Button rounded 
================== */
.btn-rounded {
	border-radius: 4px;
}
.btn-rounded-2x {
	border-radius: 8px;
}
.btn-rounded-3x {
	border-radius: 12px;
}
.btn-rounded-4x {
	border-radius: 17px;
}
.btn-rounded-5x {
	border-radius: 50px;
}
.btn-rounded-full {
	border-radius: 50%;
}


/* Button sizes 
================ */
.btn-group-xs > .btn, .btn-xs { /* Extra size */
	padding: 5px 10px;
	font-size: 13px;
}
.btn-group-xs > .btn[class*="btn-outline-"],
.btn[class*="btn-outline-"].btn-xs {
	padding: 3px 8px;
	border-width: 2px;
}

.btn-group-sm > .btn, .btn-sm {
	padding: 10px 15px;
	font-size: 14px;
}
.btn-group-sm > .btn[class*="btn-outline-"],
.btn[class*="btn-outline-"].btn-sm {
	padding: 7px 12px;
}

.btn-group-lg > .btn, .btn-lg {
	padding: 20px 30px;
	font-size: 18px;
}
.btn-group-lg > .btn[class*="btn-outline-"],
.btn[class*="btn-outline-"].btn-lg {
	padding: 17px 27px;
}

.btn-group-xlg > .btn, .btn-xlg { /* Extra size */
	padding: 25px 35px;
	font-size: 19px;
}
.btn-group-xlg > .btn[class*="btn-outline-"],
.btn[class*="btn-outline-"].btn-xlg {
	padding: 22px 32px;
}


/* Play button 
=============== */
.btn-play-wrap {
	display: inline-block;
}
.btn.btn-play {
	position: relative;
	width: 46px;
	height: 46px;
	padding: 0 0 0 4px !important;
	display: inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 14px;
	text-decoration: none;
}

.btn-play.btn-link {
	width: 30px;
	height: 30px;
}

/* button play sizes */
.btn-play.btn-xs { 
	width: 28px;
	height: 28px;
	padding: 0 !important;
	line-height: 22px;
	font-size: 8px;
}
.btn-play.btn-link.btn-xs {
	width: 20px;
	height: 20px;
}

.btn-play.btn-sm { 
	width: 34px;
	height: 34px;
	padding: 0 !important;
	line-height: 28px;
	font-size: 10px;
}
.btn-play.btn-link.btn-sm {
	width: 24px;
	height: 24px;
}

.btn-play.btn-lg { 
	width: 62px;
	height: 62px;
	line-height: 48px;
	font-size: 18px;
}
.btn-play.btn-link.btn-lg {
	width: 34px;
	height: 34px;
}

.btn-play.btn-xlg { 
	width: 86px;
	height: 86px;
	line-height: 62px;
	font-size: 24px;
}
.btn-play.btn-link.btn-xlg {
	width: 42px;
	height: 42px;
}

/* play button animation */
.btn-play-wrap.btn-play-animate .btn.btn-play {
	-webkit-animation: vbbulse 2s infinite;
	animation: vbbulse 2s infinite;
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
}
@-webkit-keyframes vbbulse {
	0% { -webkit-box-shadow: 0 0 0 0 rgba(181, 181, 181, 0.86); }
	70% { -webkit-box-shadow: 0 0 0 25px rgba(0, 64, 216, 0); }
	100% { -webkit-box-shadow: 0 0 0 0 rgba(0, 64, 216, 0); }
}
@keyframes vbbulse {
	0% { -moz-box-shadow: 0 0 0 0 rgba(181, 181, 181, 0.86); box-shadow: 0 0 0 0 rgba(181, 181, 181, 0.86); }
	70% { -moz-box-shadow: 0 0 0 25px rgba(0, 64, 216, 0); box-shadow: 0 0 0 25px rgba(0, 64, 216, 0); }
	100% { -moz-box-shadow: 0 0 0 0 rgba(0, 64, 216, 0); box-shadow: 0 0 0 0 rgba(0, 64, 216, 0); }
}


/* ------------------------------------------------------------- *
 * Social buttons
/* ------------------------------------------------------------- */

.social-buttons {
}
.social-buttons ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.social-buttons ul > li {
	display: inline-block;
	margin: 0 -2px;
}
.social-buttons ul > li:first-child {
	margin-left: 0;
}
.social-buttons ul > li:last-child {
	margin-right: 0;
}

.social-buttons .btn.btn-link {
	width: auto;
	height: auto;
	line-height: 1.4;
	margin-right: 15px;
	border: none !important;
	border-radius: 0;
	text-decoration: none;
	overflow: visible;
}
.social-buttons li:last-child .btn.btn-link {
	margin-right: 0;
}


/* ------------------------------------------------------------- *
 * Modal
/* ------------------------------------------------------------- */

.modal {
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 99999;
}

/* modal backdrop */
.modal-backdrop {
	display: none;
}

/* modal dialog */
.modal-dialog {
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 900px;
	}
}

/* modal fade */
.modal.fade .modal-dialog {
	-webkit-transform: translate(0, -20px);
	transform: translate(0, -20px);
}
.modal.show .modal-dialog {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}

/* modal content */
.modal-content {
	background-color: #FFF;
	border: none;
	border-radius: 0;
}

/* modal header */
.modal-header {
	background-color: #f1f1f1;
	padding: 20px 50px 20px 20px;
	border: none;
}

.modal-title {
	margin: 0;
	font-size: 20px;
}

/* modal body */
.modal-body {
	padding: 40px 20px 20px 20px;
}
@media (max-width: 992px) {
	.modal-body {
		padding: 30px 15px 20px 15px;
	}
}

.modal-dialog.modal-lg .modal-body {
	padding: 40px 30px 20px 30px;
}
@media (max-width: 992px) {
	.modal-dialog.modal-lg .modal-body {
		padding: 30px 15px 20px 15px;
	}
}

/* modal footer */
.modal-footer {
	padding: 20px;
	text-align: left;
	color: #999;
	justify-content: flex-start;
}


/* close */
.modal-content .close {
	position: absolute;
	top: 7px;
	right: 14px;
	float: none;
	font-weight: normal;
	line-height: 1;
	font-size: 38px;
	z-index: 9;
}


/* Modal sidebar left/right
============================= */
.modal.modal-left .modal-dialog,
.modal.modal-right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 100%;
	max-width: 40%;
	height: 100%;
	border-radius: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	    -ms-transform: translate3d(0%, 0, 0);
	     -o-transform: translate3d(0%, 0, 0);
	        transform: translate3d(0%, 0, 0);
}
@media (max-width: 1440px) {
	.modal.modal-left .modal-dialog,
	.modal.modal-right .modal-dialog {
		max-width: 70%;
	}
}
@media (max-width: 768px) {
	.modal.modal-left .modal-dialog,
	.modal.modal-right .modal-dialog {
		max-width: 100%;
	}
}

/* Left */
.modal.modal-left.fade .modal-dialog{
	left: -40%;
	-webkit-transition: opacity 0.4s linear, left 0.4s ease-out;
	   -moz-transition: opacity 0.4s linear, left 0.4s ease-out;
	     -o-transition: opacity 0.4s linear, left 0.4s ease-out;
	        transition: opacity 0.4s linear, left 0.4s ease-out;
}
.modal.modal-left.fade.show .modal-dialog{
	left: 0;
}
     
/* Right */
.modal.modal-right.fade .modal-dialog {
	right: -40%;
	-webkit-transition: opacity 0.4s linear, right 0.4s ease-out;
	   -moz-transition: opacity 0.4s linear, right 0.4s ease-out;
	     -o-transition: opacity 0.4s linear, right 0.4s ease-out;
	        transition: opacity 0.4s linear, right 0.4s ease-out;
}
.modal.modal-right.fade.show .modal-dialog {
	right: 0;
}


/* Modal sidebar style 
======================== */
.modal.modal-left,
.modal.modal-right {
	background-color: rgba(0, 0, 0, 0.8);
}

.modal.modal-left .modal-content,
.modal.modal-right .modal-content {
	height: 100%;
	border-radius: 0;
	border: none;
	overflow-y: auto;
}

.modal.modal-left .modal-header,
.modal.modal-right .modal-header {
	border-bottom: none;
}

.modal.modal-left .modal-body,
.modal.modal-right .modal-body {
	padding: 50px 30px 20px 30px;
}
@media (max-width: 992px) {
	.modal.modal-left .modal-body,
	.modal.modal-right .modal-body {
		padding: 50px 15px 20px 15px;
	}
}


/* If header & footer is fixed
=============================== */
body.modal-open #header.header-show-hide-on-scroll .header-inner,
body.modal-open #header.header-fixed-top .header-inner {
	margin-right: 17px !important;
}
body.modal-open #footer.footer-fixed {
	right: 17px !important;
}
body.modal-open .scrolltotop {
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0s ease-in-out;
	-moz-transition: all 0s ease-in-out;
	-o-transition: all 0s ease-in-out;
	-ms-transition: all 0s ease-in-out;
	transition: all 0s ease-in-out;
}


/* ------------------------------------------------------------- *
 * Blockquotes
/* ------------------------------------------------------------- */

blockquote,
.blockquote {
	margin-top: 10px;
	margin-bottom: 30px;
	margin-left: 30px;
	padding-left: 30px;
	text-align: left;
	font-size: 22px;
	font-style: italic;
	font-weight: 500;
	border-left: 4px solid #e05536;
}
@media (max-width: 992px) {
	blockquote,
	.blockquote {
		font-size: 18px;
	}
}

blockquote.text-center,
.blockquote.text-center {
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	border: none;
}
blockquote.text-right,
.blockquote.text-right {
	border-left: none;
	padding-right: 30px;
	border-right: 4px solid #e05536;
}

blockquote p,
.blockquote p {
	padding-left: 0;
	font-weight: 500;
	font-style: italic;
	margin: 0;
}

.blockquote-footer {
	display: block;
	margin-top: 15px;
	font-size: 85%;
	color: #888;
	font-weight: 400;
	font-style: normal;
}


/* ------------------------------------------------------------- *
 * Testimonial carousel
/* ------------------------------------------------------------- */

.testimonial-carousel {
}


/* ------------------------------------------------------------- *
 * Badges
/* ------------------------------------------------------------- */

.badge {
	display: inline-block;
	padding: 3px 5px;
	font-size: 75%;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	border-radius: 0;
}

.badge-pill {
	border-radius: 20px;
}

/* Badge styles 
================ */
/* badge primary */
.badge-primary {
	color: #FFF;
	background-color: #e05536;
}
.badge-primary[href]:focus, 
.badge-primary[href]:hover {
	color: #FFF;
	text-decoration: none;
	background-color: #e05536;
	opacity: .8;
}

/* badge secondary */
.badge-secondary {
	color: #fff;
	background-color: #6c757d;
}
.badge-secondary[href]:focus, 
.badge-secondary[href]:hover {
	color: #FFF;
	text-decoration: none;
	background-color: #6c757d;
	opacity: .8;
}

/* badge success */
.badge-success {
	color: #fff;
	background-color: #28a745;
}
.badge-success[href]:focus, 
.badge-success[href]:hover {
	color: #FFF;
	text-decoration: none;
	background-color: #28a745;
	opacity: .8;
}

/* badge danger */
.badge-danger {
	color: #fff;
	background-color: #dc3545;
}
.badge-danger[href]:focus, 
.badge-danger[href]:hover {
	color: #FFF;
	text-decoration: none;
	background-color: #dc3545;
	opacity: .8;
}

/* badge warning */
.badge-warning {
	color: #333;
	background-color: #ffc107;
}
.badge-warning[href]:focus, 
.badge-warning[href]:hover {
	color: #333;
	text-decoration: none;
	background-color: #ffc107;
	opacity: .8;
}

/* badge info */
.badge-info {
	color: #fff;
	background-color: #17a2b8;
}
.badge-info[href]:focus, 
.badge-info[href]:hover {
	color: #FFF;
	text-decoration: none;
	background-color: #17a2b8;
	opacity: .8;
}

/* badge light */
.badge-light {
	color: #333;
	background-color: #f8f9fa;
}
.badge-light[href]:focus, 
.badge-light[href]:hover {
	color: #333;
	text-decoration: none;
	background-color: #f8f9fa;
	opacity: .8;
}

/* badge dark */
.badge-dark {
	color: #fff;
	background-color: #343a40;
}
.badge-dark[href]:focus, 
.badge-dark[href]:hover {
	color: #FFF;
	text-decoration: none;
	background-color: #343a40;
	opacity: .8;
}


/* ------------------------------------------------------------- *
 * Info box
/* ------------------------------------------------------------- */

.info-box-wrap {
}

.info-box {
	position: relative;
	margin-bottom: 50px;
	text-align: center;
}

/* info box icon */
.info-box-icon {
	display: inline-block;
	margin-bottom: 15px;
	font-size: 34px;
}

/* info box info */
.info-box-info {
}

.info-box-heading {
	margin: 0 0 20px 0;
	font-size: 21px;
}
.info-box-heading a {
	position: relative;
	color: inherit;
}
.info-box-heading a:hover {
	text-decoration: underline;
}

.info-box-text {
	line-height: 1.5;
	font-size: 19px;
}


/* Info box light (use class "ib-light" if the background is dark) 
=================================================================== */
.info-box-wrap.ib-light {
	color: #FFF;
}

/* Info boxes in service section if class "ss-light" is used. */
.info-box-wrap.ib-light.ib-boxed .info-box {
	background-color: rgba(250, 250, 250, 0.06);
	border: none;
}


/* Info box icon styles 
======================== */
/* info box icon color */
.info-box-wrap.ib-icon-color .info-box-icon {
	color: #e05536;
}

/* info box icon rounded */
.info-box-wrap.ib-icon-bg-color.ib-icon-rounded .info-box-icon {
	width: 70px;
	height: 70px;
	line-height: 70px;
	font-size: 24px;
	border-radius: 50%;
}

/* info box icon with background color */
.info-box-wrap.ib-icon-bg-color .info-box-icon {
	width: 60px;
	height: 60px;
	line-height: 60px;
	background-color: #e05536;
	margin-bottom: 25px;
	text-align: center;
	font-size: 21px;
	color: #FFF;
}

/* info box icon animation */
.info-box-wrap.ib-icon-animate .info-box:hover .info-box-icon {
	animation: ibpulse .25s ease-in-out 2 alternate;
	-webkit-animation: ibpulse .25s ease-in-out 2 alternate;
}
@-webkit-keyframes ibpulse { /* Chrome, Safari, Opera */
	0% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
	100% {
		transform: scale(1.2);
		-webkit-transform: scale(1.2);
	}
}
@keyframes ibpulse { /* Standard */
	0% {
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
	100% {
		transform: scale(1.2);
		-webkit-transform: scale(1.2);
	}
}

/* hide info box icon */
.info-box-wrap.ib-hide-icon .info-box-icon {
	display: none;
}


/* Info box styles 
=================== */

/* info box style-2 (no effect on smaller screens!) */
.info-box-wrap.ib-style-2 .info-box {
	text-align: left;
}
.info-box-wrap.ib-style-2 .info-box-icon {
	float: left;
	margin-right: 20px;
	margin-bottom: 0;
	font-size: 32px;
}
@media (max-width: 992px) {
	.info-box-wrap.ib-style-2 .info-box-icon {
		float: none;
	}
}
.info-box-wrap.ib-style-2 .info-box-info {
	display: table;
}
.info-box-wrap.ib-style-2 .info-box-heading {
	margin: 10px 0 12px 0;
	font-weight: 600;
}

.info-box-wrap.ib-style-2.ib-icon-bg-color .info-box-icon {
	width: 52px;
	height: 52px;
	line-height: 52px;
	margin-bottom: 0;
	font-size: 20px;
}

.info-box-wrap.ib-style-2.ib-icon-bg-color.ib-icon-rounded .info-box-icon {
	width: 52px;
	height: 52px;
	line-height: 52px;
	font-size: 20px;
	border-radius: 50%;
}

/* info box boxed style */
.info-box-wrap.ib-boxed .info-box {
	background-color: #FFF;
	margin-bottom: 30px;
	padding: 34px 24px;
	border: 1px solid rgba(128, 128, 128, 0.2);
	-webkit-box-shadow: 0 15px 20px -12px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 15px 20px -12px rgba(0, 0, 0, 0.2);
	box-shadow: 0 15px 20px -12px rgba(0, 0, 0, 0.2);
}
.info-box-wrap.ib-style-2.ib-boxed .info-box {
	padding: 30px;
}

/* info box text fixed height (shows only 3 lines) */
.info-box-wrap.ib-fixed-text .info-box-text {
	max-height: 4.5em;
	line-height: 1.5em;
	font-size: 19px;
	overflow: hidden;
}


/* ------------------------------------------------------------- *
 *  Contact info
/* ------------------------------------------------------------- */

.contact-info-container {
}

.contact-info-text {
	margin-bottom: 40px;
}

.contact-info-title {
}
.contact-info-title a {
	text-decoration: underline;
	color: inherit;
}
.contact-info-title a:hover {
	color: #e05536;
}

.contact-info {
}
.contact-info i {
	margin-right: 5px;
}
.contact-info ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.contact-info ul > li {
	margin-bottom: 12px;
}
.contact-info ul > li a {
	color: inherit;
}
.contact-info ul > li a:hover {
	opacity: .8;
}

.contact-info .address {
}
.contact-info .phone {
}
.contact-info .email {
}


/* Contact form 
================ */
#contact-form {
}
.contact-form-inner {
}

.contact-form-text {
	margin-bottom: 40px;
}


/* Specific code for contact section styles (if needed)
======================================================== */
/* contact section style-1 */
#contact-section.contact-style-1  {
}

/* contact section style-2 */
#contact-section.contact-style-2 {
}
#contact-section.contact-style-2 .text-white .contact-info a {
	color: #FFF;
	border-bottom: 1px dotted;
}
#contact-section.contact-style-2 .text-white .contact-info .email a:hover {
	opacity: .8;
}
#contact-section.contact-style-2 #contact-form .form-control {
	border: 2px solid #222;
}

/* contact section style-3 */
#contact-section.contact-style-3 {
}

/* contact section style-4 */
#contact-section.contact-style-4 {
}


/* -------------------------------------------------------------------------------- *
 *  Map (tt-map)
 *  Tutorial: https://developers.google.com/maps/documentation/javascript/tutorial
 *  Map styles: https://snazzymaps.com/
/* -------------------------------------------------------------------------------- */

#tt-map {
	width: 100%;
	min-height: 320px;
	padding-bottom: 30%; /* map-height */
}

/* if boxed layout is enabled */
body.tt-boxed .tt-wrap #tt-map {
	padding-bottom: 40%;
}


/* ------------------------------------------------------------- *
 * Blog list section
/* ------------------------------------------------------------- */

/* isotope in blog list section */
 .isotope-items-wrap {
	padding-top: 0;
}
@media (max-width: 768px) {
	 .isotope.col-2 .isotope-item,
	 .isotope.col-3 .isotope-item,
	 .isotope.col-4 .isotope-item,
	 .isotope.col-5 .isotope-item,
	 .isotope.col-6 .isotope-item {
		width: 100%;
	}
}


/* Blog list item 
================== */
.blog-list-item {
	position: relative;
	background-color: #FFF;
}
@media (max-width: 768px) {
	.blog-list-item {
		margin-bottom: 15px;
	}
}

/* blog list item image */
.bl-item-image-wrap {
	position: relative;
}
.bl-item-image {
	position: relative;
	display: block;
	background-color: rgba(130, 130, 130, 0.1);
	z-index: 3;
}
.bl-item-image:hover {
}

.bl-item-image.bg-image { /* blog list item background image height */
	padding-bottom: 60%;
}

.bl-item-image img {
}


/* Blog list item info 
======================= */
.bl-item-info {
	position: relative;
	padding: 4% 0 0 0;
	z-index: 2;
}

/* blog list item category */
.bl-item-category {
	margin-right: 40px;
	margin-bottom: 12px;
	font-size: 13px;
	font-weight: 400;
	color: #888;
}
.bl-item-category a,
.bl-item-category span {
	position: relative;
	display: inline-block;
	background-color: #e05536;
	margin-bottom: 3px;
	padding: 2px 6px;
	color: #fff;
}
.bl-item-category a:hover {
	opacity: .8;
}

/* blog list item title */
.bl-item-title {
	display: block;
}
.bl-item-title, .bl-item-title h2 {
	display: inline;
	margin: 0;
	font-weight: bold;
	font-weight: 800;
	font-size: 25px;
	color: #333;
	line-height: 1.2;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.bl-item-title:hover, .bl-item-title:focus,
.bl-item-title h2:hover, .bl-item-title h2:focus {
	color: #e05536;
}
@media (max-width: 992px) {
	.bl-item-title, .bl-item-title h2 {
		font-size: 22px;
	}
}

/* blog list item meta */
.bl-item-meta {
	margin: 10px 0 0 0;
	padding: 0;
	list-style: none;
	font-size: 14px;
	color: #888;
}
.bl-item-meta > li {
	position: relative;
	display: inline-block;
	margin-left: 3px;
}
.bl-item-meta > li::before {
	display: inline-block;
	content: "-";
	margin-right: 6px;
}
.bl-item-meta > li:first-child {
	margin-left: 0;
}
.bl-item-meta > li:first-child::before {
	display: none;
}

.bl-item-meta a {
	color: #888;
}
.bl-item-meta a:hover {
	color: #e05536;
}

.bli-meta-published {
}
.bli-meta-posted-by {
}
.bli-meta-comments {
}

/* blog list item description */
.bl-item-desc {
	margin-top: 15px;
	font-size: 18px;
	font-weight: 300;
}
.bl-item-desc p {
	margin: 0;
}

/* blog list item read more */
.bli-read-more {
	margin-top: 30px;
}
.bli-read-more-btn {
	position: relative;
	display: inline-block;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: 500;
	color: #333;
	overflow: hidden;
	-webkit-box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	-moz-box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);

	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.bli-read-more-btn:hover {
	color: #FFF;
}
.bli-read-more-btn::before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);

	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.bli-read-more-btn:hover::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}


/* Blog list item if isotope "col-1" is enabled
================================================ */
.isotope.iso-col-1 .isotope-items-wrap {
}

.isotope.iso-col-1 .iso-height-1 .blog-list-item,
.isotope.iso-col-1 .iso-height-2 .blog-list-item {
	display: block;
	padding-bottom: 50%;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.isotope.iso-col-1 .blog-list-item,
.isotope.iso-col-1 .blog-list-item {
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.isotope.iso-col-1 .bl-item-info {
	background-color: #FFF;
	padding: 3% 0 4% 0;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.isotope.iso-col-1 .bl-item-category {
	font-size: 13px;
	font-weight: 400;
}

.isotope.iso-col-1 .bl-item-title, 
.isotope.iso-col-1 .bl-item-title h2 {
	font-size: 46px;
}
@media (max-width: 992px) {
	.isotope.iso-col-1 .bl-item-title, 
	.isotope.iso-col-1 .bl-item-title h2 {
		font-size: 28px;
	}
}

.isotope.iso-col-1 .bl-item-meta {
	margin-top: 15px;
	font-size: 15px;
}

.isotope.iso-col-1 .bl-item-desc {
	margin-bottom: 15px;
	font-size: 19px;
}

.isotope.iso-col-1 .bli-read-more {
	display: block;
}
.isotope.iso-col-1 .bli-read-more-btn {
	padding: 12px 22px;
	font-size: 15px;
	font-weight: 500;
}


/* Blog list classic 
===================== */
.blog-list-classic {
}


/* Blog list grid 
================== */
.blog-list-grid {
}

/* blog list grid fluid + sidebar */
@media (max-width: 1400px) {
	.blg-fluid-sidebar .isotope.col-3 .isotope-item,
	.blg-fluid-sidebar .isotope.col-3 .grid-sizer,

	.blg-fluid-sidebar .isotope.col-4 .isotope-item,
	.blg-fluid-sidebar .isotope.col-4 .grid-sizer,

	.blg-fluid-sidebar .isotope.col-5 .isotope-item,
	.blg-fluid-sidebar .isotope.col-5 .grid-sizer,

	.blg-fluid-sidebar .isotope.col-6 .isotope-item,
	.blg-fluid-sidebar .isotope.col-6 .grid-sizer {
		width: 50%;
	}
}
@media (max-width: 992px) {
	.blg-fluid-sidebar .isotope.col-3 .isotope-item,
	.blg-fluid-sidebar .isotope.col-3 .grid-sizer,

	.blg-fluid-sidebar .isotope.col-4 .isotope-item,
	.blg-fluid-sidebar .isotope.col-4 .grid-sizer,

	.blg-fluid-sidebar .isotope.col-5 .isotope-item,
	.blg-fluid-sidebar .isotope.col-5 .grid-sizer,

	.blg-fluid-sidebar .isotope.col-6 .isotope-item,
	.blg-fluid-sidebar .isotope.col-6 .grid-sizer {
		width: 100%;
	}
}


/* Blog list simple 
==================== */
.blog-list-simple {
}
body.page-header-on .blog-list-simple {
	padding-top: 50px;
}


/* Blog list with thumbnails 
============================= */
.blog-list-thumb .blog-list-item,
.blog-list-thumb .isotope.iso-col-1 .blog-list-item {
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.blog-list-thumb .isotope-item:last-child .blog-list-item,
.blog-list-thumb .isotope.iso-col-1 .isotope-item:last-child .blog-list-item {
	border-bottom: none;
}
.blog-list-thumb .blog-list-item::after { /* clear fix */
	content: "";
	display: table;
	clear: both;
}

/* blog list with thumbnails item image */
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-image,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-image {
	position: relative;
	float: left;
	display: block;
	width: 240px;
	height: auto;
	margin-right: 30px;
	padding: 0;
}
@media (max-width: 768px) {
	.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-image,
	.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-image  {
		float: none;
		width: 100%;
		height: auto;
		padding-bottom: 60%;
		margin-right: 0;
		margin-bottom: 20px;
	}
}
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-image:hover,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-image:hover {
}

/* blog list with thumbnails item background image */
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-image.bg-image,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-image.bg-image {
	height: 240px;
}

/* blog list with thumbnails item info */
.blog-list-thumb .isotope.iso-col-1 .bl-item-info {
	padding-bottom: 3%;
}
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-info,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-info {
	position: relative;
	display: table;
	max-width: 800px;
	padding: 0;
}

/* blog list with thumbnails item category */
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-category a, 
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-category span,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-category a, 
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-category span {
}

/* blog list with thumbnails item title */
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-title,
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-title h2,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-title,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-title h2 {
	margin: 0;
	font-size: 30px;
}

/* blog list with thumbnails item meta */
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-meta,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-meta {
	margin-top: 10px;
}

/* blog list with thumbnails item description */
.blog-list-thumb .isotope-item:nth-child(n+3) .bl-item-desc,
.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .bl-item-desc {
	margin-top: 15px;
	margin-bottom: 0;
	font-size: 17px;
}

/* blog list with thumbnails item read more */
@media (min-width: 767px) {
	.blog-list-thumb .isotope-item:nth-child(n+3) .blog-list-item .bli-read-more,
	.blog-list-thumb .isotope-item:nth-child(n+3) .isotope.iso-col-1 .blog-list-item .bli-read-more {
		display: none;
	}
}


/* Blog list archive 
===================== */
.blog-list-archive .blog-list-item,
.blog-list-archive .isotope.iso-col-1 .blog-list-item {
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.blog-list-archive .isotope-item:last-child .blog-list-item,
.blog-list-archive .isotope.iso-col-1 .isotope-item:last-child .blog-list-item {
	border-bottom: none;
}
.blog-list-archive .blog-list-item::after { /* clear fix */
	content: "";
	display: table;
	clear: both;
}

/* blog list archive item image */
.blog-list-archive .isotope-item .bl-item-image,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-image {
	position: relative;
	float: left;
	display: block;
	width: 240px;
	height: auto;
	margin-right: 30px;
	padding: 0;
}
@media (max-width: 768px) {
	.blog-list-archive .isotope-item .bl-item-image,
	.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-image  {
		float: none;
		width: 100%;
		height: auto;
		padding-bottom: 60%;
		margin-right: 0;
		margin-bottom: 20px;
	}
}
.blog-list-archive .isotope-item .bl-item-image:hover,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-image:hover {
}

/* blog list archive item background image */
.blog-list-archive .isotope-item .bl-item-image.bg-image,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-image.bg-image {
	height: 240px;
}

/* blog list archive item info */
.blog-list-archive .isotope.iso-col-1 .bl-item-info {
	padding-bottom: 3%;
}
.blog-list-archive .isotope-item .bl-item-info,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-info {
	position: relative;
	display: table;
	max-width: 800px;
	padding: 0;
}

/* blog list archive item category */
.blog-list-archive .isotope-item .bl-item-category a, 
.blog-list-archive .isotope-item .bl-item-category span,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-category a, 
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-category span {
}

/* blog list archive item title */
.blog-list-archive .isotope-item .bl-item-title,
.blog-list-archive .isotope-item .bl-item-title h2,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-title,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-title h2 {
	margin: 0;
	font-size: 30px;
}

/* blog list archive item meta */
.blog-list-archive .isotope-item .bl-item-meta,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-meta {
	margin-top: 10px;
}

/* blog list archive item description */
.blog-list-archive .isotope-item .bl-item-desc,
.blog-list-archive .isotope-item .isotope.iso-col-1 .bl-item-desc {
	margin-top: 15px;
	margin-bottom: 0;
	font-size: 17px;
}

/* blog list archive item read more */
@media (min-width: 767px) {
	.blog-list-archive .isotope-item .blog-list-item .bli-read-more,
	.blog-list-archive .isotope-item .isotope.iso-col-1 .blog-list-item .bli-read-more {
	}
}


/* ------------------------------------------------------------- *
 * Blog list carousel
/* ------------------------------------------------------------- */

.blog-list-carousel {
}

/* blog list carousel nav position */
.blog-list-carousel .owl-carousel.nav-outside .owl-nav button.owl-prev, 
.blog-list-carousel .owl-carousel.nav-outside .owl-nav button.owl-next {
	top: 30%;
}
@media (max-width: 992px) {
	.blog-list-carousel .owl-carousel.nav-outside .owl-nav button.owl-prev, 
	.blog-list-carousel .owl-carousel.nav-outside .owl-nav button.owl-next {
		top: 25%;
	}
}


/* ------------------------------------------------------------- *
 * Blog single section
/* ------------------------------------------------------------- */

/* blog single inner */
.blog-single-inner {
	position: relative;
	padding-left: 5%;
	padding-right: 5%;
}

/* if page boxed layout enabled */
body.tt-boxed .blog-single-inner.tt-wrap {
	padding-left: 15px;
	padding-right: 15px;
}


/* Blog single post 
==================== */
.blog-single-post {
}
.blog-single-post-inner {
	position: relative;
}

/* blog single post featured image */
#blog-single-featured-image-holder {
}
.blog-single-featured-image-inner {
}

.blog-single-featured-image {
	display: block;
}

.blog-single-featured-image.bg-image { /* for background image height */
	padding-bottom: 50%;
}
@media (max-width: 992px) {
	.blog-single-featured-image.bg-image { /* for background image height */
		padding-bottom: 60%;
	}
}
@media (max-width: 768px) {
	.blog-single-featured-image.bg-image { /* for background image height */
		padding-bottom: 75%;
	}
}

/* blog single post heading */
.blog-single-post-heading {
	position: relative;
	padding: 0 0 5% 0;
	z-index: 1;
}
.blog-single-featured-image + .blog-single-post-heading {
	padding: 3% 0 1% 0;
}
.blog-single-post-title {
	margin: 0;
	font-size: 58px;
	font-weight: bold;
	font-weight: 800;
	color: #333;
}
@media (max-width: 992px) {
	.blog-single-post-title {
		font-size: 28px;
	}
}

.blog-single-post-category {
	margin-bottom: 15px;
	font-size: 13px;
	font-weight: 400;
	color: #FFF;
}
.blog-single-post-category a,
.blog-single-post-category span {
	position: relative;
	display: inline-block;
	background-color: #e05536;
	margin-bottom: 3px;
	padding: 2px 6px;
	color: #fff;
}
.blog-single-post-category a:hover {
	opacity: .9;
}

/* blog single post content */
.post-content {
}

/* blog single post image */
.single-post-image {
	margin: 0;
}
.sp-image-wrap {
	position: relative;
	display: block;
}
.sp-image {
	padding-bottom: 60%;
}
img.sp-image {
	padding-bottom: 0;
}
.single-post-image-caption {
}


/* Blog single slider 
====================== */
.blog-single-slider {
}

.post-content .owl-carousel {
	margin: 30px 0 30px 0;
}
.post-content .owl-carousel.dots-outside {
	margin-bottom: 60px;
}
.post-content .owl-carousel .cc-item {
	margin: 0;
	padding: 0;
}

.post-content .owl-carousel .single-post-image {
	margin: 0;
}


/* Blog single attributes 
========================== */
.blog-single-attributes {
	margin-bottom: 40px;
}

/* blog single meta */
.blog-single-meta-wrap {
	overflow: hidden;
}

.bs-meta-author-avatar {
	position: relative;
	display: block;
	float: left;
	width: 40px;
	height: 40px;
	background-color: rgba(130, 130, 130, 0.1);
	margin-right: 12px;
	border-radius: 50%;
}

.blog-single-meta {
	float: left;
	margin: 0;
	padding: 0;
	font-size: 15px;
	color: #999;
	overflow: hidden;
	list-style: none;
}

.bs-meta-posted-by {
	font-weight: normal;
}
.bs-meta-posted-by a {
	color: #333;
}
.bs-meta-posted-by a:hover {
	color: #e05536;
}
.bs-meta-published {
}

/* blog single links */
.blog-single-links {
	margin: 8px 0 0 0;
	padding: 0;
	text-align: right;
	font-size: 15px;
	color: #333;
	line-height: 1.5;
	list-style: none;
}
@media (max-width: 420px) {
	.blog-single-links {
		margin-top: 20px;
		text-align: left;
	}
}
.blog-single-links > li {
	display: inline-block;
	margin-left: 5px;
}
.blog-single-links a {
	display: inline-block;
	font-weight: bold;
	font-weight: 500;
	color: #333;
}
.blog-single-links a:hover {
}

.blog-single-comment-count {
}

/* blog single tags */
.blog-single-tags {
	padding: 40px 0;
	font-size: 16px;
	font-weight: bold;
	font-weight: 600;
	overflow: hidden;
	margin-top: 60px;
	overflow: hidden;
	border-top: 1px solid rgba(150, 150, 150, 0.2);
}
.blog-single-tags span {
	color: #333;
	line-height: 2;
}
.blog-single-tags > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.blog-single-tags > ul > li {
	float: left;
	margin: 3px;
}
.blog-single-tags a {
	position: relative;
	display: block;
	padding: 6px 10px;
	font-size: 14px;
	font-weight: 400;
	color: #333;
	overflow: hidden;
	-webkit-box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	-moz-box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.blog-single-tags a::before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.blog-single-tags a:hover::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.blog-single-tags a:hover {
	color: #fff;
}


/* Blog single post share 
========================== */
.blog-single-share {
}
.blog-single-share > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.blog-single-share > ul > li {
	display: inline-block;
}
.bss-text {
	margin-bottom: 16px;
	font-size: 16px;
	font-weight: bold;
	font-weight: 600;
}

/* blog single post share fixed bottom (effect only on small screens!!!) */
@media (max-width: 992px) {
	.blog-single-share.bss-fixed-bottom {
		position: fixed;
		top: auto;
		bottom: -1px;
		left: 0;
		right: 0;
		background-color: rgba(255, 255, 255, 0.85);
		padding: 0;
		text-align: center;
		z-index: 99991;
	}
	.blog-single-share.bss-fixed-bottom .bss-text {
		display: none !important;
	}

	.blog-single-share.bss-fixed-bottom > ul {
		width: 100%;
		display: table;
		table-layout: fixed;
	}
	.blog-single-share.bss-fixed-bottom > ul > li {
		margin: 0 1px;
		display: table-cell;
	}
	.blog-single-share.bss-fixed-bottom  > ul > li > .btn {
		margin: 0;
		width: 100%;
		height: 28px;
		line-height: 22px;
		padding: 0;
		font-size: 14px;
	}
}


/* Blog single nav 
=================== */
.blog-single-nav {
	margin-top: 60px;
	padding-top: 40px;
	overflow: hidden;
	border-top: 1px solid rgba(150, 150, 150, 0.2);
}

.bs-nav-col {
	float: left;
	width: 50%;
}
.bs-nav-left {
	padding-right: 5%;
	border-right: 1px solid rgba(150, 150, 150, 0.2);
}
.bs-nav-right {
	padding-left: 5%;
	text-align: right;
}

.bs-nav-text {
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: bold;
	font-weight: 600;
	color: #333;
}
.bs-nav-title, .bs-nav-title h4 {
	margin: 0;
	font-size: 21px;
	color: #333;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
@media (max-width: 768px) {
	.bs-nav-title, .bs-nav-title h4 {
		font-size: 16px;
	}
}
.bs-nav-title:hover, .bs-nav-title h4:hover {
	color: #e05536;
}


/* Blog single related posts 
============================= */
.related-posts {
	margin-top: 60px;
	padding-top: 60px;
	overflow: hidden;
	border-top: 1px solid rgba(150, 150, 150, 0.2);
}
.related-posts-heading {
	font-size: 21px;
	margin: 0 0 30px 0;
}

/* related posts carousel */
.related-posts-carousel {
}

.related-posts-carousel .owl-carousel .cc-item {
	margin: 0;
	padding: 0;
}

.related-posts-item {
}
.related-posts-item .rp-item-image-wrap {
	position: relative;
	background-color: rgba(130, 130, 130, 0.1);
}
.related-posts-item .rp-item-image {
	display: block;
	padding-bottom: 70%;
	margin-bottom: 10px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.related-posts-item .rp-item-image:hover {
    opacity: .9;
}

.rp-item-info {
}

.related-posts-item .rp-item-title,
.related-posts-item .rp-item-title a {
	margin: 0;
	font-size: 20px;
	color: #333;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
@media (max-width: 479px) {
	.related-posts-item .rp-item-title,
	.related-posts-item .rp-item-title a {
		font-size: 28px;
	}
}
.related-posts-item .rp-item-title:hover,
.related-posts-item .rp-item-title a:hover {
	color: #e05536;
}

.related-posts-item .rp-item-category {
	margin-bottom: 10px;
	font-size: 11px;
	font-weight: 500;
	color: #999;
}
.related-posts-item .rp-item-category a {
	display: inline-block;
	background-color: #e05536;
	padding: 0 4px;
	color: #FFF;
	margin: 0 2px 2px 0;
}
.related-posts-item .rp-item-category a:hover {
	opacity: .8;
}


/* Blog single post comments 
============================= */
#blog-post-comments {
	margin-top: 60px;
	padding-top: 60px;
	border-top: 1px solid rgba(150, 150, 150, 0.2);
}

.blog-post-comments-heading {
	margin: 0 0 30px 0;
	font-size: 24px;
}

/* tt comment list */
.tt-comment-list {
	padding: 0;
	margin: 0;
	list-style: none;
	overflow: hidden;
}
.tt-comment-list > li {
	margin-top: 30px;
}

/* tt comment */
.tt-comment {
}

/* tt comment avatar */
.tt-comment-avatar {
	position: relative;
	display: inline-block;
	float: left;
	width: 50px;
	height: 50px;
	margin-right: 15px;
	border-radius: 50%;
}
.tt-comment-avatar:hover {
}
@media (max-width: 768px) {
	.tt-comment-avatar {
		width: 40px;
		height: 40px;
		margin-top: 20px;
		margin-left: 20px;
	}
}

/* tt comment pody */
.tt-comment-body {
	position: relative;
	display: table;
	padding: 20px;
	border: 1px solid rgba(150, 150, 150, 0.2);
}
@media (max-width: 768px) {
	.tt-comment-body {
		display: block;
	}
}

/* tt comment meta */
.tt-comment-meta {
	margin-right: 70px;
}

.tt-comment-heading {
	margin: 0 0 5px 0;
	text-transform: none;
	letter-spacing: 0;
	font-size: 18px;
	color: #333;
}
.tt-comment-heading a {
	color: #333;
}
.tt-comment-heading a:hover {
	color: #e05536;
}

.tt-comment-time {
	display: block;
	margin-bottom: 15px;
	font-size: 14px;
	font-style: italic;
	color: #888;
}

/* tt comment text */
.tt-comment-text {
	clear: both;
	font-size: 17px;
}

/* tt comment reply */
.tt-comment-reply {
	position: absolute;
	top: 20px;
	right: 20px;
	text-align: right;
	font-size: 13px;
	z-index: 9;
}
.tt-comment-reply a {
	color: #888;
}
.tt-comment-reply a:hover {
	color: #e05536;
}

/* blog single post comment form */
#post-comment-form {
	margin-top: 80px;
	padding: 5%;
	border: 1px solid rgba(150, 150, 150, 0.2);
}
#post-comment-form .post-comment-reminder {
	margin-top: 20px;
}
#post-comment-form .post-comment-reminder label {
	font-size: 16px;
	color: #666;
}


/* ------------------------------------------------------------- *
 * Sidebar
/* ------------------------------------------------------------- */

.sidebar {
}

.sidebar.sidebar-left {
	padding-right: 28px;
	margin-right: 28px;
}
@media (max-width: 1600px) {
	.sidebar.sidebar-left {
		padding-right: 15px;
		margin-right: 15px;
	}
}

.sidebar.sidebar-right {
	padding-left: 28px;
	margin-left: 28px;
}
@media (max-width: 1600px) {
	.sidebar.sidebar-right {
		padding-left: 15px;
		margin-left: 15px;
	}
}

@media (max-width: 991px) {
	.sidebar {
		margin-left: 0 !important;
		margin-right: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		border-left: none !important;
		border-right: none !important;

		margin-top: 30px;
		padding-top: 40px;
		border-top: 1px dotted rgba(150, 150, 150, 0.18);
	}
}


/* ------------------------------------------------------------- *
 * Blog sidebar widgets
/* ------------------------------------------------------------- */

.sidebar-widget {
	margin-bottom: 50px;
	overflow: hidden;
}

/* Sidebar headings */
.sidebar-heading {
	margin-bottom: 40px;
	font-size: 24px;
	font-weight: 700;
}

/* Blog author (about me) 
========================== */
.blog-author {
}

/* blog author image */
.blog-author-img {
	display: inline-block;
	width: 110px;
	height: 110px;
	background-color: rgba(130, 130, 130, 0.1);
	margin-bottom: 10px;
	border-radius: 50%;
}

a.blog-author-img {
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
a.blog-author-img:hover {
	margin-left: 12px;
	-webkit-box-shadow: -10px 0 0 0px #e05536;
	-moz-box-shadow: -10px 0 0 0px #e05536;
	box-shadow: -10px 0 0 0px #e05536;
}

/* blog author info */
.blog-author-info {
}
.blog-author-name {
	text-transform: none;
	letter-spacing: 0;
	margin: 0;
	font-size: 20px;
}
.blog-author-name a {
	color: #333;
}
.blog-author-name a:hover {
	color: #e05536;
}
.blog-author-sub {
	margin-bottom: 10px;
	font-size: 15px;
	color: #888;
}

/* blog author text */
.blog-author-text {
	margin: 0;
	font-size: 17px;
}
.blog-author-more {
	display: block;
	margin-top: 15px;
	text-align: right;
	font-size: 17px;
	color: #BBB;
}


/* Blog sidebar search 
======================= */
.sidebar-search {
}
#blog-search-form {
}
#blog-search-form .form-group {
	position: relative;
}
#blog-search-form .form-control {
}
#blog-search-form button {
}


/* Blog sidebar social 
======================= */
.sidebar-social {
}
.sidebar-social .btn-social-min {
	width: 54px;
	height: 54px;
	line-height: 50px;
	font-size: 20px;
}


/* Blog sidebar subscribe 
========================== */
#sidebar-subscribe-form {
	position: relative;
}
#sidebar-subscribe {
}


/* Blog sidebar categories 
=========================== */
.sidebar-categories {
}
.sidebar-categories > ul > li {
	padding: 0;
}
.sidebar-categories > ul > li > a {
	display: block;
	text-decoration: none;
	font-size: 17px;
	font-weight: 600;
	color: #333;
	line-height: 1;
	padding: 15px 0;
	border-bottom: 1px solid rgba(136, 136, 136, 0.15);
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.sidebar-categories > ul > li > a:hover {
	color: #e05536;
}
.sidebar-categories > ul > li:first-child {
	padding-top: 0;
	border: 0;
}
.sidebar-categories > ul > li > a span {
	position: relative;
	float: right;
	top: -5px;
	background-color: #e05536;
	padding: 6px 7px 6px 7px;
	text-align: center;
	font-size: 13px;
	font-weight: 400;
	color: #FFF;
	border-radius: 50%;
	overflow: hidden;
	z-index: 1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.sidebar-categories > ul > li > a:hover span {
	color: #FFF;
}

.sidebar-categories > ul > li > a span::before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);

	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.sidebar-categories > ul > li > a:hover span::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}


/* Blog sidebar post list 
========================== */
.sidebar-post-list {
}
.sidebar-post-list > ul > li {
	list-style: none;
	overflow: hidden;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.sidebar-post-list > ul > li:last-child {
	margin: 0;
	padding: 0;
	border: 0;
}

.sidebar-post-list .post-thumb {
	float: left;
	display: block;
	margin: 0 15px 0 0;
	width: 130px;
	height: 110px;
	background-color: rgba(130, 130, 130, 0.1);
	overflow: hidden;
}
.sidebar-post-list .post-thumb:hover {
}

.sidebar-post-list .post-data {
	display: table;
}
.sidebar-post-list .post-data .post-title {
	margin: 0 0 5px 0;
	font-size: 18px;
	font-weight: bold;
	font-weight: 600;
	line-height: 1.2;
}
.sidebar-post-list .post-data .post-title a {
	color: #333;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.sidebar-post-list .post-data .post-title a:hover {
	color: #e05536;
}
.sidebar-post-list .post-data .author {
	display: block;
	font-size: 14px;
	line-height: 15px;
}
.sidebar-post-list .post-data .author a {
	color: #333;
}
.sidebar-post-list .post-data .author a:hover {
	color: #e05536;
}
.sidebar-post-list .post-data .date {
	display: block;
	font-size: 14px;
	color: #888;
}


/* Blog sidebar post comments list 
=================================== */
.sidebar-comments-list {
}

/* sidebar comment */
.sidebar-comment {
	padding-bottom: 18px;
	margin-bottom: 20px;
	border-bottom: 1px solid #e6e6e6;
}
.sidebar-comment:last-child {
	margin: 0;
	padding: 0;
	border: none;
}

/* sidebar comment meta */
.sidebar-comment-meta {
	overflow: hidden;
}
.sidebar-comment-avatar {
	float: left;
	display: block;
	margin: 0 10px 0 0;
	overflow: hidden;
	width: 40px;
	height: 40px;
	background-color: rgba(130, 130, 130, 0.1);
	border-radius: 50%;
}
.sidebar-comment-avatar:hover {
}

/* sidebar comment data */
.sidebar-comment-data {
	float: left;
}
.sidebar-comment-data .author {
	display: block;
	font-size: 16px;
	font-weight: 600;
}
.sidebar-comment-data .author a {
	color: #333;
}
.sidebar-comment-data .author a:hover {
	color: #e05536;
}
.sidebar-comment-data .date {
	display: block;
	font-size: 14px;
	color: #666;
}

/* sidebar comment text */
.sidebar-comment-text {
	margin-top: 10px;
	font-size: 15px;
	line-height: 1.3;
	font-style: italic;
	color: #333;
}
.sidebar-comment-text a {
	color: #333;
}
.sidebar-comment-text a:hover {
	color: #000;
}
.sidebar-comment-text p {
	margin-bottom: 0;
}


/* Blog sidebar tags 
===================== */
.sidebar-tags {
}
.sidebar-tags > ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.sidebar-tags > ul > li {
	float: left;
	margin: 3px;
}
.sidebar-tags a {
	position: relative;
	display: block;
	padding: 4px 7px;
	font-size: 15px;
	font-weight: 400;
	color: #333;
	overflow: hidden;
	-webkit-box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	-moz-box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);
	box-shadow: inset 0 0 0 1px rgba(130, 130, 130, 0.18);

	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.sidebar-tags a:hover {
	color: #FFF;
}
.sidebar-tags a::before {
	position: absolute;
	display: block;
	content: "";
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}
.sidebar-tags a:hover::before {
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
}


/* Blog sidebar photo stream 
============================= */
.sidebar-photo-stream {
}


/* Blog sidebar meta 
===================== */
.sidebar-meta {
}
.sidebar-meta ul > li > a {
	display: inline-block;
	padding: 3px 0;
	font-size: 16px;
	color: #333;
}
.sidebar-meta ul > li > a:hover {
	color: #e05536;
}


/* ------------------------------------------------------------- *
 *  Search results
/* ------------------------------------------------------------- */

/* Big search box 
================== */
.big-search-box {
	position: relative;
	margin-bottom: 80px;
}
@media (max-width: 768px) {
	.big-search-box  {
		margin-bottom: 10%;
	}
}

#big-search-form {
}
.big-search-input-wrap {
	position: relative;
}
#big-search-input {
	background-color: #FFF;
}
#big-search-input:focus {
}

.big-search-options-wrap {
	margin-top: 20px;
}
.bso-title {
	display: inline-block;
	margin-right: 15px;
	font-size: 16px;
	font-weight: 600;
}


/* Search results 
================== */
.search-results {
}


/* Search results items wrap 
============================= */
.sr-items-wrap {
}

/* search results head */
.search-results-head {
	margin-bottom: 50px;
	padding-bottom: 20px;
	font-size: 16px;
	border-bottom: 1px solid rgba(132, 132, 132, 0.2);
}
.search-results-head .sr-count {
}
@media (max-width: 767px) {
	.search-results-head .sr-count  {
		margin-top: 20px;
		text-align: left;
	}
}
.search-results-head .sr-count span {
	font-size: 18px;
	font-weight: bold;
	font-weight: 500;
	color: #333;
}


/* Search results item 
======================= */
.search-results-item {
	position: relative;
	margin-bottom: 50px;
	padding-bottom: 50px;
	overflow: hidden;
	border-bottom: 1px solid #ededed;
}
.search-results-item::after {
	content: "";
	display: table;
	clear: both;
}
.search-results-item:last-child {
	border-bottom: none;
}

/* search results item image */
.sr-item-img-holder {
	position: relative;
	display: block;
	float: left;
	width: 460px;
	height: 260px;
	background-color: rgba(238, 238, 240, 1);
	margin-right: 30px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.sr-item-img-holder:hover {
	opacity: .85;
}
@media (max-width: 1200px) {
	.sr-item-img-holder  {
		width: 340px;
		height: 260px;
		margin-right: 20px;
	}
}
@media (max-width: 992px) {
	.sr-item-img-holder  {
		width: 200px;
		height: 240px;
		margin-right: 20px;
	}
}
@media (max-width: 768px) {
	.sr-item-img-holder  {
		float: none;
		width: 100%;
		height: auto;
		padding-bottom: 60%;
		margin-right: 0;
		margin-bottom: 20px;
	}
}
.sr-item-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* search results item info */
.sr-item-info {
	position: relative;
	display: table;
}
@media (max-width: 768px) {
	.sr-item-info  {
		padding-left: 0;
	}
}

.sr-item-title {
	max-width: 600px;
	margin: 0;
	font-size: 28px;
	color: #333;
}
.sr-item-title > a {
	color: #333;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.sr-item-title:hover,
.sr-item-title > a:hover {
	color: #e05536;
}

.search-results-item .sr-item-type {
	position: relative;
	display: inline-block;
	background-color: #e05536;
	margin-top: 10px;
	padding: 2px 6px;
	font-size: 13px;
	font-weight: 400;
	color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.sr-item-description {
	margin-top: 15px;
	font-size: 17px;
}


/* No search results found 
=========================== */
.no-search-results {
	padding-bottom: 20%;
}


/* ------------------------------------------------------------- *
 * Scroll to top button
/* ------------------------------------------------------------- */

.scrolltotop {
	position: fixed;
	bottom: 24px;
	right: 20px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	background-color: #FFF;
	text-align: center;
	font-size: 18px;
	color: #000;
	text-decoration: none;
	z-index: 9;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.scrolltotop::before {
	line-height: 30px;

	font-family: "Font Awesome 5 Free";
	content: "\f077";
	font-weight: 900;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

/* scrolltotop active */
.scrolltotop.scr-active {
	opacity: 1;
	visibility: visible;
}

/* scrolltotop hover */
.scrolltotop:hover {
	color: #FFF;
}

/* scrolltotop hover background */
.scrolltotop::after {
	position: absolute;
	display: block;
	content: "";
	height: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e05536;
	z-index: -1;
	-webkit-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .4s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .4s cubic-bezier(.645,.045,.355,1);
	transition: all .4s cubic-bezier(.645,.045,.355,1);

	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.scrolltotop:hover::after {
	height: 100%
}

/* scrolltotop if boxed layout enabled */
body.tt-boxed .scrolltotop {
	bottom: 16px;
}
body.tt-boxed.footer-simple-on .scrolltotop {
	bottom: 27px;
}
body.tt-boxed.footer-minimal-on .scrolltotop {
	bottom: 27px;
}

@media (max-width: 1560px) {
	body.tt-boxed .scrolltotop {
		bottom: 74px;
	}
	body.tt-boxed.footer-minimal-on .scrolltotop {
		bottom: 94px;
	}
}
@media (max-width: 991px) {
	.scrolltotop,
	body.tt-boxed .scrolltotop,
	body.footer-minimal-on .scrolltotop {
		bottom: 20px;
	}
}



/* ------------------------------------------------------------- *
 * Footer
/* ------------------------------------------------------------- */

#footer {
	position: relative;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 120px 0 0 0;
	/* border-top: 1px solid rgba(134, 134, 134, 0.15); */
}
@media (max-width: 767px) {
	#footer {
		padding-top: 70px;
	}
}

.footer-inner {
}

/* footer container */
.footer-container {
	padding-left: 3%;
	padding-right: 3%;
}

body.tt-boxed .footer-container.tt-wrap {
	padding-left: 15px;
	padding-right: 15px;
}


/* Footer blocks 
================= */
.footer-block {
	margin-bottom: 30px;
	font-size: 16px;
	overflow: hidden;
}

.footer-block-heading {
	margin: 0 0 20px 0;
	font-size: 18px;
	font-weight: 700;
}

.footer-block-content {
}

/* footer logo */
#footer .f-block-logo {
	margin-top: 20px;
	margin-bottom: 40px;
}
#footer .footer-logo {
	display: inline-block;
}
#footer .footer-logo img {
	max-height: 80px;
}

/* footer logo variations */
#footer .logo-light,
#footer .logo-light-m,
#footer .logo-dark-m {
	display: none;
}
@media (max-width: 991px) {
	#footer .logo-dark {
		display: none;
	}
	#footer .logo-dark-m {
		display: block;
	}
}

/* footer links */
ul.f-block-links {
	margin: 0;
	padding: 0;
	list-style: none;
}
ul.f-block-links > li {
	display: block;
}
ul.f-block-links > li > a {
	position: relative;
	display: inline-block;
	padding-bottom: 4px;
	font-size: 16px;
	color: #000;
	z-index: 1;
}
ul.f-block-links > li > a:hover {
}

ul.f-block-links > li > a::after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 20%;
	width: 100%;
	z-index: -1;
	height: 0;
	background: #e05536;
	opacity: .3;
	overflow: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
ul.f-block-links > li > a:hover::after {
	height: 10px;
}

/* footer text */
.f-block-text {
}
.f-block-text p {
	margin-bottom: 7px;
}

/* footer social buttons */
#footer .social-buttons {
	margin-bottom: 20px;
}


/* Footer bottom bar
===================== */
.footer-bottom {
	margin-top: 60px;
	padding: 24px 0;
	font-size: 13px;
	color: #888;
	border-top: 1px solid rgba(128, 128, 128, 0.15);
}
@media (max-width: 768px) {
	.footer-bottom {
		margin-top: 40px;
		padding: 24px 0 44px 0;
	}
}

.footer-bottom p {
	margin-bottom: 5px;
}

/* footer bottom left */
.footer-bottom-left {
}

/* footer bottom col right */
.footer-bottom-right {
}
@media (min-width: 992px) {
	.footer-bottom-right {
		text-align: right;
	}
}


/* Footer medium
================= */
#footer.footer-medium {
	padding: 120px 0 0 0;
}
@media (max-width: 991px) {
	#footer.footer-medium {
		padding: 60px 0 0 0;
	}
}

/* footer medium bottom */
#footer.footer-medium .footer-bottom {
	margin-top: 40px;
	border-top: none;
}
@media (max-width: 991px) {
	#footer.footer-medium .footer-bottom {
		margin-top: 0;
	}
}
#footer.footer-medium .footer-bottom .footer-bottom-left {
}
#footer.footer-medium .footer-bottom .footer-bottom-right {
}


/* Footer simple
================= */
#footer.footer-simple {
	padding: 100px 0 80px 0;
	text-align: center;
}
@media (max-width: 991px) {
	#footer.footer-simple {
		padding: 80px 0;
	}
}

/* footer simple logo */
#footer.footer-simple .f-block-logo {
	margin: 0 0 20px 0;
}
#footer.footer-simple .f-block-logo {
	display: inline-block;
}
#footer.footer-simple .footer-logo img {
	max-height: 94px;
}

/* footer simple f-block-links */
#footer.footer-simple ul.f-block-links > li {
	display: inline-block;
	margin: 0 6px;
}

/* footer simple social buttons */
#footer.footer-simple .social-buttons {
	text-align: center;
}
#footer.footer-simple .social-buttons .btn-link:hover {
	color: #e05536;
}

/* footer simple copyright */
#footer.footer-simple .footer-simple-copyright {
	font-size: 14px;
	text-align: center;
}
#footer.footer-simple .footer-simple-copyright p {
	margin-bottom: 5px;
}


/* Footer minimal
================== */
#footer.footer-minimal {
	height: 83px;
	padding: 22px 0;
	border-top: 1px solid rgba(128, 128, 128, 0.05);
}
@media (max-width: 991px) {
	#footer.footer-minimal {
		height: auto;
		padding: 60px 0;
	}
}

@media (min-width: 1282px) { /* boxed layout width */
	#footer.footer-minimal .footer-container {
		padding-left: 3%;
		padding-right: 3%;
	}
}
body.tt-boxed #footer.footer-minimal .footer-container.tt-wrap {
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 992px) {
	#footer.footer-minimal .footer-block {
		margin: 0;
	}
}

#footer.footer-minimal .social-buttons {
	margin-top: 8px;
	margin-bottom: 0;
	text-align: right;
}
@media (max-width: 991px) {
	#footer.footer-minimal .social-buttons {
		text-align: center;
	}
}

#footer.footer-minimal .footer-minimal-copyright {
	font-size: 14px;
}
#footer.footer-minimal .footer-minimal-copyright p {
	margin-bottom: 0;
}
@media (max-width: 991px) {
	#footer.footer-minimal .footer-minimal-copyright {
		text-align: center;
	}
}


/* Footer dark
=============== */
#footer.footer-dark {
	background-color: #000;
	color: #BBB;
	border-top: none;
}

/* footer dark footer block */
#footer.footer-dark .footer-block-heading {
	color: #DDD;
}

#footer.footer-dark ul.f-block-links > li > a {
	color: #BBB;
}
#footer.footer-dark ul.f-block-links > li > a::after {
	opacity: 1;
}
#footer.footer-dark ul.f-block-links > li > a:hover {
	color: #FFF;
}

#footer.footer-dark .f-block-text a:hover {
	color: #FFF;
}

/* footer logo variations if "footer-dark" enabled */
#footer.footer-dark .logo-dark,
#footer.footer-dark .logo-dark-m {
	display: none;
}

#footer.footer-dark .logo-light {
	display: block;
}
@media (max-width: 991px) {
	#footer.footer-dark .logo-light {
		display: none;
	}
	#footer.footer-dark .logo-light-m {
		display: block;
	}
}

/* footer dark buttons */
#footer.footer-dark .btn-gray {
	color: #FFF;
}
#footer.footer-dark .btn-gray:hover {
	color: #FFF;
}
#footer.footer-dark .btn-gray::before {
	background-color: rgba(0, 0, 0, 0.25);
}

#footer.footer-dark .btn-link {
	color: #FFF;
}
#footer.footer-dark .btn-link:hover {
	color: #e05536;
}

/* footer dark forms */
#footer.footer-dark .form-control {
	background-color: #101010;
	color: #CCC;
}
#footer.footer-dark .form-btn-inside button {
	color: #CCC;
}

/* footer dark footer bottom */
#footer.footer-dark .footer-bottom {
	background-color: rgba(0, 0, 0, 0.2);
	color: #888;
	border-top-color: rgba(128, 128, 128, 0.16);
}
#footer.footer-dark .footer-bottom a {
	color: #DDD;
}
#footer.footer-dark .footer-bottom a:hover {
	color: #e05536;
}

#footer.footer-dark .footer-simple-copyright a {
	color: #DDD;
}
#footer.footer-dark .footer-simple-copyright a:hover {
	color: #e05536;
}

#footer.footer-dark .footer-minimal-copyright a {
	color: #DDD;
}
#footer.footer-dark .footer-minimal-copyright a:hover {
	color: #e05536;
}


/* Fixed footer (no effect on small devices) 
============================================= */
@media (min-width: 992px) {
	#footer.footer-fixed {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		height: auto;
		margin-top: 0 !important;
		z-index: 1;
	}
}
